import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';
import { BaseHttpService } from '@erp/shared';

@Injectable({
  providedIn: 'root'
})
export class ERPCustomersDictionaryService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('dictionaries');
  }

  get customerTypes() {
    return this.get<ISelectOption[]>({}, 'customerType');
  }

  get customerStatuses() {
    return this.get<ISelectOption[]>({}, 'customerStatus');
  }
}
