import { filter, pairwise } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router, RoutesRecognized } from '@angular/router';

import { DESKTOP_GO_BACK_URLS, TABLET_GO_BACK_URLS } from '../constants';
import { DocumentTypeIds } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ERPRouterExtService {
  private previousUrl: string;
  private currentUrl: string;
  private history: string[] = [];

  constructor(private router: Router, private readonly location: Location) {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe(([prev, next]): void => {
        const routeObj = prev as RoutesRecognized;
        this.history.push((next as RoutesRecognized).urlAfterRedirects);
        this.previousUrl = routeObj?.urlAfterRedirects;
      });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  getURLbyDocumentTypeId(documentTypeId: DocumentTypeIds, tablet: boolean = false): string {
    const url: string | undefined = tablet ? TABLET_GO_BACK_URLS[documentTypeId] : DESKTOP_GO_BACK_URLS[documentTypeId];

    return url ?? (tablet ? '/tablet' : '/');
  }

  back(documentTypeId: DocumentTypeIds, tablet?: boolean): void {
    const url = this.getURLbyDocumentTypeId(documentTypeId, tablet);
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(url);
    }
  }

  navigateTo(url: string, params?: NavigationExtras): void {
    this.router.navigate([url], params);
  }
}
