import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ERPSharedModule } from '@erp/shared';

import { ERPIconModule } from '../icon/icon.module';
import { ERPTooltipModule } from '../tooltip';

import {
  ERPArrayPopupComponent,
  ERPTrimArrayComponent,
  ERPTrimEmailComponent,
  ERPTrimLinkComponent,
  ERPTrimTextComponent
} from './components';

@NgModule({
  declarations: [
    ERPArrayPopupComponent,
    ERPTrimArrayComponent,
    ERPTrimEmailComponent,
    ERPTrimLinkComponent,
    ERPTrimTextComponent
  ],
  imports: [CommonModule, ERPSharedModule, ERPTooltipModule, RouterModule, ERPIconModule],
  exports: [
    ERPArrayPopupComponent,
    ERPTrimArrayComponent,
    ERPTrimEmailComponent,
    ERPTrimLinkComponent,
    ERPTrimTextComponent
  ]
})
export class ERPTrimModule {}
