import { ISelectOption } from '@erp/components';

import { ReportJointType } from '../enums';

export const REPORT_JOINT_OPTIONS: ISelectOption<ReportJointType>[] = [
  {
    id: ReportJointType.ByQuantity,
    value: $localize`:@@tablet.production.processing-order.entry-operation.report-total-qty:Report Total QTY`
  },
  {
    id: ReportJointType.ByJoints,
    value: $localize`:@@tablet.production.processing-order.entry-operation.enter-by-joint:Enter by Joint #`
  }
];
