import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPButtonModule } from '../../button';

import { ERPNotFoundPageComponent } from './components';
import { ERPNotFoundPageRoutingModule } from './not-found-page-module.routing';

@NgModule({
  declarations: [ERPNotFoundPageComponent],
  exports: [ERPNotFoundPageComponent],
  imports: [CommonModule, ERPButtonModule, ERPNotFoundPageRoutingModule]
})
export class ERPNotFoundPageModule {}
