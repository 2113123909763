import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

import { IAttachment } from '../../interface';

@Component({
  selector: 'erp-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPAttachmentComponent implements OnInit {
  @Input() readonly errorLoading = false;
  @Input() readonly isLoadingType = false;
  @Input() readonly loadingProgress = 0;
  @Input() readonly attachment: IAttachment;

  @Output() readonly delete = new EventEmitter();
  @Output() readonly download = new EventEmitter<IAttachment>();
  @Output() readonly interruptLoading = new EventEmitter<string>();
  icon: SafeStyle;
  @Input() readonly underline: boolean;
  @Input() readonly withLabels: boolean;
  @Input() readonly trimSize: number;
  trimmedFileName: string | null;

  ngOnInit() {
    this.icon = this.getIcon(this.attachment.fileName);
    this.trimmedFileName = this.trimFileName(this.attachment.fileName, this.trimSize);
  }

  getExtension(fileName: string) {
    const [, extension] = fileName.split('.');

    return extension;
  }

  private getIcon(fileName: string) {
    const extension = this.getExtension(fileName)?.toLowerCase();
    let icon: string;

    switch (extension) {
      case 'pdf':
        icon = 'pdf';
        break;
      case 'zip':
      case 'tar':
      case 'rar':
      case '7z':
        icon = 'archive';
        break;
      case 'image':
      case 'jpg':
      case 'jpeg':
      case 'png':
        icon = 'image';
        break;
      case 'doc':
      case 'docx':
      case 'rtf':
      case 'odf':
        icon = 'document';
        break;
      case 'xls':
      case 'xlsx':
      case 'csv':
        icon = 'excel';
        break;
      case 'ppt':
      case 'pot':
      case 'pps':
      case 'pptx':
      case 'ppsx':
      case 'sldx':
        icon = 'powerpoint';
        break;
      default:
        icon = 'generic';
    }

    return `file-type:${icon}`;
  }

  onInterrupt() {
    this.interruptLoading.emit(this.attachment.uniqueName);
  }

  onRemove() {
    this.delete.emit();
  }

  onDownload() {
    this.download.emit();
  }

  private trimFileName(fileName: string, trimSize: number): string | null {
    const splitIndex = fileName.lastIndexOf('.');
    const name = fileName.substring(0, splitIndex);
    if (name?.length > trimSize) {
      const extension = fileName?.substring(splitIndex, fileName.length);

      return `${name.substring(0, trimSize)}..${extension}`;
    }

    return null;
  }
}
