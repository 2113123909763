<mat-form-field appearance="outline" [@.disabled]="true">
  <mat-select
    [erpFocus]="isFocused"
    [@.disabled]="true"
    [formControl]="control"
    [placeholder]="placeholder"
    [compareWith]="compareFn"
    [disableOptionCentering]="true"
    (keydown.enter)="onEnterKeyDown($event)"
    (closed)="submitted.emit()"
  >
    <mat-select-trigger *ngIf="displayFn">
      {{ displayFn(options, control.value) }}
    </mat-select-trigger>
    <ng-container *ngIf="optional">
      <mat-option [value]="null">
        {{ defaultOption }}
      </mat-option>
    </ng-container>
    <ng-container *ngFor="let option of options; trackBy: trackByFn">
      <mat-option [value]="valueFn(option)" [disabled]="option.disabled">
        {{ labelFn(option) }}
      </mat-option>
    </ng-container>
  </mat-select>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')" i18n="@@common.form.errors.required">
    This field is required
  </mat-error>
</mat-form-field>
