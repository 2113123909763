<mat-form-field class="textarea-wrapper" appearance="outline" [@.disabled]="true">
  <textarea
    class="textarea"
    [rows]="rows"
    [formControl]="control"
    [maxlength]="maxlength"
    [erpFocus]="isFocused"
    matInput
    mat-autosize
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="1"
    [cdkAutosizeMaxRows]="rows"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"
  ></textarea>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')" i18n="@@common.form.errors.required">
    This field is required
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <ng-container *ngIf="control.getError('minlength') as error" i18n="@@common.form.errors.min-length">
      Use at least {{ error.requiredLength }}
      { error.requiredLength, plural, =1 {character} other {characters} }
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error" i18n="@@common.form.errors.max-length">
      Use {{ error.requiredLength }}
      { error.requiredLength, plural, =1 {character} other {characters} } max
    </ng-container>
  </mat-error>
  <mat-error i18n="@@common.form.errors.alphanum" *ngIf="control.hasError('alphanum')">
    Use letters and numbers only
  </mat-error>
</mat-form-field>
