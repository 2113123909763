import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { IInventoryUom, IMetadataFiltering } from '@erp/shared';

import { ISelectOption } from '../../select';
import { TableFilterType } from '../types';

import { ERPTableFilterRegistry } from './filter-type.registry';

type labelFnType = (val: IInventoryUom) => string;
type valueFnType = (val: IInventoryUom[], id: number) => string;
type InputType = 'integer' | 'decimal';

@Injectable({ providedIn: 'any' })
export class ERPTableFilterOpener {
  constructor(readonly dialogService: MatDialog, readonly tableFilterRegistry: ERPTableFilterRegistry) {}

  open<T>(
    type: TableFilterType,
    data: {
      filter: IMetadataFiltering<T>;
      trigger: HTMLElement;
      options?: ISelectOption<unknown, unknown>[];
      labelFn?: labelFnType;
      displayFn?: valueFnType;
      inputType?: InputType;
    }
  ) {
    const injection = this.tableFilterRegistry.get(type);

    const dialog = this.dialogService.open(injection.component, {
      data: {
        ...injection.data,
        ...data
      },
      backdropClass: 'transparent',
      panelClass: 'erp-filter-dialog',
      minWidth: 'auto'
    });

    return dialog.afterClosed().pipe(
      switchMap(result => {
        if (result === undefined) {
          return EMPTY;
        }

        return of<IMetadataFiltering<T> | null>(result);
      })
    );
  }
}
