import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';
import { ERPToolbarModule } from '../toolbar';

import { ERPSideDialogComponent } from './components';

@NgModule({
  declarations: [ERPSideDialogComponent],
  exports: [ERPSideDialogComponent],
  imports: [CommonModule, MatDialogModule, ERPToolbarModule, ERPIconModule, ERPButtonModule]
})
export class ERPSideDialogModule {}
