<div class="grid">
  <div class="col-12 grid grid-gap-t">
    <div class="col-6">
      <erp-date-picker [formControl]="dateControl">
        <ng-content select="mat-hint"></ng-content>
      </erp-date-picker>
    </div>
    <div class="col-6">
      <erp-time-picker [formControl]="timeControl"></erp-time-picker>
    </div>
  </div>
</div>
