import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';
import { IPDPRoles, IPDPRolesResponseItem } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPPDPRolesService extends BaseHttpService {
  private _prefix: string;

  constructor(readonly httpClient: HttpClient) {
    super('pdp');
  }

  get prefix() {
    return this._prefix;
  }

  getRoles(ids: string[], prefix: string): Observable<IPDPRoles> {
    this._prefix = prefix;

    return this.post<string[], IPDPRolesResponseItem[]>(ids, {}, '', {
      headers: new HttpHeaders({ 'skip-loader': 'true' })
    }).pipe(map(data => this.mapRoles(data)));
  }

  private mapRoles(data: IPDPRolesResponseItem[]): IPDPRoles {
    const result = new Map();

    data.forEach(item => {
      result.set(item.resource, {
        allowed: item.decision === 'Permit'
      });
    });

    return Object.fromEntries(result);
  }
}
