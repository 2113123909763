import { Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import {
  AutoCleanupFeature,
  BaseFormComponent,
  DictionaryService,
  ERPFormStateDispatcher,
  ERPSessionService,
  Features,
  ISession,
  Memoise,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

import { ISelectOption } from '../../../../select';
import { ERPToasterService } from '../../../../toaster';

@Component({
  selector: 'erp-tablet-check-in-dialog',
  templateUrl: './check-in-dialog.component.html',
  styleUrls: ['./check-in-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPTabletCheckInDialogComponent<T> extends BaseFormComponent<ISession> implements OnInit {
  readonly destroyed$: Observable<unknown>;
  lines: ISelectOption[] = [];
  allLines: (ISelectOption & { facilityId: number; name: string })[];

  @ViewChild('checkIn') checkInToast: TemplateRef<unknown>;

  readonly form = new UntypedFormGroup(
    {
      facilityId: new UntypedFormControl(),
      facilityName: new UntypedFormControl(null, [Validators.required]),
      shiftId: new UntypedFormControl(),
      shiftName: new UntypedFormControl(null, [Validators.required]),
      lineId: new UntypedFormControl(),
      lineName: new UntypedFormControl(),
      cncId: new UntypedFormControl(),
      cncName: new UntypedFormControl({ value: null, disabled: true }),
      startDate: new UntypedFormControl(),
      userId: new UntypedFormControl()
    },
    { validators: this.formValidator() }
  );

  constructor(
    readonly formState: ERPFormStateDispatcher,
    readonly dialogRef: MatDialogRef<ERPTabletCheckInDialogComponent<ISession>>,
    @Inject(MAT_DIALOG_DATA) public data: { userId: string },
    readonly dictionariesService: DictionaryService,
    readonly changeDetector: ChangeDetectorRef,
    readonly sessionService: ERPSessionService,
    readonly toaster: ERPToasterService
  ) {
    super();
  }

  ngOnInit() {
    this.form
      .get('lineName')
      ?.valueChanges.subscribe(val => (val ? this.form.get('cncName')?.enable() : this.form.get('cncName')?.disable()));
    this.form.get('facilityId')?.valueChanges.subscribe(facilityId => {
      if (!facilityId) {
        return [];
      }

      this.lines = this.allLines
        .filter(i => i.facilityId === facilityId)
        .map(item => ({ id: item.id, value: item.name })) as ISelectOption[];
      this.changeDetector.markForCheck();
    });
    this.form.get('userId')?.patchValue(this.data.userId);
    this.lines$.subscribe(lines => (this.allLines = lines));
  }

  @Memoise()
  get facilities$() {
    return this.dictionariesService.facilities;
  }

  @Memoise()
  get shifts$() {
    return this.dictionariesService.shifts;
  }

  @Memoise()
  get cncs$() {
    return this.dictionariesService.cncs;
  }

  @Memoise()
  get lines$() {
    return this.dictionariesService.lines;
  }

  onFacilitiesChanged(id: number) {
    this.form.patchValue({
      facilityId: id,
      lineName: null,
      lineId: null,
      cncName: null,
      cncId: null
    });
  }

  onShiftChanged(id: number) {
    this.form.patchValue({
      shiftId: id
    });
  }

  onLineChanged(id: number) {
    this.form.patchValue({
      lineId: id,
      cncId: null,
      cncName: null
    });
  }

  onCNCChanged(id: number) {
    this.form.patchValue({
      cncId: id
    });
  }

  onCreate() {
    const { invalid } = this.form;
    this.form.get('startDate')?.patchValue(new Date());

    const value = this.form.getRawValue();

    if (invalid) {
      return;
    }

    this.formState.onSubmit.notify();

    this.sessionService.createSession(value).subscribe(session => {
      this.toaster.success(
        {
          template: this.checkInToast,
          context: {
            startDate: session?.startDate
          }
        },
        null,
        { showTitle: false }
      );
      this.dialogRef.close(session);
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  private formValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const lineControl = control.get('lineName')?.value;
      const cncControl = control.get('cncName');

      const validators = lineControl ? [Validators.required] : [];

      cncControl?.setValidators(validators);

      return control.valid ? null : { notValid: true };
    };
  }
}
