import '@angular/localize';

import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { IDestroyable } from '@erp/shared';

import { ScreenFamilyContext } from '../utils/screen-family-context';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService implements IDestroyable, OnDestroy {
  readonly destroyed$ = new Subject<void>();

  constructor(readonly titleService: Title, readonly route: ActivatedRoute, readonly router: Router) {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let currentNode: ActivatedRoute | undefined | null = this.route;
          let resultNode = currentNode;
          while (currentNode) {
            const title: string = currentNode.snapshot.data?.title;
            if (title && title.trim() !== '') {
              resultNode = currentNode;
            }

            currentNode = currentNode.firstChild;
          }

          return resultNode?.snapshot.data;
        })
      )
      .subscribe(data => {
        this.scrollToLeft();
        this.titleService.setTitle(data?.title);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  setTitle(title: string) {
    const currentRoute: ActivatedRoute = this.getCurrentRoute();
    currentRoute.snapshot.data.title = title;
    this.titleService.setTitle(title);
  }

  startScreenFamilyTitle(newTitle: string) {
    const currentRoute: ActivatedRoute = this.getCurrentRoute();

    this.setTitle(newTitle);
    const newContext: ScreenFamilyContext = new ScreenFamilyContext(newTitle);
    if (currentRoute.parent) {
      currentRoute.parent.snapshot.data.screenFamilyContext = newContext;
    } else {
      currentRoute.snapshot.data.screenFamilyContext = newContext;
    }
  }

  useScreenFamilyTitle() {
    let newContext: ScreenFamilyContext | undefined;
    let currentNode: ActivatedRoute | undefined | null = this.route;
    while (currentNode) {
      const familyContext: ScreenFamilyContext = currentNode.snapshot.data?.screenFamilyContext;
      if (familyContext) {
        newContext = familyContext;
      }

      currentNode = currentNode.firstChild;
    }

    if (newContext) {
      this.setTitle(newContext.title);
    }
  }

  private scrollToLeft() {
    const html = document.getElementsByTagName('html')[0];

    if (html?.scrollWidth > html?.clientWidth) {
      html.scrollLeft = 0;
    }
  }

  private getCurrentRoute(): ActivatedRoute {
    let currentNode: ActivatedRoute | undefined | null = this.route;
    let resultNode = currentNode;
    while (currentNode) {
      resultNode = currentNode;
      currentNode = currentNode.firstChild;
    }

    return resultNode;
  }
}
