import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPDatePickerModule } from '../date-picker';

import { ERPDatePickerFilterComponent } from './date-picker-filter.component';

@NgModule({
  imports: [CommonModule, ERPDatePickerModule],
  declarations: [ERPDatePickerFilterComponent],
  exports: [ERPDatePickerFilterComponent]
})
export class ERPDatePickerFilterModule {}
