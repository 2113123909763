import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { ERPSharedModule } from '../../../../../shared/src/lib';

import { ERPSelectComponent } from './select.component';

@NgModule({
  declarations: [ERPSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatSelectModule,
    ERPSharedModule
  ],
  exports: [ERPSelectComponent, MatFormFieldModule, ReactiveFormsModule]
})
export class ERPSelectModule {}
