import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';

import { ERPActionsSelectComponent } from './components';

@NgModule({
  declarations: [ERPActionsSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatMenuModule,
    ERPIconModule,
    ERPButtonModule
  ],
  exports: [ERPActionsSelectComponent]
})
export class ERPActionsSelectModule {}
