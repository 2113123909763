import { Directive, HostListener, Input, Self } from '@angular/core';
import {
  LegacyTooltipPosition as TooltipPosition,
  MatLegacyTooltip as MatTooltip
} from '@angular/material/legacy-tooltip';

@Directive({
  selector: '[erpTooltip]',
  providers: [MatTooltip]
})
export class TooltipDirective {
  @Input('erpTooltip')
  readonly message: string;
  @Input('erpTooltipPosition')
  readonly position: TooltipPosition = 'above';
  @Input('erpTooltipDisabled')
  readonly disabled = false;
  @Input() readonly erpTooltipShowDelay = 0;
  @Input() readonly erpTooltipHideDelay = 0;

  constructor(@Self() readonly tooltip: MatTooltip) {
    this.tooltip = tooltip;
    this.tooltip.tooltipClass = 'erp-tooltip';
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.tooltip.message = this.message;
    this.tooltip.position = this.position;
    this.tooltip.disabled = this.disabled;
    this.tooltip.show(this.erpTooltipShowDelay);
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.tooltip.hide(this.erpTooltipHideDelay);
  }
}
