import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import {
  AutoCleanupFeature,
  BaseFormComponent,
  ERPInfinityScrollService,
  ERP_HUGE_TABLE_PAGING,
  Features,
  IMetadataFiltering,
  IMetadataPaging
} from '@erp/shared';

const VALUE_CHANGE_DEBOUNCE_TIME = 700;

@Component({
  selector: 'erp-notification-filter',
  templateUrl: './notification-filter.component.html',
  styleUrls: ['./notification-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPNotificationFilterComponent extends BaseFormComponent<unknown> implements OnInit {
  @Output() readonly filteringChange: EventEmitter<IMetadataFiltering[]> = new EventEmitter();

  @Input() isTablet: boolean;
  @Input() set filtering(filters: IMetadataFiltering[]) {
    if (Array.isArray(filters)) {
      const mapObj = filters.reduce((accumulator, currentValue) => {
        if (currentValue.by === 'isRead' && !currentValue.match1) {
          accumulator.set(currentValue.by, true);
        } else {
          accumulator.set(currentValue.by, currentValue.match1 as string);
        }

        return accumulator;
      }, new Map<string, string | boolean>());

      this.form?.patchValue(Object.fromEntries(mapObj), { emitEvent: false });
    }
  }

  readonly form = new UntypedFormGroup({
    searchValue: new UntypedFormControl(),
    isRead: new UntypedFormControl()
  });

  constructor(
    @Inject(ERP_HUGE_TABLE_PAGING) readonly paging: IMetadataPaging,
    readonly infinityScrollService: ERPInfinityScrollService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(VALUE_CHANGE_DEBOUNCE_TIME),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(formData => {
        const filters: IMetadataFiltering[] = [];

        for (const [key, value] of Object.entries(formData)) {
          if (key === 'searchValue' && value) {
            filters.push({ by: key, match1: value, op: 'contains' });
          }
          if (key === 'isRead' && value) {
            filters.push({ by: key, match1: false, op: 'eq' });
          }
        }

        this.filteringChange.emit(filters);
      });
  }
}
