import { HttpClient, HttpParameterCodec, HttpParams, HttpRequest } from '@angular/common/http';

import { DefaultQueryEncoder } from './default-query.encoder';

export abstract class BaseHttpService {
  protected readonly baseUrl: string;
  protected readonly encoder: HttpParameterCodec;
  protected abstract readonly httpClient: HttpClient;

  constructor(path: string) {
    this.baseUrl = `/gateway/api/v1/${path}`;
    this.encoder = new DefaultQueryEncoder();
  }

  get<TResponse>(params?: {}, path: string | number = '', config?: {}, rewriteUrl = false) {
    const url = rewriteUrl ? path.toString() : `${this.baseUrl}/${path}`;

    return this.httpClient.get<TResponse>(url, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  delete<TResponse>(params?: {}, path: string | number = '', config?: {}) {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.delete<TResponse>(url, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  post<TRequest, TResponse = TRequest>(body: TRequest, params?: {}, path: string | number = '', config?: {}) {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.post<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  put<TRequest, TResponse = TRequest>(body: TRequest, params?: {}, path: string | number = '', config?: {}) {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.put<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  patch<TRequest, TResponse = TRequest>(body: TRequest, params?: {}, path: string | number = '', config?: {}) {
    const url = `${this.baseUrl}/${path}`;

    return this.httpClient.patch<TResponse>(url, body, {
      ...config,
      params: new HttpParams({
        fromObject: params,
        encoder: this.encoder
      })
    });
  }

  request<TRequest, TResponse = TRequest>(request: HttpRequest<TRequest>) {
    return this.httpClient.request<TResponse>({
      params: new HttpParams({
        fromObject: request.params as {},
        encoder: this.encoder
      })
    } as HttpRequest<unknown>);
  }
}
