export class ERPDateDurationUtil {
  static hoursPerDay = 24;
  static minutesPerHour = 60;
  static msPerMinute = 60000;

  static formatDuration(timeExp: string) {
    const timeParts = timeExp?.split('.');
    const result = [];
    const hoursIndex = 2;
    let time = '';
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let timePoints: string[] = [];

    if (timeParts) {
      time = timeParts?.length > 1 ? timeParts[1] : timeParts[0];
      days = timeParts?.length > 1 ? Number(timeParts[0]) : 0;
      timePoints = time.split(':').reverse();
      seconds = timePoints.length > 1 ? Number(timePoints[0]) : 0;
      minutes = timePoints.length > 1 ? Number(timePoints[1]) : 0;
      hours = timePoints.length > hoursIndex ? Number(timePoints[hoursIndex]) : 0;
      hours += days * ERPDateDurationUtil.hoursPerDay;
      if (seconds > 0) {
        minutes++;
      }
    }

    return ERPDateDurationUtil.concatDurationString(0, hours, minutes);
  }

  static getDateDuration(startDateTime: Date, endDateTime: Date): string {
    const msInHour = ERPDateDurationUtil.msPerMinute * ERPDateDurationUtil.minutesPerHour;
    const msInDay = msInHour * ERPDateDurationUtil.hoursPerDay;

    let durationMs = endDateTime.getTime() - startDateTime.getTime();

    const durationDays = Math.floor(durationMs / msInDay);
    durationMs = durationMs - durationDays * msInDay;
    const durationHours = Math.floor(durationMs / msInHour);
    durationMs = durationMs - durationHours * msInHour;
    const durationMinutes = Math.floor(durationMs / ERPDateDurationUtil.msPerMinute);
    durationMs = durationMs - durationMinutes * ERPDateDurationUtil.msPerMinute;

    return ERPDateDurationUtil.concatDurationString(durationDays, durationHours, durationMinutes);
  }

  private static concatDurationString(days: number, hours: number, minutes: number): string {
    const result: string[] = [];
    ERPDateDurationUtil.addTimePartIfNeeded(result, days, 'd');
    ERPDateDurationUtil.addTimePartIfNeeded(result, hours, 'h');
    ERPDateDurationUtil.addTimePartIfNeeded(result, minutes, 'min');

    return result.join(' ');
  }

  private static addTimePartIfNeeded(parts: string[], time: number, unit: string): string[] {
    if (time !== 0) {
      parts.push(`${time}${unit}`);
    }

    return parts;
  }
}
