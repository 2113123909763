export enum PoAttachmentCategory {
  CustomersDocument = 14,
  HeatTreat,
  Hydro,
  Upset,
  PriReports,
  Thread,
  AncillaryReports,
  Mtrs,
  LotRelease,
  WoprsProprs,
  NCP,
  INCP,
  InspectionReports,
  TallyResults,
  WeightResults,
  Others
}
