import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

const REQUEST_TIMEOUT = 60_000;
const LONG_REQUEST_TIMEOUT = 180_000;

const yardToDateFileUrl = 'gateway/api/v1/yardToDate/file';

@Injectable({ providedIn: 'root' })
export class TimeoutInterceptor implements HttpInterceptor {
  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (req.url.includes(yardToDateFileUrl)) {
      return next.handle(req).pipe(timeout(LONG_REQUEST_TIMEOUT));
    }

    return next.handle(req).pipe(timeout(REQUEST_TIMEOUT));
  }
}
