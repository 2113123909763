import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import { ICollectionResponse } from '../abstracts/http/collection-response.interface';
import { ISalesOrderReferences, ISalesOrderResponse } from '../interfaces/sales-order.interface';

import { ISelectOption } from '../../../../components/src/lib';
import { ERPSharedSalesOrderFactory } from './shared-sales-order.factory';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedSalesOrderService extends BaseHttpService {
  constructor(
    readonly httpClient: HttpClient,
    readonly salesOrderFactory: ERPSharedSalesOrderFactory
  ) {
    super('salesOrder');
  }

  getById(id: string) {
    return this.get<ISalesOrderResponse>({}, id).pipe(map(order => this.salesOrderFactory.fromResponse(order)));
  }

  getSalesOrdersReferences(params: object) {
    return this.get<ICollectionResponse<ISalesOrderReferences>>(params, 'reference');
  }

  getAvailableStatuses(currentStatusId?: number) {
    const params: { [key: string]: string | number } = {};
    if (currentStatusId) { params.currentStatusId = currentStatusId; }

    return this.get<ISelectOption[]>(params, 'availableStatuses');
  }
}
