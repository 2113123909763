export enum ProductionServiceType {
  HeatTreat = 1,
  Upsetting = 2,
  Hydro = 3,
  Inspection = 4,
  Threading = 5,
  Laboratory = 6,
  Ancillary = 7,
  SawCut = 8,
  Gag = 9
}
