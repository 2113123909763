import { identity } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ERPTabletBuckOnDialogComponent } from '../components';

@Injectable({
  providedIn: 'root'
})
export class ERPBuckOnService {
  constructor(private dialogService: MatDialog) {}

  openDialog(mode: 'create' | 'view' | 'edit', buckOnData?: object) {
    return this.dialogService
      .open(ERPTabletBuckOnDialogComponent, {
        data: { mode, buckOnData },
        maxHeight: 720
      })
      .afterClosed()
      .pipe(filter(identity));
  }
}
