<mat-form-field appearance="outline" [@.disabled]="true">
  <input
    autocomplete="off"
    #input="matInput"
    [formControl]="control"
    [type]="type"
    matInput
    [placeholder]="placeholder"
    (keydown.enter)="onEnterKeyDown($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [maxlength]="maxlength"
    [matAutocomplete]="auto"
    [erpFocus]="isFocused"
  />

  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>
  <mat-error *ngIf="control.hasError('required')" i18n="@@common.form.errors.required">
    This field is required
  </mat-error>
  <mat-error *ngIf="control.hasError('unique')" i18n="@@common.form.errors.unique">
    This field should be unique
  </mat-error>
  <mat-error *ngIf="control.hasError('minlength')">
    <ng-container *ngIf="control.getError('minlength') as error" i18n="@@common.form.errors.min-length">
      Use at least {{ error.requiredLength }}
      { error.requiredLength, plural, =1 {character} other {characters} }
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error" i18n="@@common.form.errors.max-length">
      Use {{ error.requiredLength }}
      { error.requiredLength, plural, =1 {character} other {characters} } max
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('integer')" i18n="@@common.form.errors.integer">
    Use integers only
  </mat-error>
  <mat-error *ngIf="control.hasError('email')" i18n="@@common.form.errors.email">
    Invalid email format
  </mat-error>
  <mat-error *ngIf="control.hasError('alphanum')" i18n="@@common.form.errors.alphanum">
    Use letters and numbers only
  </mat-error>
  <mat-error *ngIf="control.hasError('endsWithPeriod')" i18n="@@common.form.errors.ends-with-period">
    Can't end with a period
  </mat-error>
  <mat-error *ngIf="control.hasError('specialChars')" i18n="@@common.form.errors.special-chars">
    Can't contain any of these characters: &quot; / \ [ ] : | &lt; &gt; + = ; ? * ,
  </mat-error>
  <mat-error *ngIf="control.hasError('alphanumWithSymbols')" i18n="@@common.form.errors.alphanum-with-symbol">
    Can't use non-English letters
  </mat-error>
  <mat-error *ngIf="control.hasError('alreadyExists')">
    <ng-container *ngIf="control.getError('alreadyExists') as error" i18n="@@common.form.errors.already-exists">
      This {{ error.field }} already exists
    </ng-container>
  </mat-error>
</mat-form-field>
