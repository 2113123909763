<erp-button color="primary" variant="icon" class="dialog-close" [mat-dialog-close]="false">
  <erp-icon icon="action:cross"></erp-icon>
</erp-button>
<section class="dialog-content" [formGroup]="form">
  <erp-inventory-sku-smart-search-filtering
    formControlName="filter"
    class="margin-bottom-3"
    (filtering)="onFilteringChange($event)"
  ></erp-inventory-sku-smart-search-filtering>
  <erp-inventory-sku-smart-search-table
    [tableData]="tableData"
    (productSelection)="onProductSelected($event)"
    (sortingChange)="onSortingChange($event)"
    [sorting]="sorting"
    [total]="paging?.total"
  ></erp-inventory-sku-smart-search-table>
  <div class="margin-top-4 flex justify-content-flex-end">
    <erp-paginator
      [total]="paging?.total"
      [page]="paging?.page"
      [pageSize]="paging?.perPage"
      (pageChange)="onChangePage($event)"
    ></erp-paginator>
  </div>
</section>
