export const groupBy = <T, K extends keyof T>(objectArray: T[], property: string) => {
  return objectArray.reduce((acc: { [key: string]: T[] }, obj) => {
    // @ts-ignore
    const key = obj[property];

    acc[key] = [...(acc[key] || []), obj];

    return acc;
  }, {} as Record<K, T[]>);
};
