import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse, IPatchRequest, TableQueryBuilder } from '@erp/shared';

import { INotification } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPNotificationService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('users');
  }

  getUnreadCount(userId: string, params: object): Observable<number> {
    return this.get<number>(params, `${encodeURIComponent(userId)}/notifications/counter`);
  }

  getList(userId: string, params: object): Observable<ICollectionResponse<INotification>> {
    return this.get<ICollectionResponse<INotification>>(params, `${encodeURIComponent(userId)}/notifications`).pipe(
      tap(list => list.data.forEach(item => (item.date = new Date(item.date))))
    );
  }

  markReadState(userId: string, id: number, isRead: boolean) {
    const request = [
      {
        op: 'replace',
        path: 'isRead',
        value: isRead
      } as IPatchRequest<INotification>
    ];

    return this.patch<IPatchRequest<INotification>[], INotification>(
      request,
      {},
      `${encodeURIComponent(userId)}/notifications/${id}`,
      {
        headers: new HttpHeaders({
          'skip-loader': 'true'
        })
      }
    );
  }

  markAllAsRead(userId: string, params: object): Observable<INotification[]> {
    const queryObject = new TableQueryBuilder({
      filtering: [{ by: 'isRead', match1: false, op: 'eq' }]
    });
    const query = queryObject.serialize();

    const request = [
      {
        op: 'replace',
        path: 'isRead',
        value: true
      } as IPatchRequest<INotification>
    ];

    return this.patch<IPatchRequest<INotification>[], INotification[]>(
      request,
      { query, ...params },
      `${encodeURIComponent(userId)}/notifications`
    );
  }
}
