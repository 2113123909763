import { Observable, combineLatest } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';

import { AutoCleanupFeature, BaseControlComponent, ERPFormStateDispatcher, Features } from '@erp/shared';

@Component({
  selector: 'erp-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPDateTimePickerComponent extends BaseControlComponent<Date> implements OnInit {
  readonly destroyed$: Observable<void>;

  readonly control = new UntypedFormControl(null);
  readonly dateControl = new UntypedFormControl(null);
  readonly timeControl = new UntypedFormControl(null);

  readonly modelToViewFormatter = (value: Date | null) => {
    this.dateControl.setValue(value);
    this.timeControl.setValue(value);

    return value;
  };

  constructor(
    @Inject(NgControl) readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() @SkipSelf() readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.setValidators();

    this.listenDateTimeChange();
    this.listenFormSubmit();

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  private listenFormSubmit() {
    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.dateControl.markAsTouched();
      this.timeControl.markAsTouched();

      this.changeDetector.markForCheck();
    });
  }

  private listenDateTimeChange() {
    combineLatest([
      this.dateControl.valueChanges.pipe(startWith(this.dateControl.value)),
      this.timeControl.valueChanges.pipe(startWith(this.timeControl.value))
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([date, time]: [Date | null, Date | null]) => {
        this.setDateTime(date, time);
      });
  }

  private setDateTime(date: Date | null, time: Date | null) {
    let value: Date | null = null;

    if (date instanceof Date || time instanceof Date) {
      value = new Date();

      if (time instanceof Date) {
        this.setTimePart(value, time);
        this.setDatePart(value, time);
      }

      if (date instanceof Date) {
        this.setDatePart(value, date);
      }
    }

    this.control.setValue(value);
  }

  private setValidators() {
    const validator = this.ctrl.control?.validator ?? null;
    const asyncValidator = this.ctrl.control?.asyncValidator ?? null;

    this.control.setValidators(validator);
    this.control.setAsyncValidators(asyncValidator);

    this.dateControl.setValidators(validator);
    this.dateControl.setAsyncValidators(asyncValidator);

    this.timeControl.setValidators(validator);
    this.timeControl.setAsyncValidators(asyncValidator);

    this.onValidatorChange?.();
  }

  private setTimePart(value: Date, time: Date) {
    value.setUTCHours(time.getUTCHours());
    value.setUTCMinutes(time.getUTCMinutes());
    value.setUTCSeconds(time.getUTCSeconds());
    value.setUTCMilliseconds(time.getUTCMilliseconds());
  }

  private setDatePart(value: Date, date: Date) {
    value.setUTCFullYear(date.getUTCFullYear());
    value.setUTCMonth(date.getUTCMonth());
    value.setUTCDate(date.getUTCDate());
  }
}
