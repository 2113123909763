import { AbstractControl } from '@angular/forms';

export function MinTimeValidator(comparator: string) {
  return (control: AbstractControl) => {
    const regex = /^(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]$/;
    const value = control.value as string | '';
    const isValidTime = !value || regex.test(value);
    const isValidTimeComparator = !value || regex.test(comparator);
    let valid = true;

    if (!isValidTime || !isValidTimeComparator || !value) {
      return null;
    }

    const [ctrH, ctrM] = value?.split(':');
    const [cmpH, cmpM] = comparator?.split(':');

    if (ctrH < cmpH || (ctrH === cmpH && ctrM <= cmpM)) {
      valid = false;
    }

    return valid ? null : { minTime: {} };
  };
}
