import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'erp-date-picker-filter',
  templateUrl: './date-picker-filter.component.html',
  styleUrls: ['./date-picker-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPDatePickerFilterComponent {
  @Input() title: string;
  @Input() set fromDate(date: string | Date) {
    this.form.get('fromDate')?.patchValue(date);
  }
  @Input() set toDate(date: string | Date) {
    this.form.get('toDate')?.patchValue(date);
  }
  @Output() readonly fromDateChanged = new EventEmitter<string | Date>();
  @Output() readonly toDateChanged = new EventEmitter<string | Date>();

  readonly form = new UntypedFormGroup({
    fromDate: new UntypedFormControl(null),
    toDate: new UntypedFormControl(null)
  });

  get fromValue(): string | Date {
    return this.form.get('fromDate')?.value;
  }

  get toValue(): string | Date {
    return this.form.get('toDate')?.value;
  }

  get isError(): boolean {
    if (!!this.toValue) {
      return this.toValue < this.fromValue;
    }

    return false;
  }
}
