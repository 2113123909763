<erp-confirm
  i18n-header="@@common.dialogs.discard-changes.header"
  header="You have unsaved changes."
  i18n-message="@@common.dialogs.discard-changes.message"
  message="Discard these changes?"
  i18n-cancel="@@common.dialogs.discard-changes.buttons.keep-changes"
  cancel="Keep Changes"
  i18n-confirm="@@common.dialogs.discard-changes.buttons.discard-changes"
  confirm="Discard Changes"
></erp-confirm>
