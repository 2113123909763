import { identity } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ERPConfirmModule } from '../../confirm';

import { ERPReloadWindowDialogComponent } from './components';

@NgModule({
  declarations: [ERPReloadWindowDialogComponent],
  imports: [CommonModule, ERPConfirmModule],
  exports: [ERPReloadWindowDialogComponent]
})
export class ERPReloadWindowDialogModule {
  constructor(private readonly dialogService: MatDialog) {}

  openDialog() {
    this.dialogService
      .open(ERPReloadWindowDialogComponent)
      .afterClosed()
      .pipe(filter(identity))
      .subscribe(() => location.reload());
  }
}
