import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { ERPSharedModule } from '../../../../../shared/src/lib';
import { ERPIconModule } from '../icon';

import { ERPTimePickerComponent } from './components';

@NgModule({
  declarations: [ERPTimePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatInputModule,
    ERPIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ERPSharedModule
  ],
  exports: [ERPTimePickerComponent],
  providers: [provideNgxMask()]
})
export class ERPTimePickerModule {}
