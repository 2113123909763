import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { DictionaryService } from '../services';

@Injectable({ providedIn: 'root' })
export class ERPMaterialTypesResolver {
  constructor(readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.materialTypes.pipe(
      // @ts-ignore: Object is possibly 'undefined'.
      map(types => types.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0)))
    );
  }
}
