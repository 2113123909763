import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ERPPageIdentifierService {
  selector = 'table';

  constructor(private router: Router) {}

  getIdentifier(storageIdentifier: string, element: HTMLElement) {
    const tableIdentifier =
      storageIdentifier ?? Array.from(document.querySelectorAll(this.selector)).indexOf(element as HTMLTableElement);

    return `${this.getPageId()}-${tableIdentifier}`;
  }

  getWidthIdentifier(storageIdentifier: string, element: HTMLElement) {
    return `cols-${this.getIdentifier(storageIdentifier, element)}`;
  }

  getOrderIdentifier(storageIdentifier: string, element: HTMLElement) {
    return `ord-${this.getIdentifier(storageIdentifier, element)}`;
  }

  getActiveIdentifier(storageIdentifier: string, element: HTMLElement) {
    return `act-${this.getIdentifier(storageIdentifier, element)}`;
  }

  private getPageId() {
    const urlTree = this.router.parseUrl(this.router.url);

    return urlTree.root.children.primary.segments
      .filter(s => s.path !== 'edit')
      .filter(s => s.path !== 'new')
      .map(s => (isNaN(Number(s.path)) ? (s.path !== 'create' ? s.path : ':id') : ':id'))
      .join('/');
  }
}
