import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'erp-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPExpansionPanelComponent {
  @Input() readonly padding: 'vertical' | 'horizontal' | 'default' = 'default';
  @Input() readonly header: string;
  @Input() readonly expandDirection: 'up' | 'down' = 'up';

  @Output() readonly expansionChange = new EventEmitter<boolean>();

  private _expanded = true;

  @Input() set expanded(expanded: boolean) {
    this._expanded = expanded;
  }

  get expanded() {
    return this._expanded;
  }

  constructor(readonly changeDetector: ChangeDetectorRef) {}

  onOpen() {
    this.expanded = true;
    this.changeDetector.markForCheck();
    this.expansionChange.emit(this.expanded);
  }

  onClose() {
    this.expanded = false;
    this.changeDetector.markForCheck();
    this.expansionChange.emit(this.expanded);
  }
}
