import { Observable, filter, first, iif, map, mapTo, of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';
import { EInventoryStatuses } from '../enums';
import { IDefaultOwner } from '../interfaces';
import { IdValue } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ERPSettingsService extends BaseHttpService {
  private _defaultLocation = '';

  constructor(readonly httpClient: HttpClient) {
    super('settings');
  }

  getDefaultLocation(params = {}): Observable<IdValue> {
    return this.get<IdValue>(params, `location`);
  }

  getDefaultOwner(params = {}): Observable<IDefaultOwner> {
    return this.get<IDefaultOwner>(params, `owner`);
  }

  getFTZLocation(): Observable<IdValue<EInventoryStatuses>> {
    return this.get<IdValue<EInventoryStatuses>>({}, 'inventoryStatus/ftz');
  }

  get defaultLocation(): string {
    return this._defaultLocation;
  }

  onLoadDefaultLocation(): Observable<string> {
    return iif(() => !!this._defaultLocation, of(null), this.getDefaultLocation()).pipe(
      map((location: IdValue | null) => location?.value ?? ''),
      tap((location: string) => (location ? (this._defaultLocation = location) : null)),
      mapTo('')
    );
  }
}
