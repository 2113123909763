import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  HostBinding,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { IToaster, IToasterConfig, IToasterTemplate } from '../../interface';
import { ToasterData, ToasterMessage, ToasterMessageType } from '../../types';
import { ERPToasterComponent } from '../toaster';

@Component({
  selector: 'erp-toaster-outlet',
  templateUrl: './toaster-outlet.component.html',
  styleUrls: ['./toaster-outlet.component.scss']
})
export class ERPToasterOutletComponent implements IToaster {
  @ViewChild('outlet', {
    read: ViewContainerRef,
    static: true
  })
  readonly viewContainer: ViewContainerRef;
  @Input()
  @HostBinding('attr.type')
  readonly type: 'root' | 'inline' = 'inline';
  @Input() readonly max: number = Number.POSITIVE_INFINITY;
  @Input() readonly duplicate: boolean = false;

  readonly visibleToastSet = new Set<ComponentRef<ERPToasterComponent>>();
  readonly toastFactory: ComponentFactory<ERPToasterComponent>;

  constructor(readonly factoryResolver: ComponentFactoryResolver, readonly changeDetector: ChangeDetectorRef) {
    this.toastFactory = this.factoryResolver.resolveComponentFactory(ERPToasterComponent);
  }

  get empty() {
    return Boolean(this.visibleToastSet.size);
  }

  success(data: ToasterData, target?: IToaster | null, options?: IToasterConfig): void {
    this.createToast(data, 'success', options);
  }

  error(data: ToasterData, target?: IToaster | null, options?: IToasterConfig): void {
    this.createToast(data, 'error', options);
  }

  info(data: ToasterData, target?: IToaster | null, options?: IToasterConfig): void {
    this.createToast(data, 'info', options);
  }

  warn(data: ToasterData, target?: IToaster | null, options?: IToasterConfig): void {
    this.createToast(data, 'warning', options);
  }

  clearAll() {
    this.viewContainer.clear();
  }

  removeAt(index: number) {
    const toastViewRef = this.viewContainer.get(index);
    const toastComponentRef = [...this.visibleToastSet].find(x => x.hostView === toastViewRef);

    toastComponentRef?.instance.remove.emit();
  }

  private createToast(data: ToasterData, type: ToasterMessageType, config?: IToasterConfig) {
    this.executeOptions(data);

    const componentRef = this.createComponent(type, data, config);

    this.visibleToastSet.add(componentRef);

    this.changeDetector.markForCheck();
  }

  private createComponent(type: ToasterMessageType, data: ToasterData, config?: IToasterConfig) {
    const componentRef = this.viewContainer.createComponent(this.toastFactory);

    this.configureInstance(componentRef, type, data, config);

    return componentRef;
  }

  private executeOptions(data: ToasterData) {
    if (!this.duplicate) {
      const existingIndex = this.getExistingToastIndex(data);

      if (existingIndex !== -1) {
        this.removeAt(existingIndex);
      }
    }
    if (this.viewContainer.length >= this.max) {
      this.removeAt(0);
    }
  }

  private configureInstance(
    componentRef: ComponentRef<ERPToasterComponent>,
    type: ToasterMessageType,
    data: ToasterData,
    config?: IToasterConfig
  ) {
    const instance = componentRef.instance;

    instance.containment = this.type;
    instance.type = type;
    instance.data = data;
    instance.config = config;

    instance.remove.subscribe(() => {
      componentRef.destroy();
      this.visibleToastSet.delete(componentRef);
    });
  }

  private getExistingToastIndex(data: ToasterData) {
    return [...this.visibleToastSet].findIndex(({ instance }) => {
      const instanceMessageData = instance.data as ToasterMessage;
      const instanceTemplateData = instance.data as IToasterTemplate;
      const messageData = data as ToasterMessage;
      const templateData = data as IToasterTemplate;

      const areTemplatesEqual = instanceTemplateData.template === templateData.template;
      const areContextsEqual = JSON.stringify(instanceTemplateData.context) === JSON.stringify(templateData.context);

      return instanceMessageData === messageData || (areTemplatesEqual && areContextsEqual);
    });
  }
}
