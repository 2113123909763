import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import {
  AutoCleanupFeature,
  CustomValidators,
  ERPDateUtil,
  ERPFiltersUtil,
  Features,
  IDestroyable,
  IQueryFiltering,
  QueryOperation
} from '@erp/shared';

import { BaseTableFilter } from '../../abstracts';

const END_HOURS = 23;
const END_MINUTES = 59;

@Component({
  selector: 'erp-table-date-time-filter',
  templateUrl: './table-date-time-filter.component.html',
  styleUrls: ['./table-date-time-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPTableDateTimeFilterComponent extends BaseTableFilter implements OnInit, IDestroyable {
  form: UntypedFormGroup;
  readonly destroyed$: Observable<unknown>;

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly dialogRef: MatDialogRef<ERPTableDateTimeFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { trigger: HTMLElement; filter: IQueryFiltering },
    readonly dialogElementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.setDialogPosition();
    this.listenFormChanges();
  }

  onApply() {
    if (this.form.invalid) {
      return;
    }
    const from: Date = this.form.get('from')?.value;
    const to: Date | null = this.form.get('to')?.value;
    const toTime: Date | null = this.form.get('toTime')?.value;

    if (to && !toTime) {
      to.setHours(END_HOURS, END_MINUTES);
    }
    if (from || to) {
      let op: QueryOperation;
      let match1: Date;
      let match2: Date | null = to;

      if (from && to) {
        if (from > to) {
          return;
        }
        op = 'between';
        match1 = from;
      } else {
        if (from) {
          op = 'gte';
          match1 = from;
          match2 = null;
        } else {
          op = 'lte';
          match1 = to as Date;
          match2 = null;
        }
      }

      const filter: IQueryFiltering = { by: this.data.filter?.by, match1, match2, op };

      return this.dialogRef.close(filter);
    }
    this.dialogRef.close(null);
  }

  private listenFormChanges() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      if (val.from instanceof Date) {
        if (val.fromTime) {
          if (ERPDateUtil.isTimeValid(val.fromTime)) {
            const [hours, minutes] = val.fromTime.split(':');
            val.from.setUTCHours(+hours || 0, +minutes || 0);
          }
        } else {
          val.from.setUTCHours(0, 0);
        }
      }

      if (val.to instanceof Date) {
        if (val.toTime) {
          if (ERPDateUtil.isTimeValid(val.toTime)) {
            const [hours, minutes] = val.toTime.split(':');
            val.to.setUTCHours(+hours || 0, +minutes || 0);
          }
        } else {
          val.to.setUTCHours(0, 0);
        }
      }
      this.form.patchValue(val, { emitEvent: false });

      this.changeDetector.markForCheck();
    });
  }

  private initForm() {
    let from;
    let to = null;
    if (ERPFiltersUtil.isBetweenFilter(this.data.filter)) {
      from = this.data.filter.match1;
      to = this.data.filter.match2;
    } else {
      from = ERPFiltersUtil.isGreaterThanOrEqualFilter(this.data.filter) ? this.data.filter.match1 : null;
      to = ERPFiltersUtil.isLaterThanFilter(this.data.filter) ? this.data.filter.match1 : null;
    }

    const fromTime = ERPDateUtil.getTimeFromDate(from as Date);
    const toTime = ERPDateUtil.getTimeFromDate(to as Date);

    this.form = new UntypedFormGroup(
      {
        from: new UntypedFormControl(from),
        fromTime: new UntypedFormControl(fromTime),
        to: new UntypedFormControl(to),
        toTime: new UntypedFormControl(toTime)
      },
      [CustomValidators.range]
    );
  }
}
