import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPIconModule } from '../icon';

import { ERPHelpComponent } from './components/help';

@NgModule({
  declarations: [ERPHelpComponent],
  imports: [CommonModule, ERPIconModule],
  exports: [ERPHelpComponent]
})
export class ERPHelpModule {}
