import { Injectable } from '@angular/core';

import { ERPSettingsService } from '../services';
import { IdValue } from '../types';

@Injectable({ providedIn: 'root' })
export class ERPDefaultLocationResolver {
  constructor(readonly settingsService: ERPSettingsService) {}

  resolve() {
    return this.settingsService.getDefaultLocation();
  }
}
