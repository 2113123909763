import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPInputModule } from '../input';
import { ERPLabelModule } from '../label';
import { ERPNumberModule } from '../number';
import { ERPSelectModule } from '../select';

import { ERPAddressComponent } from './address.component';

@NgModule({
  declarations: [ERPAddressComponent],
  imports: [CommonModule, ERPInputModule, ERPNumberModule, ERPLabelModule, ERPSelectModule],
  exports: [ERPAddressComponent]
})
export class ERPAddressModule {}
