import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts/http';
import { TableQueryBuilder } from '../builders';
import { ERPWorkCenterDowntimeFactory } from '../factories';
import { IActiveService, IWorkCenterDowntime, IWorkCenterDowntimeResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPWorkCenterDowntimeService extends BaseHttpService {
  constructor(
    readonly httpClient: HttpClient,
    readonly factory: ERPWorkCenterDowntimeFactory
  ) {
    super('workCenters');
  }

  create(item: IWorkCenterDowntime, workCenterId: number): Observable<IWorkCenterDowntime> {
    const request = this.factory.toRequest(item);

    return this.post<IWorkCenterDowntimeResponse>(request, {}, `${workCenterId}/downtimes`).pipe(
      map(x => this.factory.fromResponse(x))
    );
  }

  update(item: IWorkCenterDowntime, workCenterId: number, id: number): Observable<IWorkCenterDowntime> {
    const request = this.factory.toRequest(item);

    return this.put<IWorkCenterDowntimeResponse>(request, {}, `${workCenterId}/downtimes/${id}`).pipe(
      map(x => this.factory.fromResponse(x))
    );
  }

  getHistory(workCenterId: number, params: object = {}): Observable<IWorkCenterDowntime[]> {
    return this.get<ICollectionResponse<IWorkCenterDowntimeResponse>>(params, `${workCenterId}/downtimes`).pipe(
      map(response => response.data.map(x => this.factory.fromResponse(x)))
    );
  }

  getItem(workCenterId: number, id: number): Observable<IWorkCenterDowntime> {
    const query = new TableQueryBuilder({})
      .withFiltering([
        {
          by: 'id',
          op: 'eq',
          match1: id
        }
      ])
      .serialize();

    return this.getHistory(workCenterId, { query }).pipe(
      switchMap(res => {
        const downtimeList = res as IWorkCenterDowntime[];

        return of(downtimeList[0]);
      })
    );
  }

  getActualDowntime(workCenterId: number): Observable<IWorkCenterDowntime> {
    const query = new TableQueryBuilder({})
      .withFiltering([
        {
          by: 'endDate',
          op: 'eq',
          match1: null
        },
        {
          by: 'processingServiceId',
          op: 'eq',
          match1: null
        }
      ])
      .serialize();

    return this.getHistory(workCenterId, { query }).pipe(
      switchMap(res => {
        const downtimeList = res as IWorkCenterDowntime[];

        return of(downtimeList[0]);
      })
    );
  }

  getActiveService(wcId: number): Observable<IActiveService> {
    return this.get({}, `${wcId}/activeService`);
  }
}
