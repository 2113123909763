import { Observable, identity } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractControl, UntypedFormArray, ValidationErrors, Validator } from '@angular/forms';

import { IDestroyable } from '../../lifecycle';
import { BaseControl } from '../base';

export abstract class BaseArrayComponent<T, R = T> extends BaseControl<T[]> implements Validator, IDestroyable {
  readonly destroyed$: Observable<unknown>;
  abstract readonly form: UntypedFormArray;
  protected onValidatorChange?: () => void;
  // @ts-ignore
  protected onChanged?: (value: T[]) => void;
  protected onTouched?: () => void;

  protected readonly viewToModelParser: (value: T[]) => T[] = identity;
  protected readonly modelToViewFormatter: (value: T[]) => (T | AbstractControl)[] = identity;

  get value(): T[] {
    return this.form.getRawValue() as unknown as T[];
  }

  writeValue(value: T[]): void {
    if (value) {
      this.form.clear();
      this.modelToViewFormatter?.(value as unknown as T[]).forEach(el => this.form.push(el as AbstractControl));
    }
  }

  registerOnChange(fn: (value: T[]) => void) {
    super.registerOnChange(fn);

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.onChanged?.(this.viewToModelParser?.(value));
      this.onTouched?.();
    });
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  validate(): ValidationErrors | null {
    return this.form.valid ? null : { invalid: true };
  }
}
