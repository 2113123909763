import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { IMetadataFiltering, QueryOperation } from '@erp/shared';

import { BaseTableFilter } from '../../abstracts';

const MAX_TEXT_LENGTH = 100;
@Component({
  selector: 'erp-table-string-filter',
  templateUrl: './table-string-filter.component.html',
  styleUrls: ['./table-string-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPTableStringFilterComponent extends BaseTableFilter implements OnInit {
  readonly form = new UntypedFormGroup({
    contains: new UntypedFormControl(null, [Validators.maxLength(MAX_TEXT_LENGTH)])
  });

  constructor(
    readonly dialogRef: MatDialogRef<ERPTableStringFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { trigger: HTMLElement; filter: IMetadataFiltering; operation: QueryOperation },
    readonly dialogElementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() {
    this.form.patchValue({
      contains: this.data?.filter?.match1
    });
    this.setDialogPosition();
  }

  onApply() {
    if (this.form.invalid) {
      return;
    }

    const { contains } = this.form.value;
    if (contains) {
      const filtering: IMetadataFiltering = {
        by: this.data.filter?.by,
        match1: contains,
        op: this.data?.operation || 'contains'
      };

      return this.dialogRef.close(filtering);
    }
    this.dialogRef.close(null);
  }
}
