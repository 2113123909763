import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';

@Injectable()
export class FixedSizeTableVirtualScrollStrategy implements VirtualScrollStrategy {
  private rowHeight!: number;
  private headerHeight!: number;
  private bufferMultiplier!: number;
  private readonly indexChange = new Subject<number>();

  viewport: CdkVirtualScrollViewport;

  renderedRangeStream = new Subject<ListRange>();
  infiniteScroll = false;
  bottomScrollObservable = new Subject();

  scrolledIndexChange = this.indexChange.pipe(distinctUntilChanged());

  get dataLength(): number {
    return this._dataLength;
  }

  set dataLength(value: number) {
    this._dataLength = value;
    this.onDataLengthChanged();
  }

  private _dataLength = 0;

  attach(viewport: CdkVirtualScrollViewport): void {
    this.viewport = viewport;
    this.viewport.renderedRangeStream.subscribe(this.renderedRangeStream);
    this.onDataLengthChanged();
    this.updateContent();
    if (this.infiniteScroll) {
      this.handleBottomScroll();
    }
  }

  detach(): void {}

  onContentScrolled(): void {
    this.updateContent();
  }

  onDataLengthChanged(): void {
    if (this.viewport) {
      this.viewport.setTotalContentSize(this.dataLength * this.rowHeight + this.headerHeight);
      if (!this.infiniteScroll) {
        this.viewport.scrollToOffset(0);
      }
      this.updateContent();
    }
  }

  onContentRendered(): void {}

  onRenderedOffsetChanged(): void {}

  scrollToIndex(index: number, behavior: ScrollBehavior): void {}

  setConfig({
    rowHeight,
    headerHeight,
    bufferMultiplier
  }: {
    rowHeight: number;
    headerHeight: number;
    bufferMultiplier: number;
  }) {
    this.rowHeight = rowHeight;
    this.headerHeight = headerHeight;
    this.bufferMultiplier = bufferMultiplier;

    this.updateContent();
  }

  updateContent() {
    if (!this.viewport) {
      return;
    }
    const scrollOffset = this.viewport.measureScrollOffset();
    const amount = this.getAmount();
    const offset = Math.max(scrollOffset - this.headerHeight, 0);
    const buffer = Math.ceil(amount * this.bufferMultiplier);

    const skip = Math.round(offset / this.rowHeight);
    const index = Math.max(0, skip);
    const start = Math.max(0, index - buffer);
    const end = Math.min(this.dataLength, index + amount + buffer);
    const renderedOffset = start * this.rowHeight;

    const offsetString = `-${renderedOffset}px`;
    this.viewport.elementRef.nativeElement.querySelectorAll('th').forEach(el => {
      el.style.top = offsetString;
    });

    this.viewport.setRenderedContentOffset(renderedOffset);
    this.viewport.setRenderedRange({ start, end });
    this.indexChange.next(index);
  }

  private getAmount() {
    return Math.ceil(this.viewport.getViewportSize() / this.rowHeight);
  }

  handleBottomScroll() {
    this.viewport.scrolledIndexChange.subscribe(index => {
      const end = this.viewport.getRenderedRange().end;
      const amount = this.getAmount();

      if (end - index <= amount) {
        this.bottomScrollObservable.next(null);
      }
    });
  }
}
