import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';

import { BaseHttpService } from '../abstracts';

@Injectable({
  providedIn: 'root'
})
export class ERPDowntimeDictionaryService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('dictionaries');
  }

  getDowntimeReasons(serviceTypeId: number): Observable<ISelectOption[]> {
    return this.get<ISelectOption[]>({ serviceTypeId }, 'downtimeReasons');
  }

  getProductionTimeReasons(serviceTypeId: number): Observable<ISelectOption[]> {
    return this.get<ISelectOption[]>({ serviceTypeId }, 'productionTimeReasons');
  }

  getDowntimeCategories(reasonId: number): Observable<ISelectOption[]> {
    return this.get<ISelectOption[]>({ reasonId }, 'downtimeCategories');
  }
}
