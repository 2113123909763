import { Observable, identity } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import {
  AutoCleanupFeature,
  ERPAuthService,
  ERPEmitService,
  Features,
  IDestroyable,
  IERPAccountInfo
} from '@erp/shared';

import { ERPTabletCheckInDialogComponent } from '../../../dialogs/tablet-check-in/components/check-in-dialog';
import { ERPConfirmCheckOutComponent } from '../../../dialogs/tablet-check-in/components/confirm-check-out';

@Component({
  selector: 'erp-sidenav-footer',
  templateUrl: './sidenav-footer.component.html',
  styleUrls: ['./sidenav-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPSidenavFooterComponent implements OnInit, IDestroyable {
  readonly destroyed$: Observable<unknown>;

  @Input() sidenavOpened: boolean;
  @Input() isTabletModule: boolean;

  userName: string;
  sessionStart: Date;

  constructor(
    private readonly authService: ERPAuthService,
    private readonly dialogService: MatDialog,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly emitService: ERPEmitService
  ) {}

  ngOnInit(): void {
    this.getUserFullName();
    this.authService.session$.subscribe(session => {
      this.sessionStart = session?.startDate;
      this.changeDetector.markForCheck();
    });
  }

  onClearSession() {
    if (this.isTabletModule) {
      this.emitService.emit('tablet:check-out');
    }

    this.dialogService
      .open(ERPConfirmCheckOutComponent)
      .afterClosed()
      .pipe(filter(identity))
      .subscribe(() => this.authService.clearSession());
  }

  onCheckIn() {
    if (this.isTabletModule) {
      this.emitService.emit('tablet:check-in');
    }

    this.dialogService
      .open(ERPTabletCheckInDialogComponent, {
        data: { userId: this.authService.getCustomUserID() }
      })
      .afterClosed()
      .pipe(filter(identity))
      .subscribe(session => {
        this.authService.session = session;
      });
  }

  onNavigate(): void {
    if (this.isTabletModule) {
      this.emitService.emit('tablet:sidebar-navigation');
    }
  }

  getUserFullName(): void {
    this.authService.loadUserProfile().then((profile: IERPAccountInfo) => {
      this.userName = `${profile.idTokenClaims.given_name} ${profile.idTokenClaims.family_name}`;
    });
  }
}
