import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPLabelModule } from '../label';
import { ERPSelectModule } from '../select';

import { ERPPaginatorComponent } from './paginator.component';

@NgModule({
  declarations: [ERPPaginatorComponent],
  imports: [CommonModule, ERPSelectModule, ERPLabelModule],
  exports: [ERPPaginatorComponent]
})
export class ERPPaginatorModule {}
