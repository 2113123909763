import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
  TrackByFunction
} from '@angular/core';

import { ERPFiltersUtil, IMetadataFiltering } from '@erp/shared';

import { ISelectOption } from '../../../select';

@Component({
  selector: 'erp-filtered-by',
  templateUrl: './filtered-by.component.html',
  styleUrls: ['./filtered-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPFilteredByComponent {
  readonly maxShowedLength = 30;

  @Input()
  set filtering(data: IMetadataFiltering[] | null) {
    this.hiddenFilters = [];
    this.filters =
      data?.filter(el => {
        if (this.hideFiltersSet?.has(el.by)) {
          this.hiddenFilters.push(el);

          return false;
        }

        return true;
      }) ?? [];
  }

  constructor(@Inject(LOCALE_ID) readonly currentLocale: string) {}

  private hiddenFilters: IMetadataFiltering[] = [];
  filters: IMetadataFiltering[] = [];

  @Input() hideFiltersSet: Set<string>;

  @Input() aliasesMap: Map<string, ISelectOption<number | boolean>[]> = new Map();

  @Output() readonly filterChange = new EventEmitter<IMetadataFiltering[]>();

  readonly trackByFn: TrackByFunction<IMetadataFiltering> = (_index: number, filter: IMetadataFiltering) => filter.by;

  onRemove(index: number) {
    this.filters.splice(index, 1);
    this.filterChange.emit([...this.filters, ...this.hiddenFilters]);
  }

  getDisplayName(filter: IMetadataFiltering) {
    if (this.aliasesMap.has(filter.by)) {
      return this.aliasesMap.get(filter.by)?.find(el => el.id === filter.match1)?.value as string;
    }

    if (
      ERPFiltersUtil.isContainsFilter(filter) ||
      ERPFiltersUtil.isEqualFilter(filter) ||
      ERPFiltersUtil.isInFilter(filter)
    ) {
      return this.getTrimmedValue(filter.match1 as string);
    } else {
      let match1 = filter.match1;
      let match2 = filter.match2;
      if (match1 instanceof Date || this.isIsoDate(match1 as string)) {
        match1 = formatDate(match1 as string, 'MM/dd/yyyy', this.currentLocale, 'UTC');
      }
      if (match2 instanceof Date || this.isIsoDate(match2 as string)) {
        match2 = formatDate(match2 as string, 'MM/dd/yyyy', this.currentLocale, 'UTC');
      }

      switch (filter.op) {
        case 'between':
          return `${match1} - ${match2}`;
        case 'gte':
          return `${match1} - ∞`;
        case 'lte':
          return `∞ - ${match1}`;
      }
    }
  }

  private isIsoDate(str: string) {
    return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z/.test(str);
  }

  onRemoveAll() {
    this.filters = [];
    this.filterChange.emit([...this.filters, ...this.hiddenFilters]);
  }

  private getTrimmedValue(val: string | number) {
    if (val?.toString().length < this.maxShowedLength) {
      return val;
    } else {
      const trail = '...';
      const cutLength = this.maxShowedLength - trail.length;

      return val.toString().substring(0, cutLength) + trail;
    }
  }
}
