import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { ERPPDPRolesService, getResourcesIDs, getResourcesPrefix } from '@erp/shared';

@Injectable({
  providedIn: 'root'
})
export class ERPSideMenuInGuard {
  readonly resourcesId = 'side-menu';
  readonly resources = getResourcesIDs(this.resourcesId);
  readonly prefix = getResourcesPrefix(this.resourcesId);

  constructor(
    private router: Router,
    readonly pdpService: ERPPDPRolesService,
    readonly pdpRolesService: ERPPDPRolesService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const resource = route.data?.resource;

    return this.pdpRolesService.getRoles(this.resources, this.prefix).pipe(
      map(roles => {
        if (roles && roles[resource]?.allowed) {
          return true;
        } else {
          this.router.navigate(['no-access']);

          return false;
        }
      })
    );
  }
}
