import { AbstractControl, ValidatorFn } from '@angular/forms';

import { ERPDateUtil } from '../utils';

export function DateTimeRangeValidator(
  startDateField: string,
  startTimeField: string,
  endDateField: string,
  endTimeField: string
): ValidatorFn {
  return (control: AbstractControl) => {
    const startDateTime = getDateTime(control, startDateField, startTimeField);
    const endDateTime = getDateTime(control, endDateField, endTimeField);

    const valid = !startDateTime || !endDateTime || startDateTime <= endDateTime;

    const startDateFieldCtrl = control.get(startDateField);
    const startTimeFieldCtrl = control.get(startTimeField);
    const endDateFieldCtrl = control.get(endDateField);
    const endTimeFieldCtrl = control.get(endTimeField);

    if (!valid) {
      startDateFieldCtrl?.setErrors({ dateRange: true });
      startTimeFieldCtrl?.setErrors({ dateRange: true });
      endDateFieldCtrl?.setErrors({ dateRange: true });
      endTimeFieldCtrl?.setErrors({ dateRange: true });
    } else {
      removeFormControlError(startDateFieldCtrl as AbstractControl, 'dateRange');
      removeFormControlError(startTimeFieldCtrl as AbstractControl, 'dateRange');
      removeFormControlError(endDateFieldCtrl as AbstractControl, 'dateRange');
      removeFormControlError(endTimeFieldCtrl as AbstractControl, 'dateRange');
    }

    return valid ? null : { notValid: true };
  };
}

function getDateTime(control: AbstractControl, dateFieldName: string, timeFieldName: string) {
  const date = control.get(dateFieldName)?.value;
  const time = control.get(timeFieldName)?.value;

  if (!time) {
    return null;
  }

  return ERPDateUtil.combineDateAndTime(date, time);
}

function removeFormControlError(control: AbstractControl, errorName: string) {
  if (control?.errors && control?.errors[errorName]) {
    delete control.errors[errorName];
    if (Object.keys(control.errors).length === 0) {
      control.setErrors(null);
    }
  }
}
