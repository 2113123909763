<form [formGroup]="form" (ngSubmit)="onApply()" autocomplete="off">
  <erp-label class="margin-bottom-2" i18n="@@common.table.filters.range">
    Range:
  </erp-label>
  <div class="grid grid-gap-t margin-bottom-3">
    <div class="col-6">
      <erp-number
        i18n-placeholder="@@common.table.filters.range.from.placeholder"
        placeholder="From"
        [type]="type"
        formControlName="from"
      ></erp-number>
    </div>
    <div class="col-6">
      <erp-number
        i18n-placeholder="@@common.table.filters.range.to.placeholder"
        placeholder="To"
        [type]="type"
        formControlName="to"
      ></erp-number>
    </div>
  </div>
  <mat-error
    class="mat-caption"
    *ngIf="
      form.hasError('range') &&
      !form.get('from')?.hasError('safeInteger') &&
      !form.get('to')?.hasError('safeInteger') &&
      !form.get('from')?.hasError('safeDecimal') &&
      !form.get('to')?.hasError('safeDecimal')
    "
    i18n="@@common.form.errors.range"
  >
    'To' value must be greater than 'From' value
  </mat-error>
  <div class="flex justify-content-flex-end margin-top-2">
    <erp-button color="primary" variant="link" type="reset" i18n="@@common.buttons.clear">
      Clear
    </erp-button>
    <erp-button medium color="accent" type="submit" class="margin-left-4" i18n="@@common.buttons.apply">
      Apply
    </erp-button>
  </div>
</form>
