<mat-form-field appearance="outline" [@.disabled]="true">
  <input
    autocomplete="off"
    [formControl]="control"
    [erpFocus]="isFocused"
    type="text"
    matInput
    i18n-placeholder="@@common.form.time.placeholder"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    [mask]="mask"
    [dropSpecialCharacters]="false"
  />

  <erp-icon
    *ngIf="!hoursMode && !minsMode"
    color="primary"
    icon="action:time"
    class="cursor-pointer"
    matSuffix
    [color]="control.disabled ? 'light-gray' : 'primary'"
    (click)="onSetCurrentTime()"
  ></erp-icon>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>
  <mat-error *ngIf="control.hasError('required')" i18n="@@common.form.errors.required">
    This field is required
  </mat-error>
  <mat-error *ngIf="control.hasError('time')" i18n="@@common.form.errors.time">
    Invalid time format
  </mat-error>
  <mat-error *ngIf="control.hasError('timeRange')" i18n="@@common.form.errors.time-range">
    Time range is invalid
  </mat-error>
  <mat-error *ngIf="control.hasError('hours')" i18n="@@common.form.errors.hours">
    Invalid hour format
  </mat-error>
  <mat-error *ngIf="control.hasError('maxTime')" i18n="@@common.form.errors.allowed-range">
    The time is outside the allowed range
  </mat-error>
  <mat-error *ngIf="control.hasError('minTime')" i18n="@@common.form.errors.allowed-range">
    The time is outside the allowed range
  </mat-error>
</mat-form-field>
