import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import {
  IMatchedCustomerWithAvailableDates,
  IUnloadingAuthAvailableInboundDelivery,
  IUnloadingAuthAvailablePurchaseOrder
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPUnloadingAuthSharedService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('UnloadingAuth');
  }
  getCustomerWithAvailableDates(params: object) {
    return this.get<ICollectionResponse<IMatchedCustomerWithAvailableDates>>(params, 'availableDates');
  }

  getAvailablePurchaseOrderList(params: object): Observable<ICollectionResponse<IUnloadingAuthAvailablePurchaseOrder>> {
    return this.get<ICollectionResponse<IUnloadingAuthAvailablePurchaseOrder>>(params, 'available/purchaseOrders');
  }

  getAvailableInboundDeliveriesList(
    params: object
  ): Observable<ICollectionResponse<IUnloadingAuthAvailableInboundDelivery>> {
    return this.get<ICollectionResponse<IUnloadingAuthAvailableInboundDelivery>>(params, 'available/inboundDeliveries');
  }
}
