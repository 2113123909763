export enum EInventoryStatuses {
  AVAILABLE = 1,
  RECEIVED = 2,
  QA_HOLD = 3,
  IN_TRANSIT = 4,
  HEAT_TREAT = 5,
  HYDRO = 6,
  INSPECTION = 7,
  THREAD = 8,
  BENT = 9,
  CUTOFF = 10,
  RQ_RT = 11,
  RQ_T = 12,
  RT = 13,
  REJECT = 14,
  CLOSED = 15,
  CANCELLED = 16,
  PRIME = 17,
  FTZ = 48
}
