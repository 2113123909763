import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { EInventoryStatuses } from '../enums';
import { ERPSettingsService } from '../services';
import { IdValue } from '../types';

@Injectable({ providedIn: 'root' })
export class ERPFTZResolver {
  constructor(readonly settingsService: ERPSettingsService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.settingsService.getFTZLocation().pipe(catchError(() => of(null)));
  }
}
