import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ERPDatePickerModule } from '../date-picker';
import { ERPTimePickerModule } from '../time-picker';

import { ERPDateTimePickerComponent } from './components';

@NgModule({
  declarations: [ERPDateTimePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ERPDatePickerModule,
    ERPTimePickerModule
  ],
  exports: [ERPDateTimePickerComponent]
})
export class ERPDateTimePickerModule {}
