import { Type, ɵComponentType, ɵDirectiveType } from '@angular/core';

import { ComponentFeatures, IComponentFeature } from './component-features';
import { DirectiveFeatures, IDirectiveFeature } from './directive-features';

type IFeature = IDirectiveFeature | IComponentFeature;

function FeaturesDecorator(features: IFeature[]) {
  return <T>(componentType: Type<T>) => {
    const def = componentType as ɵDirectiveType<T> & ɵComponentType<T>;

    if (def.ɵcmp) {
      return ComponentFeatures(def, features, def.ɵfac as () => T);
    }
    if (def.ɵdir) {
      return DirectiveFeatures(def, features, def.ɵfac as () => T);
    }

    throw new Error('Either Component or Directive are supported as a target.');
  };
}

export { FeaturesDecorator as Features };
