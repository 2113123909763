import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { IHttpEntityFactory } from '../abstracts/http';
import { IWorkCenterDowntime, IWorkCenterDowntimeResponse } from '../interfaces';
import { ERPDateUtil } from '../utils';

@Injectable({ providedIn: 'any' })
export class ERPWorkCenterDowntimeFactory
  implements IHttpEntityFactory<IWorkCenterDowntime, IWorkCenterDowntimeResponse> {
  fromResponse(item: IWorkCenterDowntimeResponse): IWorkCenterDowntime {
    const startDate = moment(item.startDate);
    const endDate = item.endDate ? moment(item.endDate) : null;

    return {
      id: item.id,
      workCenterId: item.workCenterId,
      processingOrderId: item.processingOrderId,
      processingOrderNumber: item.processingOrderNumber,
      reasonId: item.reasonId,
      reasonName: item.reasonName,
      categoryId: item.subReasonId,
      categoryName: item.subReasonName,
      startDate: startDate.toDate(),
      startTime: startDate.format('HH:mm'),
      failureDetails: item.failureDetail,
      endDate: endDate?.toDate() ?? null,
      endTime: endDate?.format('HH:mm') ?? null,
      resumeDetails: item.resumeDetails,
      rowVersion: item.rowVersion,
      processingServiceId: item?.processingServiceId
    };
  }

  toRequest(item: IWorkCenterDowntime): IWorkCenterDowntimeResponse {
    return {
      id: item.id,
      workCenterId: item.workCenterId,
      processingOrderId: item.processingOrderId,
      processingOrderNumber: item.processingOrderNumber,
      processingServiceId: item?.processingServiceId,
      reasonId: item.reasonId,
      reasonName: item.reasonName,
      subReasonId: item.categoryId,
      subReasonName: item.categoryName,
      startDate: ERPDateUtil.combineDateAndTime(item.startDate, item.startTime) ?? new Date(),
      failureDetail: item.failureDetails,
      endDate: ERPDateUtil.combineDateAndTime(item.endDate, item.endTime),
      resumeDetails: item.resumeDetails,
      rowVersion: item.rowVersion
    };
  }
}
