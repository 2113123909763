import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';

import { BaseHttpService } from '../abstracts';
import { NcpCategory, NcpType } from '../enums';
import { INcpDefect, INcpDispositions } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPNcpDictionaryService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('dictionaries');
  }

  getNcpDefects(ncpCategory: NcpCategory, ncpType: NcpType): Observable<INcpDefect[]> {
    return this.get<INcpDefect[]>({ ncpCategoryId: ncpCategory, ncpTypeId: ncpType }, 'defects');
  }

  getNcpDispositions(): Observable<ISelectOption[]> {
    return this.get<ISelectOption[]>(undefined, 'dispositions');
  }

  getDefaultDispositions(defectId: number | null, ncpCategory: NcpCategory): Observable<INcpDispositions[]> {
    return this.get<INcpDispositions[]>({ defectId, categoryId: ncpCategory }, 'defaultDisposition');
  }
}
