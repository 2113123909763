import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ERPInfinityScrollService {
  canLoadMore(total: number, perPage: number): boolean {
    return total % perPage === 0;
  }

  findCurrentPage(total: number, perPage: number): number {
    return Math.ceil((total + 1) / perPage);
  }
}
