import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

import { ERPSharedModule } from '@erp/shared';

import { ERPSlideToggleComponent } from './components';

@NgModule({
  declarations: [ERPSlideToggleComponent],
  imports: [
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ERPSharedModule,
    MatSlideToggleModule
  ],
  exports: [ReactiveFormsModule, ERPSlideToggleComponent]
})
export class ERPSlideToggleModule {}
