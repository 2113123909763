<form [formGroup]="form" (ngSubmit)="onApply()" autocomplete="off" class="table-select-filter">
  <erp-label class="margin-bottom-2" i18n="@@common.table.filters.select">
    Select option:
  </erp-label>
  <mat-radio-group
    *ngIf="options?.length === radioModeLength"
    class="flex flex-column margin-bottom-4"
    formControlName="select"
  >
    <mat-radio-button class="margin-bottom-1" *ngFor="let option of options" [value]="option.id">
      {{ option.value }}
    </mat-radio-button>
  </mat-radio-group>

  <erp-select
    [labelFn]="labelFn"
    [displayFn]="displayFn"
    *ngIf="options?.length !== radioModeLength"
    class="flex flex-column"
    formControlName="select"
    [options]="options"
    placeholder="Select"
    i18n-placeholder="@@common.select.placeholder"
  >
  </erp-select>

  <div class="flex justify-content-flex-end">
    <erp-button color="primary" variant="link" type="reset" i18n="@@common.buttons.clear">
      Clear
    </erp-button>
    <erp-button medium color="accent" type="submit" class="margin-left-4" i18n="@@common.buttons.apply">
      Apply
    </erp-button>
  </div>
</form>
