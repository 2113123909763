import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';

@Injectable({
  providedIn: 'root'
})
export class ERPUomService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('unitsofmeasure');
  }

  convertSkuMeasurement(params: {
    skuId: string;
    quantity: number;
    uomFromId: number;
    uomToId: number;
    manufacturerId?: number | number[];
    materialId?: number;
  }): Observable<number> {
    return this.get<number>(params, 'converters/countables/pipes');
  }
}
