import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ERPPreviousModeService {
  private readonly previousMode = new BehaviorSubject<string | null>(null);
  readonly previousMode$: Observable<string | null> = this.previousMode.asObservable();

  setPreviousMode(previousMode: string | null) {
    this.previousMode.next(previousMode);
  }
}
