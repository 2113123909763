import { identity } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ERPExpiredSessionDialogComponent, ERPTabletCheckInDialogComponent } from '@erp/components';
import { ERPAuthService } from '@erp/shared';

@Injectable({
  providedIn: 'root'
})
export class ERPCheckInService {
  constructor(private dialogService: MatDialog, readonly authService: ERPAuthService) {}

  showExpiredSessionPopup() {
    this.dialogService
      .open(ERPExpiredSessionDialogComponent)
      .afterClosed()
      .pipe(filter(identity))
      .subscribe(() => this.openCheckInDialog());
  }

  openCheckInDialog() {
    this.dialogService
      .open(ERPTabletCheckInDialogComponent, {
        data: { userId: this.authService.getCustomUserID() }
      })
      .afterClosed()
      .pipe(filter(identity))
      .subscribe(session => (this.authService.session = session));
  }

  clearSession() {
    this.authService.clearSession();
  }
}
