<mat-accordion *ngIf="isShow">
  <mat-expansion-panel
    #matExpansionPanel
    (opened)="onToggle(true)"
    (closed)="onToggle(false)"
    [expanded]="expanded"
    [ngClass]="{ 'padding-bottom-2': expanded }"
  >
    <!-- Panel header -->
    <mat-expansion-panel-header
      [ngClass]="{ 'flex justify-content-center align-items-center': true, 'has-active': hasActive }"
    >
      <mat-panel-title>
        <strong class="header mat-caption">{{ header }}</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- Panel content -->
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
