import { IQueryFiltering } from '../abstracts/http';

export class ERPFiltersUtil {
  static isBetweenFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'between';
  }

  static isGreaterThanOrEqualFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'gte';
  }

  static isLaterThanOrEqualFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'lte';
  }

  static isLaterThanFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'lt';
  }

  static isGreaterThanFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'gt';
  }

  static isContainsFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'contains';
  }

  static isEqualFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'eq';
  }

  static isNotEqualFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'neq';
  }

  static isInFilter(filter: IQueryFiltering): boolean {
    return filter?.op === 'in';
  }
}
