import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts/http';
import { IMIDHistory } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPTransactionsService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('transactions');
  }

  getList(params: object): Observable<ICollectionResponse<IMIDHistory>> {
    return this.get<ICollectionResponse<IMIDHistory>>(params);
  }
}
