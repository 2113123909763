import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';

@Injectable({
  providedIn: 'root'
})
export class ERPWeightService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('products/pipes');
  }

  getWeight(searchValue: number): Observable<string[]> {
    return this.get<number[]>({ searchValue }, 'weightPerFoot').pipe(
      map(elements => elements.map(element => element.toFixed(2)))
    );
  }
}
