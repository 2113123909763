<erp-notification-header (markAllAsRead)="markAllAsRead()"></erp-notification-header>
<div class="container">
  <erp-notification-filter
    [isTablet]="isTabletModule"
    [filtering]="query?.filtering"
    (filteringChange)="onFilteringChanged($event)"
  ></erp-notification-filter>
  <erp-notification-cards [isTablet]="isTabletModule" [data]="data"></erp-notification-cards>
  <div class="margin-top-4 flex justify-content-flex-start">
    <erp-paginator
      [total]="query?.paging?.total"
      [page]="query?.paging?.page"
      [pageSize]="query?.paging?.perPage"
      (pageChange)="onPageChanged($event)"
    ></erp-paginator>
  </div>
</div>
