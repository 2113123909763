<h1 class="dialog-header" mat-dialog-title>
  <ng-container i18n="@@common.dialogs.discard-changes.header">You have unsaved changes.</ng-container>
  <ng-content select="[header]"></ng-content>
</h1>
<erp-button color="primary" variant="icon" class="dialog-close" [mat-dialog-close]="false">
  <erp-icon icon="action:cross"></erp-icon>
</erp-button>

<section class="dialog-content" mat-dialog-content>
  <ng-container i18n="@@common.dialogs.discard-changes.message">Discard these changes?</ng-container>
  <ng-content select="[header]"></ng-content>
</section>
<section class="dialog-actions flex justify-content-flex-end" mat-dialog-actions>
  <erp-button class="margin-right-4" color="primary" variant="link" (click)="onCancel()">
    <ng-container i18n="@@common.dialogs.discard-changes.buttons.keep-changes">
      Keep Changes
    </ng-container>
    <ng-content select="[cancel]"></ng-content>
  </erp-button>
  <erp-button type="button" class="dialog-action-confirm" (click)="onConfirm()">
    <ng-container i18n="@@common.dialogs.discard-changes.buttons.discard-changes">
      Discard Changes
    </ng-container>
    <ng-content select="[confirm]"></ng-content>
  </erp-button>
</section>
