import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ISelectOption } from '@erp/components';
import { IProductionProcessingOrderList } from '@erp/production/processing-order/interfaces';
import { ERPProductionProcessingOrderService } from '@erp/production/processing-order/services';
import {
  ERPInfinityScrollService,
  ERPStockService,
  ERP_HUGE_TABLE_PAGING,
  ICollectionResponse,
  IDestroyable,
  IMetadataFiltering,
  IMetadataPaging,
  IProductSearch,
  IQueryFiltering,
  IStockMaterialQuantityChunk,
  IStockMaterialQuantityReference,
  IdName,
  IdValue,
  TableQueryBuilder
} from '@erp/shared';

@Injectable()
export class ERPStockLookupService implements IDestroyable, OnDestroy {
  destroyed$ = new Subject<void>();

  cachedHeatStock: ICollectionResponse<IStockMaterialQuantityChunk>;

  private materials: [] = [];
  private skuList: [] = [];
  private fieldsForFilters: {} = {};

  private form: UntypedFormGroup;
  private inventoryStatuses$: Observable<ISelectOption[]>;
  private filterToProcessingOrder: boolean;

  constructor(
    @Inject(ERP_HUGE_TABLE_PAGING) readonly paging: IMetadataPaging,
    private readonly processingOrderService: ERPProductionProcessingOrderService,
    private readonly infinityScrollService: ERPInfinityScrollService,
    private readonly stockService: ERPStockService
  ) {}

  initialize(form: UntypedFormGroup, inventoryStatuses$: Observable<ISelectOption[]>, includeProcessingOrder: boolean) {
    this.form = form;
    this.inventoryStatuses$ = inventoryStatuses$;
    this.filterToProcessingOrder = includeProcessingOrder;

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.setDefaultFilters();
    });
    this.setDefaultFilters();
  }

  getCurrentStock(): Observable<IStockMaterialQuantityChunk[]> {
    this.setDefaultFilters();
    const filtersArr: IMetadataFiltering[] = this.onCreateFilters(this.fieldsForFilters, 'heatNumber');
    filtersArr.push({ by: 'availableQty', match1: 0, op: 'gt' });

    const query = new TableQueryBuilder({
      paging: {
        ...this.paging
      },
      filtering: [...filtersArr]
    }).serialize();

    return this.getStockChunks(query).pipe(
      map(res => {
        this.populateStockMaterial(res.data);

        return res.data.filter(
          (thing, index, self) => index === self.findIndex(t => t.heatNumber === thing.heatNumber)
        );
      })
    );
  }

  onSearchProcessingOrder(processingOrder: IProductionProcessingOrderList) {
    this.form.patchValue({
      processingOrder: processingOrder ? { id: processingOrder?.id, name: processingOrder?.documentNumber } : null
    });

    if (!this.filterToProcessingOrder) {
      return;
    }

    if (processingOrder?.skuList?.length) {
      this.skuList.push(...processingOrder?.skuList);
    } else {
      this.skuList = [];
    }
    if (processingOrder?.materials?.length) {
      this.materials.push(...processingOrder?.materials);
    } else {
      this.materials = [];
    }
  }

  onSearchMaterialId(material: IStockMaterialQuantityReference) {
    if (!material) {
      this.form.patchValue({
        material: null,
        systemQty: null,
        millName: null,
        millId: null
      });
    } else {
      this.form.patchValue({
        material: {
          id: material?.materialId,
          name: material?.materialNumber
        },
        millName: material?.millName,
        millId: material?.millId
      });
    }
  }

  onSearchSkuDescription(product: IStockMaterialQuantityReference | IProductSearch) {
    this.form.patchValue({
      skuId: (product as IStockMaterialQuantityReference)?.skuId || (product as IProductSearch)?.id,
      skuDescription: product?.skuDescription,
      systemQty: (product as IStockMaterialQuantityReference)?.availableQty || null
    });
  }

  onSearchLocation(rack: IdName | IStockMaterialQuantityReference) {
    if (!rack) {
      this.form.patchValue({
        location: null
      });
    } else {
      this.form.patchValue({
        location: {
          id: (rack as IdName)?.id || (rack as IStockMaterialQuantityReference)?.rackId || null,
          name: (rack as IdName)?.name || (rack as IStockMaterialQuantityReference)?.rackName || null
        }
      });
    }
  }

  onSearchStatus(material: IStockMaterialQuantityReference) {
    if (!material) {
      this.form.patchValue({
        inventoryStatuses: null,
        systemQty: null
      });
    } else {
      this.form.patchValue({
        inventoryStatus: { id: material?.inventoryStatusId, value: material?.inventoryStatusName } || null
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  readonly proOptionsFn = (match1: string) => {
    const query = this.getProQuery(match1, this.filterToProcessingOrder);

    return this.processingOrderService.getProcessingOrders({ query }).pipe(map(({ data }) => data));
  };

  readonly proLabelFn = (val: { documentNumber: string } | string) =>
    val instanceof Object ? val.documentNumber : val;
  readonly proDisplayFn = (val: { name: string } | string) => (val instanceof Object ? val.name : val);
  readonly proValueFn = (value: IdValue) => (value instanceof Object ? value : null);

  readonly skuDescriptionOptionFn = (searchVal: string, total: number) => {
    const page = this.infinityScrollService.findCurrentPage(total, this.paging.perPage as number);

    const filtersArr: IMetadataFiltering[] = this.onCreateFilters(this.fieldsForFilters, 'skuDescription');
    const processingOrderSkuFilter: IMetadataFiltering[] = this.onCreateMaterialFilters(this.skuList, 'skuId');

    return this.getSkuDescriptionFiltersOptionFn(
      searchVal,
      'skuDescription',
      page,
      filtersArr,
      processingOrderSkuFilter
    );
  };

  private getProQuery(match1: string, useFilters: boolean) {
    const filtersArr: IMetadataFiltering[] = [];
    if (useFilters) {
      const skuList = this.form.get('sku')?.value;
      const materials = this.form.get('material')?.value?.id;

      const fieldsForFilters = {
        materials,
        skuList
      };

      for (const [key, value] of Object.entries(fieldsForFilters)) {
        if (value !== null && value !== undefined) {
          filtersArr.push({ by: key, match1: value, op: 'contains' });
        }
      }
    }

    const filtering: IQueryFiltering[] = [
      {
        by: 'documentNumber',
        op: 'contains',
        match1
      },
      ...filtersArr
    ];

    const query = new TableQueryBuilder({})
      .withFiltering([...filtering])
      .withPaging(this.paging)
      .serialize();

    return query;
  }

  private getSkuDescriptionFiltersOptionFn(
    searchVal: string,
    by: string,
    page: number,
    filtersArr: IMetadataFiltering[],
    processingOrderSkuFilter: IMetadataFiltering[]
  ) {
    const query = new TableQueryBuilder({
      paging: {
        ...this.paging,
        page
      },
      filtering: [
        { by, match1: searchVal, op: 'contains' },
        { by: 'locationId', match1: this.form.get('site')?.value.id, op: 'eq' },
        ...filtersArr,
        ...processingOrderSkuFilter
      ]
    }).serialize();

    return this.getStockChunks(query).pipe(
      map(res => res.data.filter((thing, index, self) => index === self.findIndex(t => t.skuId === thing.skuId)))
    );
  }

  readonly skuDescriptionLabelFn = (val: { skuDescription: string }) => val?.skuDescription;
  readonly skuDescriptionDisplayFn = (val: { skuDescription: string }) =>
    val instanceof Object ? val.skuDescription : val;

  readonly materialIdOptionsFn = (searchVal: string, total: number) => {
    if (this.infinityScrollService.canLoadMore(total, this.paging.perPage as number)) {
      const page = this.infinityScrollService.findCurrentPage(total, this.paging.perPage as number);

      const filtersArr: IMetadataFiltering[] = this.onCreateFilters(this.fieldsForFilters, 'materialId');
      const processingOrderMaterialsFilter: IMetadataFiltering[] = this.onCreateMaterialFilters(
        this.materials,
        'materialId'
      );

      const siteId = this.form.get('site')?.value.id;
      const filtering: IQueryFiltering[] = [
        { by: 'materialNumber', match1: searchVal, op: 'contains' },
        { by: 'locationId', match1: siteId, op: 'eq' },
        ...filtersArr,
        ...processingOrderMaterialsFilter
      ];

      const query = new TableQueryBuilder({
        paging: {
          ...this.paging,
          page
        },
        filtering
      }).serialize();

      return this.getStockChunks(query).pipe(
        map(res => {
          this.populateStockMaterial(res.data, searchVal);

          return res.data.filter(
            (thing, index, self) => index === self.findIndex(t => t.materialId === thing.materialId)
          );
        })
      );
    }

    return of([]);
  };

  readonly materialIdLabelFn = (val: IStockMaterialQuantityReference) =>
    val instanceof Object ? val.materialNumber : val;

  readonly materialIdDisplayFn = (val: { name: string }) => (val instanceof Object ? val.name : val);

  locationOptionsFn = (match: string) => {
    const siteId = this.form.get('site')?.value.id;
    if (!siteId) {
      return of([]);
    }

    const filtersArr: IMetadataFiltering[] = this.onCreateFilters(this.fieldsForFilters, 'rackId');

    filtersArr.push({ by: 'availableQty', match1: 0, op: 'gt' });
    filtersArr.push({ by: 'locationId', match1: siteId, op: 'eq' });
    filtersArr.push({ by: 'rackName', match1: match, op: 'contains' });

    const query = new TableQueryBuilder({
      paging: {
        ...this.paging
      },
      filtering: [...filtersArr]
    }).serialize();

    return this.getStockChunks(query).pipe(
      map(res => {
        this.populateStockMaterial(res.data);

        return res.data
          .filter((thing, index, self) => index === self.findIndex(t => t.rackId === thing.rackId))
          .map(item => ({ id: item.rackId, name: item.rackName } as IdName));
      })
    );
  };

  readonly locationLabelFn = (rack: IdName | string | IStockMaterialQuantityReference) => {
    return rack instanceof Object
      ? (rack as IdName)?.name || (rack as IStockMaterialQuantityReference)?.rackName
      : rack;
  };
  readonly locationDisplayFn = (val: IdName | string | IStockMaterialQuantityReference) => {
    return val instanceof Object ? (val as IdName)?.name || (val as IStockMaterialQuantityReference)?.rackName : val;
  };

  statusOptionsFn = (searchVal: string) => {
    const filtersArr: IMetadataFiltering[] = this.onCreateFilters(this.fieldsForFilters, 'inventoryStatusId');

    const filtering: IQueryFiltering[] = [
      { by: 'inventoryStatusName', match1: searchVal, op: 'contains' },
      { by: 'availableQty', match1: 0, op: 'gt' },
      ...filtersArr
    ];

    const query = new TableQueryBuilder({
      paging: {
        ...this.paging
      },
      filtering
    }).serialize();

    if (filtersArr.length) {
      return this.getStockChunks(query).pipe(
        map(res =>
          res.data.filter(
            (thing, index, self) => index === self.findIndex(t => t.inventoryStatusId === thing.inventoryStatusId)
          )
        )
      );
    } else {
      return this.inventoryStatuses$.pipe(
        map(item =>
          item.map(s => {
            return { inventoryStatusId: s.id, inventoryStatusName: s.value };
          })
        )
      );
    }
  };

  readonly statusLabelFn = (val: IStockMaterialQuantityReference) =>
    val instanceof Object ? val.inventoryStatusName : val;
  readonly statusDisplayFn = (val: { value: string }) => (val instanceof Object ? val.value : val);

  readonly heatValueFn = (value: { heatNumber: string }) => (value instanceof Object ? value.heatNumber : null);
  readonly heatsDisplayFn = (value: { heatNumber: string }) => (value instanceof Object ? value.heatNumber : value);
  readonly heatsOptionsFn = (match1: string) => {
    const filtersArr: IMetadataFiltering[] = this.onCreateFilters(this.fieldsForFilters, 'heatNumber');

    filtersArr.push({ by: 'availableQty', match1: 0, op: 'gt' });

    if (match1) {
      filtersArr.push({ by: 'heatNumber', match1, op: 'contains' });
    }

    const query = new TableQueryBuilder({
      paging: {
        ...this.paging
      },
      filtering: [...filtersArr]
    }).serialize();

    return this.getStockChunks(query, !!match1).pipe(
      map(res => {
        this.populateStockMaterial(res.data);

        return res.data
          .filter((thing, index, self) => index === self.findIndex(t => t.heatNumber === thing.heatNumber))
          .filter(item =>
            match1.length ? item.heatNumber?.toLowerCase().includes(match1.toLowerCase()) : item.heatNumber
          );
      })
    );
  };

  private setDefaultFilters() {
    const formValues = this.form.getRawValue();
    const skuId = formValues?.skuId;
    const materialId = formValues.material?.id;
    const rackId = formValues.location?.id;
    const inventoryStatusId = formValues.inventoryStatus?.id;

    this.fieldsForFilters = {
      skuId,
      materialId,
      rackId,
      inventoryStatusId
    };
  }

  private onCreateFilters(filters: object, notAllowed?: string) {
    const filtersArr: IMetadataFiltering[] = [];
    for (const [key, value] of Object.entries(filters)) {
      if (value !== null && value !== undefined && key !== notAllowed) {
        filtersArr.push({ by: key, match1: value, op: 'eq' });
      }
    }

    return filtersArr;
  }

  private onCreateMaterialFilters(materials: [], by: string): IMetadataFiltering[] {
    const filtersArr: IMetadataFiltering[] = [];

    materials.forEach(material => {
      filtersArr.push({ by, match1: material, op: 'in' });
    });

    return filtersArr;
  }

  private populateStockMaterial(materials: IStockMaterialQuantityChunk[], match?: string) {
    if (materials.length === 1) {
      const material = materials[0];

      this.patchMaterial(material);

      return;
    }

    const materialWithEmptyHeat = materials.find(m => !m.heatNumber && !m.lotNumber);
    if (
      materials.filter(m => !m.heatNumber).length === 1 &&
      materialWithEmptyHeat &&
      materialWithEmptyHeat.materialNumber === match
    ) {
      this.patchMaterial(materialWithEmptyHeat);
    }
  }

  private patchMaterial(material: IStockMaterialQuantityChunk) {
    this.form.patchValue({
      material: {
        id: material?.materialId,
        name: material?.materialNumber
      },
      millId: material?.millId,
      millName: material?.millName,
      location: {
        id: material.rackId,
        name: material.rackName
      },
      skuDescription: material?.skuDescription,
      skuId: material?.skuId,
      inventoryStatus:
        {
          id: material?.inventoryStatusId,
          value: material?.inventoryStatusName
        } || null,
      systemQty: material.availableQty
    });

    if (this.filterToProcessingOrder) {
      this.proOptionsFn('').subscribe(res => {
        if (res.length === 1) {
          const poDocument = res[0];

          this.form.patchValue({
            processingOrder: { id: poDocument?.id, name: poDocument?.documentNumber }
          });
        }
      });
    }
  }

  private getStockChunks(query: string, heatFilterApplied?: boolean) {
    return this.stockService.getStockMaterialsQuantityChunk({ query }).pipe(
      map(res => {
        if (!heatFilterApplied) {
          this.cachedHeatStock = res;
        }

        return res;
      })
    );
  }
}
