export enum ProcessOrderStatus {
  New = 1,
  Scheduled = 2,
  InProgress = 3,
  Finished = 4,
  Ended = 5,
  Cancelled = 6,
  Paused = 7,
  qAApproved = 8
}
