import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { ERPToasterService } from '@erp/components';
import {
  AutoCleanupFeature,
  BaseFormComponent,
  ERPFormStateDispatcher,
  Features,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';
import { IRoundThread } from '@erp/tablet';

import { ERPProductionProcessingOrderService } from '../../../../../../../production/src/lib/modules/processing-order/services';

const MAX_LENGTH = 120;

@Component({
  selector: 'erp-tablet-check-in-dialog',
  templateUrl: './round-thread-dialog.component.html',
  styleUrls: ['./round-thread-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPTabletRoundThreadDialogComponent<T> extends BaseFormComponent<IRoundThread> implements OnInit {
  readonly destroyed$: Observable<unknown>;

  readonly form = new UntypedFormGroup({
    mrp: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    runOut: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    goNoGo: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    caliper: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    protractor: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    ring: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    lead: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    taper: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    height: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    profile: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    id: new UntypedFormControl(null),
    orderId: new UntypedFormControl(null),
    serviceId: new UntypedFormControl(null),
    measurementTypeId: new UntypedFormControl(1), // default option
    userId: new UntypedFormControl(null),
    userName: new UntypedFormControl(null),
    rowVersion: new UntypedFormControl(null),
    startDate: new UntypedFormControl(null),
    isOpen: new UntypedFormControl(null)
  });

  constructor(
    readonly formState: ERPFormStateDispatcher,
    readonly dialogRef: MatDialogRef<ERPTabletRoundThreadDialogComponent<IRoundThread>>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'create' | 'view' | 'edit'; roundThreadData: IRoundThread },
    readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    readonly processingOrderService: ERPProductionProcessingOrderService,
    readonly toasterService: ERPToasterService
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.roundThreadData) {
      this.form.patchValue(this.data.roundThreadData);
    }
    if (this.data.mode === 'view') {
      this.form.disable();
    }
    if (this.data.mode === 'create') {
      this.setOrderId();
    }
  }

  private setOrderId() {
    const url = this.router.url.split('/');
    const orderId = +url[url.findIndex(i => i === 'processing-order') + 1];
    const serviceId = +url[url.findIndex(i => i === 'service') + 1];

    this.form.patchValue({
      orderId,
      serviceId
    });
  }

  onSave() {
    if (this.data.mode === 'edit') {
      this.onUpdate();
    }
    if (this.data.mode === 'create') {
      this.onCreate();
    }
  }

  onCreate() {
    this.form.get('startDate')?.patchValue(new Date());

    const { valid, value } = this.form;

    if (!valid) {
      this.formState.onSubmit.notify();

      return;
    }

    this.processingOrderService.createThreadInspection(value).subscribe(
      res => {
        this.toasterService.success('success.created');
        this.dialogRef.close(res);
      },
      error => {
        const message = error.error?.error?.message || 'error.default';
        this.toasterService.error(message);
      }
    );
  }

  onUpdate() {
    this.form.get('startDate')?.patchValue(new Date());

    const { valid, value } = this.form;

    if (!valid) {
      this.formState.onSubmit.notify();

      return;
    }

    this.processingOrderService.updateThreadInspection(value).subscribe(
      res => {
        this.toasterService.success('success.updated');
        this.dialogRef.close(res);
      },
      error => {
        const message = error.error?.error?.message || 'error.default';
        this.toasterService.error(message);
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }
}
