<erp-toolbar class="sticky-header">
  <div class="list-header flex justify-content-space-between align-items-center">
    <span class="mat-headline margin-bottom-0" i18n="@@notifications.list.title">
      List of Notifications
    </span>
    <erp-button type="button" wide (click)="markAllAsRead.emit()">
      <ng-container i18n="@@notifications.list.mark-all-read">Mark All as Read</ng-container>
    </erp-button>
  </div>
</erp-toolbar>
