import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';

import { RedirectComponent, TabletRedirectComponent } from './components';

@NgModule({
  declarations: [RedirectComponent, TabletRedirectComponent],
  imports: [CommonModule, RouterModule, MatProgressSpinnerModule],
  exports: [RedirectComponent, TabletRedirectComponent]
})
export class ERPRedirectModule {}
