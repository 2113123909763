import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';

import { AutoCleanupFeature, ERPAuthService, Features } from '@erp/shared';

import { ERPNotificationEventsService, ERPNotificationService } from '../../services';

const MAX_NOTIFICATIONS_COUNT = 99;

@Component({
  selector: 'erp-notification-counter',
  templateUrl: './notification-counter.component.html',
  styleUrls: ['./notification-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPNotificationCounterComponent implements OnInit {
  destroyed$: Observable<void>;

  private userId: string;
  private _notificationsCount: number;

  get notificationsCount() {
    return this._notificationsCount > MAX_NOTIFICATIONS_COUNT ? '99+' : this._notificationsCount;
  }
  get isShowNotificationsCount(): boolean {
    return this._notificationsCount > 0;
  }

  constructor(
    private notificationService: ERPNotificationService,
    private authService: ERPAuthService,
    private changeDetector: ChangeDetectorRef,
    private eventsService: ERPNotificationEventsService
  ) {
    this.userId = authService.getCustomUserID();
  }

  ngOnInit(): void {
    this.notificationService
      .getUnreadCount(this.userId, {})
      .pipe(
        takeUntil(this.destroyed$),
        tap((count: number) => this.eventsService.updateUnreadCount(count))
      )
      .subscribe(count => this.updateCount(count));

    this.eventsService.countChangedEvent
      .asObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(count => this.updateCount(count));
  }

  private updateCount(count: number) {
    this._notificationsCount = count;
    this.changeDetector.detectChanges();
  }
}
