<erp-confirm
  i18n-header="@@common.dialogs.confirm-quantity-correct.header"
  header="Is the reported quantity correct?"
  i18n-message="@@common.dialogs.confirm-quantity-correct.message"
  message="The Total Processed QTY is greater than the Consumed QTY."
  i18n-cancel="@@common.dialogs.confirm-production-results.buttons.cancel"
  cancel="Cancel"
  i18n-confirm="@@common.dialogs.confirm-production-results.buttons.confirm"
  confirm="Confirm"
  color="warn"
></erp-confirm>
