import { ElementRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { IMetadataFiltering } from '@erp/shared';

export abstract class BaseTableFilter {
  abstract readonly dialogRef: MatDialogRef<unknown>;
  abstract readonly data: { trigger: HTMLElement; filter: IMetadataFiltering };
  abstract readonly dialogElementRef: ElementRef<HTMLElement>;

  setDialogPosition() {
    const alignerRect = this.data.trigger.getBoundingClientRect();
    const aligneeRect = this.dialogElementRef.nativeElement
      .closest('.erp-filter-dialog')
      ?.getBoundingClientRect() as DOMRect;
    const windowRect = document.documentElement.getBoundingClientRect();
    const alignTop = alignerRect.bottom;
    const isOverflowRight = alignerRect.left + aligneeRect.width > windowRect.right;
    const isOverflowLeft = alignerRect.right - aligneeRect.width < windowRect.left;
    const alignRight = windowRect.width - (windowRect.width - alignerRect.right) - aligneeRect.width;
    const alignLeft = alignerRect.left;

    switch (false) {
      case isOverflowRight:
        return this.dialogRef.updatePosition({
          top: `${Math.ceil(alignTop + 1)}px`,
          left: `${Math.ceil(alignLeft + 1)}px`
        });
      case isOverflowLeft:
        return this.dialogRef.updatePosition({
          top: `${Math.ceil(alignTop + 1)}px`,
          left: `${Math.ceil(alignRight + 1)}px`
        });
      default:
        this.dialogRef.updatePosition({
          top: `${Math.ceil(alignTop + 1)}px`,
          left: `${Math.ceil(alignLeft + 1)}px`,
          right: `${Math.ceil(alignRight + 1)}px`
        });
        this.dialogRef.updateSize(`${alignerRect.width}px`);
    }
  }
}
