import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { DictionaryService } from '../services';

@Injectable({ providedIn: 'root' })
export class ERPSizesResolver {
  constructor(readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.sizes.pipe(
      map(elements =>
        elements.map(element => ({
          ...element,
          value: `${Number(element?.value)?.toFixed(3)}`
        }))
      )
    );
  }
}
