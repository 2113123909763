import { EMPTY, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { IPDPRoles } from '../interfaces';
import { ERPPDPRolesService } from '../services';
import { getResourcesIDs, getResourcesPrefix } from '../utils';

@Injectable({ providedIn: 'root' })
export class ERPPDPRolesResolver {
  constructor(readonly pdpRolesService: ERPPDPRolesService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const { resourcesId } = route.data;
    const resources = getResourcesIDs(resourcesId);
    const prefix = getResourcesPrefix(resourcesId);

    if (!resources) {
      return EMPTY;
    }

    return this.pdpRolesService.getRoles(resources, prefix).pipe(catchError(() => of({})));
  }
}
