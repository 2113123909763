import { orderBy } from 'natural-orderby';

import { Injectable } from '@angular/core';

import { ICollectionQuery, IQueryFiltering, IQuerySorting } from '../abstracts';

export type QuerySelectorMap<T> = {
  [K in keyof T]?: <V extends T[K]>(value: V) => unknown;
};

@Injectable({
  providedIn: 'root'
})
export class ERPQueryingService {
  executeQuery<T extends object>(data: T[], query: Partial<ICollectionQuery<T>>, selectors?: QuerySelectorMap<T>) {
    let dataMap = [...new Map(data?.entries())];

    if (query.filtering?.length) {
      dataMap = this.filtering<T>(dataMap, query.filtering, selectors);
    }
    if (query.sorting?.length) {
      dataMap = this.sorting<T>(dataMap, query.sorting[0], selectors);
    }

    return dataMap.map(([, value]) => value);
  }

  getIndexes<T extends object>(data: T[], query: Partial<ICollectionQuery<T>>, selectors?: QuerySelectorMap<T>) {
    let dataMap = [...new Map(data?.entries())];

    if (query.filtering?.length) {
      dataMap = this.filtering<T>(dataMap, query.filtering, selectors);
    }
    if (query.sorting?.length) {
      dataMap = this.sorting<T>(dataMap, query.sorting[0], selectors);
    }

    return dataMap.map(([index]) => index);
  }

  private filtering<T extends object>(
    data: [number, T][],
    filters: IQueryFiltering<T>[],
    selectors?: QuerySelectorMap<T>
  ) {
    return filters.reduce((accum: [number, T][], filter) => {
      switch (filter.op) {
        case 'contains':
          let match = (filter.match1 as string).trim().replace(/_/g, '.');
          match = match.includes('*') ? match.replace('*', '.*') : `.*${match}.*`;

          const regex = new RegExp(match, 'gi');

          return accum.filter(([, value]) =>
            (this.applySelector(value, filter.by as keyof T, selectors) as string)?.match(regex)
          );
        case 'eq':
          return accum.filter(
            ([, value]) => this.applySelector(value, filter.by as keyof T, selectors) === filter.match1
          );
        case 'between':
          return accum.filter(
            ([, value]) =>
              (this.applySelector(value, filter.by as keyof T, selectors) as number) >= (filter.match1 as number) &&
              (this.applySelector(value, filter.by as keyof T, selectors) as number) <= (filter.match2 as number)
          );
        case 'gte':
          return accum.filter(
            ([, value]) =>
              (this.applySelector(value, filter.by as keyof T, selectors) as number) >= (filter.match1 as number)
          );
        case 'lte':
          return accum.filter(
            ([, value]) =>
              (this.applySelector(value, filter.by as keyof T, selectors) as number) <= (filter.match1 as number)
          );
        default:
          return accum;
      }
    }, data);
  }

  private sorting<T extends object>(data: [number, T][], sorting: IQuerySorting<T>, selectors?: QuerySelectorMap<T>) {
    return orderBy(
      data,
      [
        ([, value]) => {
          return this.applySelector(value, sorting.by as keyof T, selectors);
        }
      ],
      [sorting.order as 'asc' | 'desc']
    );
  }

  private applySelector<T extends object>(value: T, by: keyof T, selectors?: QuerySelectorMap<T>) {
    const selector = selectors?.[by];
    const target = value[by];

    return selector ? selector(target) : target;
  }
}
