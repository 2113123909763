import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ERPToasterService, ISelectOption } from '@erp/components';
import { ERPNcpDictionaryService, NcpType } from '@erp/shared';

@Injectable({ providedIn: 'root' })
export class ERPTabletNcpDispositionsResolver {
  constructor(
    private readonly dictionaryService: ERPNcpDictionaryService,
    private readonly toastService: ERPToasterService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.dictionaryService.getNcpDispositions().pipe(
      catchError(() => {
        this.toastService.error('error.default');

        return of([]);
      })
    );
  }
}
