import { BehaviorSubject, Observable, identity, of } from 'rxjs';
import { delay, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { AutoCleanupFeature, Features, IDestroyable } from '@erp/shared';

import { IToasterConfig } from '../../interface';
import { ToasterData, ToasterMessageType, ToasterMessageWithTitle } from '../../types';

@Component({
  selector: 'erp-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPToasterComponent implements OnInit, IDestroyable, IToasterConfig {
  readonly destroyed$: Observable<unknown>;
  @Input() containment: 'root' | 'inline';
  @Input() type: ToasterMessageType = 'info';
  @Input() data: ToasterData;
  @Input() readonly autoHide = true;
  @Input() readonly autoHideTimeout = 5000;
  @Input() readonly autoHideDelay = 500;
  @Input() readonly showTitle = true;
  @Input() readonly closeOnNavigate = false;
  @Input() readonly onClick: () => void;

  @Output() readonly remove = new EventEmitter<unknown>();

  readonly removing$ = new BehaviorSubject<boolean>(false);

  constructor(readonly changeDetector: ChangeDetectorRef, readonly router: Router, readonly route: ActivatedRoute) {}

  get isMessage() {
    return typeof this.message === 'string';
  }

  get message() {
    return (this.data as ToasterMessageWithTitle)?.message ?? this.data;
  }

  get title() {
    return (this.data as ToasterMessageWithTitle)?.title;
  }

  set config(config: IToasterConfig | undefined) {
    Object.assign(this, config);
  }

  ngOnInit() {
    of(this.autoHide)
      .pipe(
        filter(identity),
        delay(this.autoHideTimeout),
        tap(() => this.removing$.next(true)),
        delay(this.autoHideDelay),
        takeUntil(this.remove),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.onRemove());

    of(this.closeOnNavigate)
      .pipe(
        filter(identity),
        switchMap(() => this.router.events.pipe(filter(event => event instanceof NavigationEnd))),
        takeUntil(this.remove),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.onRemove();
      });
  }

  contentClicked() {
    this.onClick?.();
  }

  onRemove() {
    this.removing$.next(true);

    of(true)
      .pipe(delay(this.autoHideDelay), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.remove.emit();
        this.remove.complete();
      });
  }
}
