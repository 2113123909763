import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import { ERPLabelComponent } from './components';

@NgModule({
  declarations: [ERPLabelComponent],
  imports: [CommonModule, MatFormFieldModule],
  exports: [ERPLabelComponent]
})
export class ERPLabelModule {}
