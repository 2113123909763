import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'erp-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPIconComponent implements OnChanges {
  @Input() readonly icon: string;
  @Input() readonly variant: 'small' | 'medium' | 'large';
  @Input() readonly color: ThemePalette | 'string';
  @Input() readonly size: 'auto' | 'fit' | 'big' | null;

  private readonly defaultExtension = 'svg';
  private readonly baseUrl = 'assets/icons';

  constructor(readonly sanitizer: DomSanitizer, readonly registry: MatIconRegistry) {}

  ngOnChanges() {
    const [namespace, name] = this.icon.split(':');
    const baseIconUrl = `${this.baseUrl}/${namespace}/${name}`;

    const iconUrl = this.variant
      ? `${baseIconUrl}/${this.variant}.${this.defaultExtension}`
      : `${baseIconUrl}.${this.defaultExtension}`;
    const safeIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iconUrl);

    this.registry.addSvgIconInNamespace(namespace, name, safeIconUrl);
  }
}
