<div class="flex justify-content-space-between">
  <div>
    <ul class="erp-paginator flex" *ngIf="total > pageSize">
      <li
        class="erp-paginator-btn flex justify-content-center align-items-center erp-paginator-arrow erp-paginator-arrow__left"
        (click)="changePageTo(page - 1)"
        [class.erp-paginator-arrow__disabled]="page < 2"
      ></li>
      <li
        class="erp-paginator-btn flex justify-content-center align-items-center"
        (click)="changePageTo(1)"
        [class.erp-paginator-btn__active]="page === 1"
      >
        1
      </li>
      <li
        class="erp-paginator-btn flex justify-content-center align-items-center"
        *ngIf="innerBtns[0] > innerBtnsSize"
        (click)="changePageTo(innerBtns[0] - 1)"
      >
        ...
      </li>
      <li
        class="erp-paginator-btn flex justify-content-center align-items-center"
        *ngFor="let i of innerBtns"
        [class.erp-paginator-btn__active]="i === page"
        (click)="changePageTo(i)"
      >
        {{ i }}
      </li>
      <li
        class="erp-paginator-btn flex justify-content-center align-items-center"
        *ngIf="innerBtns[innerBtns.length - 1] < last - 1"
        (click)="changePageTo(innerBtns[innerBtns.length - 1] + 1)"
      >
        ...
      </li>
      <li
        class="erp-paginator-btn flex justify-content-center align-items-center"
        *ngIf="last"
        (click)="changePageTo(last)"
        [class.erp-paginator-btn__active]="last === page"
      >
        {{ last }}
      </li>
      <li
        class="erp-paginator-btn flex justify-content-center align-items-center erp-paginator-arrow erp-paginator-arrow__right"
        (click)="changePageTo(page + 1)"
        [class.erp-paginator-arrow__disabled]="last === page || !last"
      ></li>
    </ul>
  </div>
  <div class="flex">
    <ng-container *ngIf="showRecordsPerPage">
      <div>
        <erp-label>
          <ng-container i18n="@@common.paginator.records-per-page">
            Records per page
          </ng-container>
        </erp-label>
      </div>
      <div class="select-width">
        <erp-select
          [formControl]="recordsPerPage"
          [options]="options"
          [optional]="false"
          (changed)="updateRecordsPerPage($event)"
        ></erp-select>
      </div>
    </ng-container>
  </div>
</div>
