import { TubeProperties } from '../enums';

export const tubePropertiesOrder: { [key: string]: number } = {
  [TubeProperties.MaterialType]: 1,
  [TubeProperties.OD]: 2,
  [TubeProperties.Wall]: 3,
  [TubeProperties.WeightPerFoot]: 4,
  [TubeProperties.Grade]: 5,
  [TubeProperties.Connection]: 6,
  [TubeProperties.EndType]: 7,
  [TubeProperties.Design]: 8,
  [TubeProperties.Range]: 9,
  [TubeProperties.ProtectorType]: 10,
  [TubeProperties.Condition]: 11,
  [TubeProperties.MillName]: 12
};
