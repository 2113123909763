<div class="toolbar-action">
  <erp-button [matMenuTriggerFor]="erpActionsMenu" variant="link" [color]="color" [disabled]="disabled">
    <ng-container *ngIf="title">
      {{ title }}
    </ng-container>
    <ng-content *ngIf="!title" select="[title]"></ng-content>
    <erp-icon icon="action:chevron-down"></erp-icon>
  </erp-button>
</div>
<mat-menu #erpActionsMenu="matMenu" xPosition="before">
  <button
    mat-menu-item
    *ngFor="let actionItem of actionList"
    [disabled]="actionItem.disabled"
    (click)="emitCall(actionItem)"
  >
    {{ actionItem.title }}
  </button>
</mat-menu>
