import { Subject, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogState as MatDialogState
} from '@angular/material/legacy-dialog';

import { ERPLoadingSpinnerComponent } from '@erp/components';

const DEFAULT_DELAY = 1000;
@Injectable({
  providedIn: 'root'
})
export class ERPLoadingService {
  private requestCounter = 0;
  private spinnerDialog: MatDialogRef<ERPLoadingSpinnerComponent>;
  private counterSubject$ = new Subject<number>();
  private counterGETSubject$ = new Subject<number>();

  constructor(private dialogService: MatDialog) {
    this.initSubscription();
  }

  addRequest() {
    this.requestCounter++;
    this.counterSubject$.next(this.requestCounter);
  }

  addGETRequest() {
    this.requestCounter++;
    this.counterGETSubject$.next(this.requestCounter);
  }

  removeRequest() {
    this.requestCounter--;
    this.counterSubject$.next(this.requestCounter);
  }

  removeGETRequest() {
    this.requestCounter--;
    this.counterGETSubject$.next(this.requestCounter);
  }

  private initSubscription() {
    merge(this.counterSubject$, this.counterGETSubject$.pipe(debounceTime(DEFAULT_DELAY))).subscribe(() => {
      this.handleLoader(this.requestCounter);
    });
  }

  private handleLoader(count: number): void {
    if (count > 0 && (!this.spinnerDialog || this.spinnerDialog?.getState() === MatDialogState.CLOSED)) {
      this.spinnerDialog = this.dialogService.open(ERPLoadingSpinnerComponent, {
        backdropClass: 'transparent',
        panelClass: 'erp-spinner-dialog',
        disableClose: true
      });

      return;
    }
    if (count <= 0 && this.spinnerDialog && this.spinnerDialog?.getState() === MatDialogState.OPEN) {
      this.spinnerDialog?.close();
    }
  }
}
