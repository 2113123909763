import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ERPButtonModule } from '../../button';
import { ERPConfirmModule } from '../../confirm';
import { ERPIconModule } from '../../icon';

import { ERPDiscardChangesDialogComponent, ERPDiscardChangesStateDialogComponent } from './components';

@NgModule({
  declarations: [ERPDiscardChangesDialogComponent, ERPDiscardChangesStateDialogComponent],
  imports: [CommonModule, ERPConfirmModule, MatDialogModule, ERPButtonModule, ERPIconModule],
  exports: [ERPDiscardChangesDialogComponent, ERPDiscardChangesStateDialogComponent]
})
export class ERPDiscardChangesDialogModule {}
