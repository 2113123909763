import { Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AutoCleanupFeature,
  BaseListComponent,
  ERPAuthService,
  Features,
  ICollectionResponseMetadata
} from '@erp/shared';

import { INotification } from '../../interfaces';
import { ERPNotificationEventsService, ERPNotificationService } from '../../services';

@Component({
  selector: 'erp-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPNotificationListComponent extends BaseListComponent<INotification> implements OnInit {
  destroyed$: Observable<void>;
  isTabletModule: boolean;
  data: INotification[];

  get query$() {
    return this.route.data.pipe<ICollectionResponseMetadata>(map(({ list }) => list?.metadata));
  }

  private currentUserId: string;

  constructor(
    readonly router: Router,
    readonly route: ActivatedRoute,
    readonly changeDetector: ChangeDetectorRef,
    readonly notificationService: ERPNotificationService,
    readonly authService: ERPAuthService,
    readonly eventService: ERPNotificationEventsService
  ) {
    super();
    this.route.data.pipe(map(({ list }) => list?.data)).subscribe(data => (this.data = data));

    this.currentUserId = authService.getCustomUserID();
  }

  ngOnInit(): void {
    super.onInit();

    this.eventService.newNotificationEvent
      .asObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(notification => {
        notification.date = new Date();
        this.data = [notification, ...this.data];
        this.changeDetector.detectChanges();
      });
    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isTabletModule = this.router.url.startsWith('/tablet');
    });
  }

  markAllAsRead() {
    this.notificationService
      .markAllAsRead(this.currentUserId, {})
      .pipe(tap(() => this.eventService.updateUnreadCount(0)))
      .subscribe(() => {
        this.data = this.data.map(el => {
          el.isRead = true;

          return el;
        });
        this.changeDetector.detectChanges();
      });
  }
}
