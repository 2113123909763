import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'erp-panel-body',
  templateUrl: './panel-body.component.html',
  styleUrls: ['./panel-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPPanelBodyComponent {
  @Input() readonly padding: 'vertical' | 'horizontal' | 'default' = 'default';
}
