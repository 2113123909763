import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';

import { ERPSharedModule } from '../../../../../shared/src/lib';
import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';
import { ERPLabelModule } from '../label';
import { ERPNumberModule } from '../number';
import { ERPPaginatorModule } from '../paginator';
import { ERPSelectModule } from '../select';

import {
  ERPSkuSmartSearchDialogComponent,
  ERPSkuSmartSearchFilteringComponent,
  ERPSkuSmartSearchTableComponent
} from './components';

@NgModule({
  declarations: [
    ERPSkuSmartSearchDialogComponent,
    ERPSkuSmartSearchTableComponent,
    ERPSkuSmartSearchFilteringComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ERPButtonModule,
    ERPIconModule,
    ERPLabelModule,
    ERPSelectModule,
    ERPPaginatorModule,
    MatSortModule,
    MatTableModule,
    ERPNumberModule,
    ERPSharedModule,
    RouterModule
  ],
  exports: [ERPSkuSmartSearchDialogComponent]
})
export class ERPSkuSmartSearchModule {}
