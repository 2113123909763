import { AbstractControl } from '@angular/forms';

const BASE = 2;
const POWER = 33;
const BASE_DEC = 10;
const BASE_DEC_POWER = 6;
const MAX_SAFE_VALUE = BASE ** POWER - BASE_DEC ** -BASE_DEC_POWER;
const MIN_SAFE_VALUE = -MAX_SAFE_VALUE;

SafeDecimalValidator.MIN_SAFE_VALUE = MIN_SAFE_VALUE;
SafeDecimalValidator.MAX_SAFE_VALUE = MAX_SAFE_VALUE;

export function SafeDecimalValidator(control: AbstractControl) {
  const value = control.value as string | number | null;
  const valid = !value || (+value >= MIN_SAFE_VALUE && +value <= MAX_SAFE_VALUE);

  return valid ? null : { safeDecimal: true };
}
