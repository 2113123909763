import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

import {
  AutoCleanupFeature,
  Features,
  IDestroyable,
  IMetadataSorting,
  IProductSearch,
  ModulesLinks
} from '@erp/shared';

@Component({
  selector: 'erp-inventory-sku-smart-search-table',
  templateUrl: './sku-smart-search-table.component.html',
  styleUrls: ['./sku-smart-search-table.component.scss'],
  providers: []
})
@Features([AutoCleanupFeature()])
export class ERPSkuSmartSearchTableComponent implements OnInit, IDestroyable {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  readonly destroyed$: Observable<unknown>;
  readonly dataSource = new MatTableDataSource<IProductSearch>([]);
  readonly procurementLinks = ModulesLinks;
  readonly links = new Map<string, string>([
    ['CAS', ModulesLinks.Casing],
    ['TUB', ModulesLinks.Tubing],
    ['COS', ModulesLinks.CouplingStock],
    ['LIP', ModulesLinks.LinePipe],
    ['DRP', ModulesLinks.DrillPipe],
    ['MAR', ModulesLinks.Marker],
    ['COU', ModulesLinks.Coupling],
    ['PRO', ModulesLinks.Protector],
    ['SER', ModulesLinks.Service]
  ]);

  @Input() readonly total: number;

  @Input()
  set sorting(data: IMetadataSorting[]) {
    if (data && data.length > 0) {
      this.sort.active = data[0]?.by;
      this.sort.direction = data[0]?.order;
    } else {
      this.sort.sort({
        id: (null as unknown) as string,
        start: 'asc',
        disableClear: true
      });
    }

    this.changeDetectorRef.markForCheck();
  }

  @Input()
  set tableData(data: IProductSearch[]) {
    this.dataSource.data = data || [];
  }

  @Output() readonly sortingChange: EventEmitter<IMetadataSorting[]> = new EventEmitter();
  @Output() readonly productSelection: EventEmitter<IProductSearch> = new EventEmitter();

  displayedColumns: (keyof IProductSearch | 'action')[] = [
    'id',
    'skuDescription',
    'size',
    'wall',
    'weight',
    'grade',
    'connection',
    'thread',
    'range',
    'action'
  ];

  constructor(readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.sort.sortChange
      .pipe(
        takeUntil(this.destroyed$),
        filter(sort => !!sort.active)
      )
      .subscribe(sort => {
        if (sort.direction) {
          this.sortingChange.emit([{ by: sort.active, order: sort.direction }]);
        } else {
          this.sortingChange.emit([]);
        }
      });
  }

  onSelect(product: IProductSearch) {
    this.productSelection.emit(product);
  }

  getLink(id: string): string {
    const startIndex = 0;
    const endIndex = 3;

    return this.links.get(id?.substring(startIndex, endIndex)) || '';
  }
}
