import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPConfirmModule } from '../../confirm';

import { ERPCompleteLineDialogComponent } from './components';

@NgModule({
  declarations: [ERPCompleteLineDialogComponent],
  imports: [CommonModule, ERPConfirmModule],
  exports: [ERPCompleteLineDialogComponent]
})
export class ERPCompleteDialogModule {}
