<span class="white-space-normal overflow-wrap-anywhere margin-right-1" (click)="onUploadClick($event)">
  <ng-content></ng-content>
</span>
<input
  type="file"
  [multiple]="multiple"
  accept="*"
  (change)="onSelectFiles()"
  #input
  hidden
  (click)="$event.stopPropagation()"
/>
