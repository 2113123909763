import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ERPSidenavPositionService {
  $sidenavWidthObservable = new BehaviorSubject('20rem');

  onPushNewWith(width: string) {
    this.$sidenavWidthObservable.next(width);
  }
}
