import * as HttpStatus from 'http-status';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ERPAuthService } from '@erp/shared';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    readonly route: ActivatedRoute,
    readonly authService: ERPAuthService
  ) {}

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const request = req.clone({
      withCredentials: true
    });

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatus.UNAUTHORIZED) {
            this.authService.login();
          }
        }

        return throwError(error);
      })
    );
  }
}
