import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../../../../../../shared/src/lib';
import { IQcSpecification } from '../interfaces/qc-specification.interface';

@Injectable({
  providedIn: 'root'
})
export class ERPQcSpecificationService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('qcSpecification');
  }

  getQCSpecification(params?: object) {
    return this.get<ICollectionResponse<IQcSpecification>>(params);
  }
}
