<erp-confirm
  i18n-header="@@common.dialogs.close-line.header"
  header="Close this line?"
  i18n-message="@@common.dialogs.close-line.message"
  message="After closing you will not be able to make any changes to the line."
  i18n-cancel="@@common.dialogs.close-line.buttons.keep-it"
  cancel="No"
  i18n-confirm="@@common.dialogs.close-line.buttons.delete-it"
  confirm="Yes"
  color="warn"
></erp-confirm>
