import { ApplicationRef, ComponentRef, DoBootstrap, NgModule, OnDestroy } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@environment';
import {
  ERPButtonModule,
  ERPIconModule,
  ERPInputModule,
  ERPNotificationEventsService,
  ERPNotificationListenerService,
  ERPNotificationsModule,
  ERPRedirectModule,
  ERPRoutingSpinnerModule,
  ERPSelectModule,
  ERPSidenavModule,
  ERPToasterModule
} from '@erp/components';
import { ERPCoreModule } from '@erp/core';
import { ERPRouterExtService, ERPSharedModule, ERPSignalRConnectionService } from '@erp/shared';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ERPAppRoutingModule } from './app-module.routing';
import { ERPAppComponent, ERPFooterComponent, ERPLandingComponent } from './components';

@NgModule({
  declarations: [ERPAppComponent, ERPLandingComponent, ERPFooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register(environment.serviceWorkerPath, {
      enabled: environment.production
    }),
    MatCardModule,
    MatDialogModule,
    ERPCoreModule,
    ERPSharedModule,
    ERPIconModule,
    ERPSelectModule,
    ERPButtonModule,
    ERPToasterModule,
    ERPInputModule,
    ERPRoutingSpinnerModule,
    ERPRedirectModule,
    ERPAppRoutingModule,
    ERPSidenavModule,
    ERPNotificationsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    ERPSignalRConnectionService,
    ERPNotificationListenerService,
    ERPNotificationEventsService,
    ERPRouterExtService,
    CookieService,
    provideNgxMask()
  ]
})
export class ERPAppModule implements DoBootstrap {
  root: ComponentRef<ERPAppComponent>;

  ngDoBootstrap(appRef: ApplicationRef) {
    this.root = appRef.bootstrap(ERPAppComponent);
  }
}
