import { ICollectionResponseMetadata, IMetadataFiltering, IMetadataPaging, IMetadataSorting } from '../abstracts';

export class Query<T> {
  readonly paging: IMetadataPaging = {};
  readonly sorting: IMetadataSorting<T>[] = [];
  readonly filtering: IMetadataFiltering<T>[] = [];
  readonly grouping?: (keyof T | string)[] = [];

  constructor(query?: Partial<Query<T>>) {
    this.filtering = [...(query?.filtering ?? this.filtering)];
    this.paging = { ...(query?.paging ?? this.paging) };
    this.sorting = [...(query?.sorting ?? this.sorting)];
    this.grouping = [...(query?.grouping ?? this.grouping ?? [])];
  }

  serialize() {
    return JSON.stringify(this);
  }
}

class QueryBuilder<T> {
  private query: Query<T>;

  constructor(query: Partial<Query<T>>) {
    this.query = new Query(query);
  }

  static from<T>(query: Partial<Query<T>>) {
    return new QueryBuilder(query);
  }

  static fromString<T>(string: string | null) {
    const query = JSON.parse(string as string);

    return new QueryBuilder<T>(query);
  }

  static empty<T>() {
    return new Query<T>();
  }

  static serialize<T>(query: ICollectionResponseMetadata<T>) {
    return new Query<T>(query).serialize();
  }

  withQuery(query: ICollectionResponseMetadata<T>): this {
    this.query = new Query<T>(query);

    return this;
  }

  withSorting(sorting: IMetadataSorting<T>[]): this {
    this.query = new Query<T>({
      ...this.query,
      sorting
    });

    return this;
  }

  withGrouping(grouping: (keyof T)[]): this {
    this.query = new Query<T>({
      ...this.query,
      grouping
    });

    return this;
  }

  withPaging(paging: IMetadataPaging): this {
    this.query = new Query<T>({
      ...this.query,
      paging
    });

    return this;
  }

  withFiltering(filtering: IMetadataFiltering<T>[]): this {
    this.query = new Query<T>({
      ...this.query,
      filtering
    });

    return this;
  }

  setFilter(filter: IMetadataFiltering<T>) {
    const filtering = this.query.filtering.filter(x => x.by !== filter.by);

    this.query = new Query<T>({
      ...this.query,
      filtering: [...filtering, { ...filter }]
    });

    return this;
  }

  setInFilter(filter: IMetadataFiltering<T>) {
    const otherOpFiltering = this.query.filtering.filter(x => x.by === filter.by && x.op !== 'in');
    const sameMatchFiltering = this.query.filtering.filter(x => x.by === filter.by && x.match1 === filter.match1);
    if (
      (otherOpFiltering && otherOpFiltering.length) ||
      (sameMatchFiltering && sameMatchFiltering.length) ||
      filter.op !== 'in'
    ) {
      return this;
    }

    this.query = new Query<T>({
      ...this.query,
      filtering: [...this.query.filtering, { ...filter }]
    });

    return this;
  }

  removeFilter(filter: IMetadataFiltering<T>) {
    const filtering = this.query.filtering.filter(x => x.by !== filter.by);

    this.query = new Query<T>({
      ...this.query,
      filtering
    });

    return this;
  }

  removeInFilter(filter: IMetadataFiltering<T>) {
    if (filter.op !== 'in') {
      return this;
    }
    const filtering = this.query.filtering.filter(
      x => x.by !== filter.by && x.match1 !== filter.match1 && x.op !== filter.op
    );

    this.query = new Query<T>({
      ...this.query,
      filtering
    });

    return this;
  }

  removeFiltering(filters: IMetadataFiltering<T>[]) {
    const by = filters.map(x => x.by);
    const filtering = this.query.filtering.filter(x => !by.includes(x.by));

    this.query = new Query<T>({
      ...this.query,
      filtering
    });

    return this;
  }

  setSort(sort: IMetadataSorting<T>) {
    const sorting = this.query.sorting.filter(x => x.by !== sort.by);

    this.query = new Query({
      ...this.query,
      sorting: [...sorting, { ...sort }]
    });

    return this;
  }

  removeSort(sort: IMetadataSorting<T>) {
    const sorting = this.query.sorting.filter(x => x.by !== sort.by);

    this.query = new Query<T>({
      ...this.query,
      sorting
    });

    return this;
  }

  removeSorting(sorts: IMetadataSorting<T>[]) {
    const by = sorts.map(x => x.by);
    const sorting = this.query.sorting.filter(x => !by.includes(x.by));

    this.query = new Query<T>({
      ...this.query,
      sorting
    });

    return this;
  }

  setPage(page: number) {
    this.query = new Query<T>({
      ...this.query,
      paging: {
        ...this.query.paging,
        page
      }
    });

    return this;
  }

  mergeWith(query: Query<T>) {
    if (query.filtering) {
      const filteringMap = new Map([
        ...this.query.filtering.map(x => [x.by, x] as [keyof T, IMetadataFiltering<T>]),
        ...query.filtering.map(x => [x.by, x] as [keyof T, IMetadataFiltering<T>])
      ]);

      this.query = new Query({
        ...this.query,
        filtering: [...filteringMap.values()]
      });
    }

    if (query.sorting) {
      const sortingMap = new Map([
        ...this.query.sorting.map(x => [x.by, x] as [keyof T, IMetadataSorting<T>]),
        ...query.sorting.map(x => [x.by, x] as [keyof T, IMetadataSorting<T>])
      ]);

      this.query = new Query({
        ...this.query,
        sorting: [...sortingMap.values()]
      });
    }

    if (query.paging) {
      this.query = new Query({
        ...this.query,
        paging: {
          ...this.query.paging,
          ...query.paging
        }
      });
    }

    return this;
  }

  build() {
    return new Query<T>(this.query);
  }

  serialize() {
    return this.query.serialize();
  }
}

export { QueryBuilder as TableQueryBuilder };
