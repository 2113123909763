import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ERPActionsSelectModule } from '../actions-select/actions-select.module';

import { ERPFixedToolbarActionComponent, ERPToolbarActionsComponent, ERPToolbarComponent } from './components';

@NgModule({
  declarations: [ERPToolbarActionsComponent, ERPToolbarComponent, ERPFixedToolbarActionComponent],
  imports: [CommonModule, MatToolbarModule, MatDividerModule, ERPActionsSelectModule],
  exports: [ERPToolbarActionsComponent, ERPToolbarComponent, ERPFixedToolbarActionComponent]
})
export class ERPToolbarModule {}
