import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { IUser } from '../interfaces';
import { ERPUsersService } from '../services';

@Injectable({ providedIn: 'root' })
export class ERPUsersResolver {
  constructor(readonly usersService: ERPUsersService) {}

  resolve(): Observable<IUser[]> {
    return this.usersService.users;
  }
}
