import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'erp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPButtonComponent {
  @Input() readonly type: 'button' | 'submit' | 'reset' = 'button';
  @Input() readonly color: ThemePalette = 'accent';
  @Input() readonly variant: 'flat' | 'link' | 'icon' = 'flat';
  @Input() readonly textAlign: 'end' | 'center' | 'start' = 'center';
  @Input() @HostBinding('attr.disabled') readonly disabled: boolean;
}
