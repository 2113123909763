export const pageTitles = {
  main: $localize`:@@app.router.title.main:Panoptic`,
  inventoryManagement: $localize`:@@app.router.title.inventory-management:Panoptic: Inventory Management`,
  sales: $localize`:@@app.router.title.sales:Panoptic: Sales`,
  logisticWarehousing: $localize`:@@app.router.title.logistic-warehousing:Panoptic: Logistics and Warehousing`,
  production: $localize`:@@app.router.title.production:Panoptic: Production`,
  planing: $localize`:@@app.router.title.planing:Panoptic: Planning`,
  procurement: $localize`:@@app.router.title.procurement:Panoptic: Procurement`,
  administration: $localize`:@@app.router.title.administration:Panoptic: Administration`,
  qualityControl: $localize`:@@app.router.title.quality-control:Panoptic: Quality Control`,
  formBuilder: $localize`:@@app.router.title.form-builder:Panoptic: Form Builder`,
  notifications: $localize`:@@app.router.title.notifications:Panoptic: Notifications`,
  tablet: $localize`:@@app.router.title.logistic-warehousing:Panoptic: Logistics and Warehousing`
};
