import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WidgetComponent } from './components';
import { WidgetDirective } from './directives';

@NgModule({
  imports: [CommonModule],
  declarations: [WidgetComponent, WidgetDirective],
  exports: [WidgetComponent, WidgetDirective]
})
export class ERPWidgetModule {}
