import { Injectable } from '@angular/core';

import { ISegregation, ISegregationResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SegregationFactory {
  toRequest(segregation: ISegregation): ISegregationResponse {
    delete segregation.mainInfo.rack;

    return {
      id: segregation.id,
      rowVersion: segregation.rowVersion,
      historyList: segregation.historyList?.map(item => ({
        ...item,
        colors: (item.colors || []).map((color, index) => ({
          ...color,
          orderNumber: index
        }))
      })),
      ...segregation.mainInfo,
      entryId: segregation.entryId
    };
  }

  fromResponse(segregation: ISegregationResponse): ISegregation {
    return {
      id: segregation.id,
      rowVersion: segregation.rowVersion,
      mainInfo: {
        statusId: segregation.statusId,
        statusName: segregation.statusName,
        skuDescription: segregation.skuDescription,
        skuId: segregation.skuId,
        rackToName: segregation.rackToName,
        rackToId: segregation.rackToId,
        racks: segregation.racks,
        rack: null,
        processingOrderNumber: segregation.processingOrderNumber,
        processingOrderId: segregation.processingOrderId,
        materialNumber: segregation.materialNumber,
        materialId: segregation.materialId,
        locationName: segregation.locationName,
        locationId: segregation.locationId,
        documentNumber: segregation.documentNumber,
        assignedToUserName: segregation.assignedToUserName,
        assignedToUserId: segregation.assignedToUserId,
        requestedQTY: segregation.requestedQTY ?? 0,
        rackQTY: segregation.rackQTY ?? 0,
        inventoryStatus: segregation.inventoryStatus,
        mill: segregation.mill
      },
      historyList: segregation.historyList?.map(item => ({
        ...item,
        colors: item.colors.sort((a, b) => {
          const aOrderNm = a.orderNumber || 0;
          const bOrderNm = b.orderNumber || 0;

          return aOrderNm > bOrderNm ? 1 : bOrderNm > aOrderNm ? -1 : 0;
        })
      }))
    };
  }
}
