import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UntypedFormControl } from '@angular/forms';

import { TableQueryBuilder } from '../../builders';
import { IMetadataFiltering, QueryOperation } from '../http';

import { BaseListComponent } from './base-list.component';

export abstract class BaseAutocompleteListComponent<T extends object> extends BaseListComponent {
  abstract readonly destroyed$: Observable<void>;

  abstract readonly autocompleteColumn?: keyof T;
  abstract readonly autocompleteColumnOp?: QueryOperation;

  readonly autocompleteControl = new UntypedFormControl(null);

  onInit() {
    super.onInit();

    this.onSetControlValue();
  }

  onSetControlValue() {
    this.query$.pipe(takeUntil(this.destroyed$)).subscribe(query => {
      const column = this.autocompleteColumn;
      const value = query.filtering.find(x => x.by === column)?.match1 as T[keyof T];

      this.autocompleteControl.setValue(value);
      this.changeDetector.markForCheck();
    });
  }

  onReferenceFilterChanged(value: T[keyof T]) {
    const column = this.autocompleteColumn;
    const op = this.autocompleteColumnOp;
    const builder = new TableQueryBuilder(this.query).removeFilter({
      by: column as string
    } as IMetadataFiltering);

    if (value) {
      builder.setFilter({
        by: column,
        op,
        match1: value
      } as IMetadataFiltering);
    }

    const query = builder
      .withPaging({
        ...this.query.paging,
        page: 1
      })
      .build();

    this.onQueryChanged(query);
  }
}
