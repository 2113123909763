import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { ERPAutocompleteModule } from '../autocomplete';
import { ERPIconModule } from '../icon';

import { ERPMultiSelectComponent } from './components';

@NgModule({
  declarations: [ERPMultiSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ERPIconModule,
    ERPAutocompleteModule
  ],
  exports: [MatFormFieldModule, ReactiveFormsModule, ERPMultiSelectComponent]
})
export class ERPMultiSelectModule {}
