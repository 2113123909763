import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

import { AutoCleanupFeature, BaseControlComponent, ERPFormStateDispatcher, Features } from '@erp/shared';

@Component({
  selector: 'erp-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPDatePickerComponent extends BaseControlComponent<Date> implements OnInit {
  readonly destroyed$: Observable<void>;
  readonly control = new UntypedFormControl(null);
  @Input() readonly icon: string;
  @Input() readonly disableFuture: boolean;
  @Input() readonly disablePast: boolean;
  @Input() readonly min: Date;
  @Input() readonly max: Date;
  @Input() readonly touchUi: boolean;
  @Output() readonly dateChanged = new EventEmitter<Date | null>();
  @ViewChild(MatDatepicker) readonly picker: MatDatepicker<Date>;

  readonly viewToModelParser = (value: Date | null) => {
    if (value instanceof Date) {
      const utcDate = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate());

      return new Date(utcDate);
    }

    return value;
  };

  readonly modelToViewFormatter = (value: Date | null) => {
    if (value instanceof Date) {
      return new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate());
    }

    return value;
  };

  get maxDate() {
    return this.disableFuture ? new Date() : this.max ?? null;
  }

  get minDate() {
    return this.disablePast ? new Date() : this.min ?? null;
  }

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.dateChanged.emit(event?.value);
  }

  onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }

  onFocus() {
    this.picker.open();
    this.onTouched?.();
  }

  onClear() {
    if (this.control.disabled) {
      return;
    }

    this.control.setValue(null);
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }
}
