import { Injectable } from '@angular/core';

import { InventoryStatusService } from '../services';

@Injectable({ providedIn: 'root' })
export class ERPInventoryStatusesResolver {
  constructor(readonly inventoryStatusService: InventoryStatusService) {}

  resolve() {
    return this.inventoryStatusService.inventoryStatuses;
  }
}
