import { UntypedFormGroup } from '@angular/forms';

import { IProductSearch, ProductionServiceType } from '@erp/shared';

import { DriftStandardEnum, HeatTreatProcesses, PslValuesEnum, SupplementaryReq } from '../enums';
import {
  IProductionProcessingOrder,
  IProductionProcessingOrderSpecification,
  IProductionProcessingOrderSpecificationHeatTreat,
  IProductionProcessingOrderSpecificationHydro,
  IProductionProcessingOrderSpecificationThreading,
  IQcSpecification
} from '../interfaces';

export function generateServiceStencil(obj: {
  serviceType: number;
  order?: IProductionProcessingOrder;
  sku?: IProductSearch;
  headerSku?: IProductSearch;
  skuSpecification?: IQcSpecification;
  processingOrderStandardId?: string;
  form?: UntypedFormGroup;
  spec?:
    | IProductionProcessingOrderSpecificationHeatTreat
    | IProductionProcessingOrderSpecificationHydro
    | IProductionProcessingOrderSpecificationThreading
    | IProductionProcessingOrderSpecification;
}) {
  const serviceTypeEnum = ProductionServiceType;
  const data = [];

  if (obj.serviceType === serviceTypeEnum.Upsetting) {
    if (obj.order?.mainInfo.documentNumber) {
      data.push(obj.order?.mainInfo.documentNumber);
    }
    if (obj.sku?.size) {
      data.push(obj.sku?.size.toFixed(3));
    }
    if (obj.sku?.wall) {
      data.push(obj.sku?.wall.toFixed(3));
    }

    data.push('HEAT #(varies)');

    return data.join('  ');
  }

  if (obj.serviceType === serviceTypeEnum.HeatTreat) {
    const specification = obj?.spec as IProductionProcessingOrderSpecificationHeatTreat;
    const firstLine = [];
    const secondLine = ['HEAT #(varies)', 'LOT #(varies)', 'SHIFT (varies)'];
    const threadOptions = [
      'LC',
      'SC',
      'BC',
      'NU',
      'EU',
      'IJ',
      'USS-CDC HTQ',
      'BK',
      'BK-HT',
      'HOSS',
      'HYDRA',
      'INTERLOCK',
      'INTERLOCK SLIMLINE',
      'ME2',
      'HYDRA-XL',
      'OG'
    ];

    // NAME
    switch (obj.processingOrderStandardId) {
      case 'API 5CT':
        firstLine.push('PANOPTIC');
        break;
      case 'API 5L':
        firstLine.push('PANOPTIC');
        break;
      case 'API 5CT AMA Tenaris Baytown':
        firstLine.push('TENARIS BW');
        break;
      case 'API 5CT AMA Tenaris Bay City':
        firstLine.push('TENARIS BC');
        break;
      case 'API 5L AMA USS Fairfield':
        firstLine.push('USS');
        break;
      case 'API 5CT AMA USS Fairfield':
        firstLine.push('USS');
        break;
      case 'API 5CT AMA Tenaris Hickman':
        firstLine.push('Tenaris HK');
        break;
      case 'API 5CT AMA Tenaris Ambridge':
        firstLine.push('Tenaris AM');
        break;
      case 'API 5CT AMA Tenaris Conroe':
        firstLine.push('Tenaris CR');
    }

    // API MONO X1
    switch (obj.processingOrderStandardId) {
      case 'API 5CT':
        firstLine.push('API 5CT-1692 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5L':
        firstLine.push('API 5L-1241 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5CT AMA Tenaris Baytown':
        firstLine.push('API 5CT-2256 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5CT AMA Tenaris Bay City':
        firstLine.push('API 5CT-1919 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5CT AMA USS Fairfield':
        firstLine.push('API 5CT-0161.1 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5CT AMA Tenaris Hickman':
        firstLine.push('API 5CT-0070.3 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5CT AMA Tenaris Ambridge':
        firstLine.push('API 5CT-2288 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5CT AMA Tenaris Conroe':
        firstLine.push('API 5CT-0070-.1 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'API 5L AMA USS Fairfield':
        firstLine.push('API 5L-0133.2 < >');
        firstLine.push('<<API MONO>>');
    }

    // MM-YY
    firstLine.push('MM-YY');

    // X2
    if (obj.headerSku?.thread === 'PE') {
      firstLine.push('PE');
    } else if (obj.headerSku?.thread === 'SF') {
      firstLine.push('SF');
    }

    // X3 X4
    if (obj.headerSku?.size) {
      firstLine.push(`${obj.headerSku?.size.toFixed(3)}`);
    }

    if (obj.skuSpecification?.tandCWeight) {
      firstLine.push(`${obj.skuSpecification?.tandCWeight.toFixed(2)}`);
    }

    // X5
    // @ts-ignore
    firstLine.push(obj.skuSpecification?.gradeDesignation);
    // X6
    if (
      (obj.skuSpecification?.gradeDesignation === 'H' ||
        obj.skuSpecification?.gradeDesignation === 'K' ||
        obj.skuSpecification?.gradeDesignation === 'J') &&
      obj.skuSpecification?.cvnTestTemp &&
      obj.skuSpecification?.cvnTestTemp !== 'N/A' &&
      (obj.form?.get('specification')?.value.splr2 === SupplementaryReq.SR16 ||
        specification?.splr2 === SupplementaryReq.SR16)
    ) {
      firstLine.push('70F');
    }
    // X7
    if (
      (obj.skuSpecification?.gradeDesignation === 'K' || obj.skuSpecification?.gradeDesignation === 'J') &&
      (obj.form?.get('specification')?.value.processId === HeatTreatProcesses.QT ||
        specification?.processId === HeatTreatProcesses.QT)
    ) {
      firstLine.push('N&T');
    } else if (
      (obj.skuSpecification?.gradeDesignation === 'K' || obj.skuSpecification?.gradeDesignation === 'J') &&
      (obj.form?.get('specification')?.value.processId === HeatTreatProcesses.Normalize ||
        specification?.processId === HeatTreatProcesses.Normalize)
    ) {
      firstLine.push('Z');
    }
    // X8
    if (obj.headerSku?.design === 'ERW') {
      firstLine.push('E');
    } else if (obj.headerSku?.design === 'SMLS') {
      firstLine.push('S');
    }

    // X9
    if (
      obj.form?.get('specification')?.value.splr1 === SupplementaryReq.SR1 ||
      specification?.splr1 === SupplementaryReq.SR1
    ) {
      firstLine.push('S1');
    } else if (
      obj.form?.get('specification')?.value.splr1 === SupplementaryReq.SR2 ||
      specification?.splr1 === SupplementaryReq.SR2
    ) {
      firstLine.push('S2');
    }
    // X10
    if (
      obj.skuSpecification?.cvnTestTemp &&
      obj.skuSpecification?.cvnTestTemp !== 'N/A' &&
      (obj.form?.get('specification')?.value.splr2 === SupplementaryReq.SR16 ||
        specification?.splr2 === SupplementaryReq.SR16)
    ) {
      const str = `S16-${obj.skuSpecification?.cvnFullSizeEnergy}+${obj.skuSpecification?.cvnTestTemp}F`;
      firstLine.push(str);
    }

    // Second line
    // Y6
    // @ts-ignore
    if (obj.headerSku?.grade) {
      secondLine.unshift(obj.headerSku?.grade);
    }
    // Y5
    if (obj.order?.mainInfo?.parentProcessingOrder) {
      secondLine.unshift(`${obj.order?.mainInfo?.parentProcessingOrder?.documentNumber}`);
    } else if (!obj.order?.mainInfo?.parentProcessingOrder && obj.order?.mainInfo?.documentNumber) {
      secondLine.unshift(`${obj.order?.mainInfo?.documentNumber}`);
    }
    // Y4
    if (
      (obj.form?.get('specification')?.value.driftId === DriftStandardEnum.Alternative ||
        specification?.driftId === DriftStandardEnum.Alternative) &&
      obj.skuSpecification?.altDrift !== null
    ) {
      secondLine.unshift(`DA${obj.skuSpecification?.altDrift}`);
    } else if (
      (obj.form?.get('specification')?.value.driftId !== DriftStandardEnum.Alternative ||
        specification?.driftId !== DriftStandardEnum.Alternative) &&
      obj.skuSpecification?.standardDriftOD
    ) {
      secondLine.unshift('D');
    }
    // Y3
    if (typeof obj.headerSku?.thread === 'string' && threadOptions.includes(obj.headerSku?.thread)) {
      secondLine.unshift(obj.headerSku?.thread);
    }
    // Y2
    if (obj.skuSpecification?.hydrotestPressure) {
      secondLine.unshift(`P${obj.skuSpecification?.hydrotestPressure}`);
    }
    // Y1
    if (obj.form?.get('specification')?.value.pslId === PslValuesEnum.L2 || specification?.pslId === PslValuesEnum.L2) {
      secondLine.unshift('L2');
    } else if (
      obj.form?.get('specification')?.value.pslId === PslValuesEnum.L3 ||
      specification?.pslId === PslValuesEnum.L3
    ) {
      secondLine.unshift('L3');
    }

    data.push(firstLine.join('  '));
    data.push(secondLine.join('  '));

    return data.join('\n');
  }

  if (obj.serviceType === serviceTypeEnum.Hydro) {
    const specification = obj?.spec as IProductionProcessingOrderSpecificationHydro;
    // X1
    data.push(obj.order?.mainInfo.documentNumber);
    data.push('HYDRO');

    // X2
    const testPressure = obj?.form?.value?.specification.testPressure || specification?.testPressure;
    if (testPressure) {
      data.push(`${testPressure}`);
    }

    data.push('PSI');
    data.push('YMM');

    return data.join('  ');
  }

  if (obj.serviceType === serviceTypeEnum.Threading) {
    const specification = obj?.spec as IProductionProcessingOrderSpecificationThreading;
    const firstLine = ['PANOPTIC'];
    const secondLine: string[] = [];
    enum driftTypeId {
      FLD = 1,
      FLDAlternative = 2,
      EndDrfit = 3,
      EndDrfitAlternative = 4
    }

    // X1
    switch (obj.headerSku?.thread) {
      case 'LC':
      case 'SC':
      case 'BC':
      case 'NU':
      case 'EU':
      case 'IJ':
        firstLine.push('API 5CT-1692 < >');
        firstLine.push('<<API MONO>>');
        break;
      case 'USS-CDC HTQ':
        firstLine.push('USS');
    }
    // MM-YY
    firstLine.push('MM-YY');
    // X3
    if (obj.headerSku?.size) {
      firstLine.push(`${obj.headerSku?.size.toFixed(3)}`);
    }
    // X4
    if (obj.headerSku?.thread) {
      firstLine.push(`${obj.headerSku?.thread}`);
    }

    // X5
    const driftId = obj.form?.get('specification')?.value?.driftTypeId ?? specification?.driftTypeId;

    if (driftId === driftTypeId.FLDAlternative && obj.skuSpecification?.altDrift) {
      firstLine.push(`DA ${obj.skuSpecification?.altDrift}`);
    } else if (driftId === driftTypeId.FLD) {
      firstLine.push('D');
    } else if (
      [driftTypeId.EndDrfitAlternative, driftTypeId.EndDrfit].includes(driftId) &&
      (obj.skuSpecification?.altDrift || obj.skuSpecification?.standardDriftOD)
    ) {
      firstLine.push(`End Drift ${obj.skuSpecification?.standardDriftOD || obj.skuSpecification?.altDrift}`);
    }

    // X-6
    if (obj.order?.mainInfo?.parentProcessingOrder) {
      firstLine.push(`${obj.order?.mainInfo?.parentProcessingOrder?.documentNumber}`);
    } else if (!obj.order?.mainInfo?.parentProcessingOrder && obj.order?.mainInfo?.documentNumber) {
      firstLine.push(`${obj.order?.mainInfo?.documentNumber}`);
    }

    data.push(firstLine.join('  '));

    return data.join('\n');
  }
}
