export enum NcpType {
  Rework = 1,
  Reject = 2
}

export enum NcpCategory {
  None = 0,
  Yard = 1,
  Upsetting = 2,
  HeatTreat = 3,
  Hydro = 4,
  Inspection = 5,
  Threading = 6,
  Protectors = 7,
  Couplings = 8,
  Ancillary = 9
}
