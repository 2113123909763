import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyNavList as MatNavList } from '@angular/material/legacy-list';
import { ActivatedRoute } from '@angular/router';

import {
  AutoCleanupFeature,
  ERPEmitService,
  ERPPDPRolesService,
  Features,
  IDestroyable,
  IPDPRoles,
  IWorkCentersList,
  groupBy
} from '@erp/shared';

@Component({
  selector: 'erp-tablet-sidenav-list',
  templateUrl: './tablet-sidenav-list.component.html',
  styleUrls: ['./tablet-sidenav-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPTabletSidenavListComponent implements OnInit, IDestroyable {
  @ViewChild(MatNavList, { read: ElementRef }) navList: ElementRef;

  destroyed$: Observable<unknown>;
  facilities: { [key: string]: IWorkCentersList[] };
  roles: IPDPRoles;

  get facilityNames(): string[] {
    return Object.getOwnPropertyNames(this.facilities);
  }

  constructor(
    private readonly route: ActivatedRoute,
    readonly pdpService: ERPPDPRolesService,
    readonly emitService: ERPEmitService
  ) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        takeUntil(this.destroyed$),
        map(({ workCenters, roles }) => ({ workCenters, roles }))
      )
      .subscribe(({ workCenters, roles }) => {
        this.facilities = groupBy(workCenters.data, 'facilityName');
        this.roles = roles;
      });
  }
}
