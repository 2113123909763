import { Dictionary } from '../../types';

type TMethod = <T>(...args: unknown[]) => T;

function BindContextDecorator<TContext>(staticContext?: TContext) {
  return <TProto extends object>(_target: TProto, property: PropertyKey, descriptor: PropertyDescriptor) => {
    if (descriptor.value) {
      let method = descriptor.value as TMethod;

      return {
        get<TInstance extends TProto & Dictionary>(this: TInstance) {
          const context = staticContext ?? this;
          const delegate = (...args: unknown[]) => method.apply(context, args);

          if (!this.hasOwnProperty(property)) {
            Object.defineProperties(this, {
              [property]: {
                get() {
                  return delegate;
                },
                set(fn: TMethod) {
                  method = fn;
                }
              }
            });
          }

          return delegate;
        },
        set(fn) {
          method = fn;
        }
      } as PropertyDescriptor;
    }
  };
}

export { BindContextDecorator as BindContext };
