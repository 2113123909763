import { Injectable } from '@angular/core';

import { DictionaryService } from '../services';

@Injectable({ providedIn: 'root' })
export class ERPMovementTabletStatusesResolver {
  constructor(readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.movementTabletStatuses;
  }
}
