import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ISelectOption } from '../select';

@Component({
  selector: 'erp-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPPaginatorComponent implements OnChanges {
  @Input() readonly page = 1;
  @Input() pageSize = 10;
  @Input() readonly total = 0;
  @Input() readonly showRecordsPerPage = false;
  @Output() readonly pageChange = new EventEmitter<number>();
  @Output() readonly recordsSizeChange = new EventEmitter<number>();
  private readonly offset = 2;
  readonly innerBtnsSize = 3;
  innerBtns: number[] = [];
  last: number;
  readonly options: ISelectOption[] = [
    { id: 100, value: '100' },
    { id: 200, value: '200' },
    { id: 500, value: '500' },
    { id: 1000, value: '1000' }
  ];
  readonly recordsPerPage = new UntypedFormControl(null);

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  private getPagesToShow() {
    this.recordsPerPage.setValue(this.pageSize);
    this.last = Math.ceil(this.total / this.pageSize);
    this.last = this.last > 1 ? this.last : 0;
    if (this.last < this.innerBtnsSize) {
      return (this.innerBtns = []);
    }

    let from = this.page - this.offset;
    if (this.last - this.page < this.innerBtnsSize) {
      from = this.last - this.innerBtnsSize - 1;
    }
    if (this.last - this.innerBtnsSize < 1 || from <= 1) {
      from = 0;
    }
    this.innerBtns = Array(this.last - this.offset)
      .fill(null)
      .map((x, i) => i + this.offset)
      .slice(from, from + this.innerBtnsSize);
  }

  changePageTo(page: number) {
    if (page === this.page || page < 1 || page > this.last) {
      return;
    }
    this.getPagesToShow();
    this.pageChange.emit(page);
  }

  updateRecordsPerPage(recordsSize: number) {
    this.pageSize = recordsSize;
    this.getPagesToShow();
    this.recordsSizeChange.emit(recordsSize);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getPagesToShow();
    this.changeDetectorRef.markForCheck();
  }
}
