<main class="animated fadeIn faster mat-typography">
  <router-outlet></router-outlet>
</main>
<footer class="mat-typography" *ngIf="!isTabletModule">
  <erp-footer></erp-footer>
</footer>

<erp-toaster-outlet type="root"></erp-toaster-outlet>

<ng-template>
  <ng-container i18n="@@app.router.title.main">Panoptic</ng-container>
  <ng-container i18n="@@app.router.title.inventory-management">Inventory Management</ng-container>
  <ng-container i18n="@@app.router.title.sales">Sales</ng-container>
  <ng-container i18n="@@app.router.title.logistic-warehousing">Logistics and Warehousing</ng-container>
  <ng-container i18n="@@app.router.title.production">Production</ng-container>
  <ng-container i18n="@@app.router.title.planing">Planning</ng-container>
  <ng-container i18n="@@app.router.title.procurement">Procurement</ng-container>
  <ng-container i18n="@@app.router.title.administration">Administration</ng-container>
  <ng-container i18n="@@app.router.title.quality-control">Quality Control</ng-container>
  <ng-container i18n="@@app.router.title.form-builder">Form Builder</ng-container>
  <ng-container i18n="@@app.router.title.notifications">Notifications</ng-container>
</ng-template>
