import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'erpDateOrTime'
})
export class ERPDateOrTimePipe implements PipeTransform {
  private yesterday: Date;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    this.yesterday = yesterday;
  }

  transform(value: Date | number | string): string | null {
    try {
      return new DatePipe(this.locale).transform(value, value < this.yesterday ? 'MM/dd/yyyy HH:mm' : 'HH:mm');
    } catch {
      return value as string | null;
    }
  }
}
