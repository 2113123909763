<div class="flex flex-column align-items-center padding-top-8">
  <h1 class="no-access__title" i18n="@@no-access.title">
    403
  </h1>
  <h3 class="no-access__message" i18n="@@no-access.message">
    Forbidden
  </h3>
  <div class="no-access__description margin-bottom-5" i18n="@@no-access.description">
    Your role has no access to the current page.
  </div>
  <erp-button [routerLink]="route" i18n="@@no-access.buttons.go-to-homepage">
    Go to the Home Page
  </erp-button>
</div>
