import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPConfirmModule } from '../../confirm';

import { ERPCloseLineDialogComponent } from './components';

@NgModule({
  declarations: [ERPCloseLineDialogComponent],
  imports: [CommonModule, ERPConfirmModule],
  exports: [ERPCloseLineDialogComponent]
})
export class ERPCloseDialogModule {}
