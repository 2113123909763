import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ERPButtonModule } from '../../button';
import { ERPIconModule } from '../../icon';

import { ERPDiscardCreationSalesOrderComponent } from './components';

@NgModule({
  imports: [CommonModule, ERPButtonModule, ERPIconModule, MatDialogModule],
  declarations: [ERPDiscardCreationSalesOrderComponent],
  exports: [ERPDiscardCreationSalesOrderComponent]
})
export class ERPDiscardCreationSalesOrderModule {}
