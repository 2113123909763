import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import {
  AutoCleanupFeature,
  BaseControlComponent,
  Features,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

@Component({
  selector: 'erp-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPSlideToggleComponent<T> extends BaseControlComponent<T> {
  readonly destroyed$: Observable<unknown>;
  @Input() readonly color: string;
  @Input() readonly labelPosition: string = 'before';
  readonly control = new UntypedFormControl(null);

  get checked() {
    return this.control.value as boolean;
  }
}
