<mat-toolbar class="flex align-items-center justify-content-space-between" [class.sidenav-opened]="sidenavOpened">
  <div *ngIf="sidenavOpened" class="sidenav-footer-text">
    <div class="user-name">{{ userName }}</div>
    <div *ngIf="sessionStart && isTabletModule" class="user-session">
      <ng-container>Session started at </ng-container>
      {{ sessionStart | erpTime }}
    </div>
  </div>
  <div class="sidenav-footer-buttons" [class.collapsed]="!sidenavOpened">
    <erp-icon
      *ngIf="isTabletModule && !sessionStart"
      (click)="onCheckIn()"
      icon="action:sign-in"
      size="fit"
      class="action-icon"
    ></erp-icon>
    <erp-icon
      *ngIf="isTabletModule && sessionStart"
      (click)="onClearSession()"
      icon="action:sign-out"
      size="fit"
      class="action-icon"
    ></erp-icon>
    <erp-notification-counter
      class="action-icon"
      [routerLink]="isTabletModule ? '/tablet/notifications' : '/notifications'"
      routerLinkActive="active"
      (click)="onNavigate()"
    ></erp-notification-counter>
    <erp-help class="action-icon" (click)="onNavigate()"></erp-help>
  </div>
</mat-toolbar>
