import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import {
  AutoCleanupFeature,
  BaseFormComponent,
  ERPFormStateDispatcher,
  Features,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

import { ERPTableOrderModalComponent } from '../order-modal';

@Component({
  selector: 'erp-table-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPTableOrderComponent implements OnInit {
  readonly destroyed$: Observable<unknown>;

  @Output() readonly orderChanged = new EventEmitter();
  columnNames: Map<string, { name: string; disabled: boolean }>;
  columns: string[];
  columnsOrder: string[];
  activeColumns: string[];
  disabledColumns: string[];
  requiredColumns: string[];
  hiddenColumns: string[] = [];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  async emit() {
    this.dialog
      .open(ERPTableOrderModalComponent, {
        data: {
          columnNames: this.columnNames,
          columnOrder: this.columnsOrder,
          disabledColumns: this.disabledColumns,
          hiddenColumns: this.hiddenColumns ?? [],
          columns: this.columns,
          activeColumns: this.activeColumns ?? [],
          requiredColumns: this.requiredColumns ?? []
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => this.orderChanged.emit(res));
  }
}
