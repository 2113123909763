import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';

@Injectable({
  providedIn: 'root'
})
export class ERPReleaseService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('releases');
  }

  getCustomerReleases(params: object) {
    return this.get<string[]>(params, 'releaseNumbers');
  }
}
