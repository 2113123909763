<section class="animated fadeIn">
  <div class="flex justify-content-center padding-vertical-5">
    <a class="logo" href="/">
      <erp-icon icon="logo:large" size="auto"></erp-icon>
    </a>
  </div>

  <div class="flex flex-column flex-grow-1">
    <div class="layout-grid padding-vertical-5">
      <section class="layout-grid grid-gap-d col-d-3-10 col-t-2-7 col-p-2-3">
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="logistic-and-warehousing"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__logistic-and-warehousing"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.logistic-and-warehousing">
              Logistics and Warehousing
            </ng-container>
          </div>
        </mat-card>
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="sales"
        >
          <!--        Disabled in scope of RTED-11531 -->
          <!--        <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__sales"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.sales">
              Sales
            </ng-container>
          </div>
        </mat-card>
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="procurement"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__procurement"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.procurement">
              Procurement
            </ng-container>
          </div>
        </mat-card>
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="production"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__production"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.production">
              Production
            </ng-container>
          </div>
        </mat-card>
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="planning"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__planning"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.planning">
              Planning
            </ng-container>
          </div>
        </mat-card>
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="inventory/setup/on-hand"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__inventory-management"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.inventory-management">
              Inventory on-hand
            </ng-container>
          </div>
        </mat-card>
        <mat-card class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center">
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__quality-control"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.quality-control">
              Quality Control
            </ng-container>
          </div>
        </mat-card>
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="administration"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__administration"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.administration">
              Administration
            </ng-container>
          </div>
        </mat-card>

        <!-- remove ngIf once module is implemented (KO) -->
        <mat-card
          *ngIf="false"
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="forms"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__form-builder"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.form-builder">
              Form Builder
            </ng-container>
          </div>
        </mat-card>
        <mat-card
          class="col-d-4 col-t-4 col-p-4 tile flex flex-column align-items-center justify-content-center"
          routerLink="tablet"
        >
          <!--          Disabled in scope of RTED-11531 -->
          <!--          <erp-routing-spinner></erp-routing-spinner>-->
          <div class="tile-icon tile-icon__form-builder"></div>
          <div class="tile-label mat-caption margin-top-4">
            <ng-container i18n="@@landing.tablet">
              Tablet
            </ng-container>
          </div>
        </mat-card>
      </section>
    </div>
  </div>
</section>
