import { Injectable } from '@angular/core';

import { IToaster, IToasterConfig } from '../interface';
import { ToasterData } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ERPToasterService implements IToaster {
  private container: IToaster;

  setDefaultContainer(container: IToaster) {
    this.container = container;
  }

  success(data: ToasterData, container?: IToaster | null, options?: Partial<IToasterConfig>): void {
    (container ?? this.container).success(data, null, options);
  }

  error(data: ToasterData, container?: IToaster | null, options?: Partial<IToasterConfig>): void {
    (container ?? this.container).error(data, null, options);
  }

  info(data: ToasterData, container?: IToaster | null, options?: Partial<IToasterConfig>): void {
    (container ?? this.container).info(data, null, options);
  }

  warn(data: ToasterData, container?: IToaster | null, options?: Partial<IToasterConfig>): void {
    (container ?? this.container).warn(data, null, options);
  }

  clearAll(container?: IToaster | null) {
    (container ?? this.container).clearAll();
  }
}
