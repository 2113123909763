import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

import { AutoCleanupFeature, BaseControlComponent, ERPFormStateDispatcher, Features } from '@erp/shared';

import { ISelectOption } from './select-option.interface';

@Component({
  selector: 'erp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature()])
export class ERPSelectComponent<T> extends BaseControlComponent<T> implements OnInit {
  readonly destroyed$: Observable<unknown>;
  @Input() readonly optional: boolean = true;
  @Input() readonly readonly: boolean = false;
  @Input() readonly options: ISelectOption[] = [];
  @Input() readonly placeholder: string;
  @Input() readonly defaultOption = '-';
  @Input() readonly openOnFocus = false;
  @Input() readonly isFocused: boolean;

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly submitted = new EventEmitter<T | null>();

  @ViewChild(MatSelect, { static: true }) readonly select: MatSelect;

  readonly control = new UntypedFormControl(null);

  @Input() readonly displayFn: (value: T, id: number) => void;
  @Input() readonly valueFn = (option: ISelectOption | null) => option?.id;
  @Input() readonly labelFn = (option: ISelectOption | null) => option?.value;
  @Input() readonly compareFn = (option: ISelectOption | string, selected: ISelectOption | number | null) => {
    if (selected === null) {
      return false;
    }

    if (typeof option === 'object' && typeof selected === 'object') {
      return option.id === selected.id;
    } else {
      return option === selected;
    }
  };

  constructor(
    readonly element: ElementRef<HTMLElement>,
    @Inject(NgControl) readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() @SkipSelf() readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  @HostListener('focus')
  focusHandler() {
    if (!this.select.panelOpen && this.openOnFocus) {
      this.select.open();
    }
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.patchSelectOpen();

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: T) => {
      this.changed.emit(this.viewToModelParser(value) as T);
    });

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
      this.submitted.emit(this.value);
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  trackByFn(option: ISelectOption<string>) {
    return option.id;
  }

  private patchSelectOpen() {
    const open = this.select.open;

    this.select.open = () => {
      if (!this.readonly) {
        open.call(this.select);
      }
    };
  }
}
