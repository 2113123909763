import { ValidatorFn, Validators } from '@angular/forms';

import { AlphanumWithSymbolsValidator } from './alphanum-with-symbols.validator';
import { AlphanumValidator } from './alphanum.validator';
import { CheckboxGroupRequiredValidator } from './checkbox-group-required.validator';
import { DateTimeRangeValidator } from './date-time-range.validator';
import { DecimalValidator } from './decimal.validator';
import { DifferenceNumberValidator } from './difference-number.validator';
import { EndsWithPeriodValidator } from './ends-with-period.validator';
import { GreaterThanOrEqualValidator } from './greater-than-or-equal.validator';
import { GreaterThanValidator } from './greater-than.validator';
import { HoursValidator } from './hours.validator';
import { IntegerValidator } from './integer.validator';
import { IsMatchValidator } from './ismatch.validator';
import { LessThanDynamicValidator } from './less-than-dynamic.validator';
import { LessThanMultipleDynamicValidator } from './less-than-multiple-dynamic.validator';
import { LessThanOrEqualValidator } from './less-than-or-equal.validator';
import { LessThanValidator } from './less-than.validator';
import { MaxFractionDigitsValidator } from './max-fraction-digits.validator';
import { MaxTimeValidator } from './max-time.validator';
import { MinLengthValidator } from './min-length.validator';
import { MinTimeValidator } from './min-time.validator';
import { MinutesValidator } from './minutes.validator';
import { NonZeroValidator } from './non-zero.validator';
import { NumberRangeValidator } from './number-range.validator';
import { RangeValidator } from './range.validator';
import { RequiredObjectValidator } from './required-object.validator';
import { SafeAmountValidator } from './safe-amount.validator';
import { SafeDecimalValidator } from './safe-decimal.validator';
import { SafeIntegerValidator } from './safe-integer.validator';
import { SpecialCharactersValidator } from './special-characters.validator';
import { TimeValidator } from './time.validator';
import { UniqueByValidator } from './unique-by.validator';

const CUSTOM_VALIDATORS = {
  decimal: DecimalValidator,
  integer: IntegerValidator,
  alphanum: AlphanumValidator,
  safeAmount: SafeAmountValidator,
  safeDecimal: SafeDecimalValidator,
  safeInteger: SafeIntegerValidator,
  endsWithPeriod: EndsWithPeriodValidator,
  specialChars: SpecialCharactersValidator,
  alphanumWithSymbols: AlphanumWithSymbolsValidator,
  isMatch: IsMatchValidator,
  range: RangeValidator,
  dateTimeRange: DateTimeRangeValidator,
  greaterThan: GreaterThanValidator,
  nonZero: NonZeroValidator,
  greaterThanOrEqual: GreaterThanOrEqualValidator,
  lessThan: LessThanValidator,
  lessThanDynamic: LessThanDynamicValidator,
  lessThanMultipleDynamic: LessThanMultipleDynamicValidator,
  lessThanOrEqual: LessThanOrEqualValidator,
  minLength: MinLengthValidator,
  maxFractionDigits: MaxFractionDigitsValidator,
  time: TimeValidator,
  numberRange: NumberRangeValidator,
  checkboxGroupRequired: CheckboxGroupRequiredValidator,
  requiredObject: RequiredObjectValidator,
  uniqueBy: UniqueByValidator,
  maxTime: MaxTimeValidator,
  minTime: MinTimeValidator,
  hours: HoursValidator,
  minutes: MinutesValidator,
  diffNumber: DifferenceNumberValidator
};

const COMPOSED_VALIDATORS = {
  nameOrId: Validators.compose([
    CUSTOM_VALIDATORS.alphanumWithSymbols,
    CUSTOM_VALIDATORS.specialChars,
    CUSTOM_VALIDATORS.endsWithPeriod
  ]) as ValidatorFn,
  integer: Validators.compose([CUSTOM_VALIDATORS.integer, CUSTOM_VALIDATORS.safeInteger]) as ValidatorFn,
  decimal: Validators.compose([CUSTOM_VALIDATORS.decimal, CUSTOM_VALIDATORS.safeDecimal]) as ValidatorFn,
  amount: Validators.compose([CUSTOM_VALIDATORS.decimal, CUSTOM_VALIDATORS.safeAmount]) as ValidatorFn
};

export { CUSTOM_VALIDATORS as CustomValidators, COMPOSED_VALIDATORS as ComposedValidators };
