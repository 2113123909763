import { DocumentTypeIds } from '../enums';

export interface IDocumentPageURL {
  id: number;
  uri: string;
}

export type IGoBackURL = {
  [key in DocumentTypeIds]?: string;
};

export const DESKTOP_DOCUMENT_PAGE_URLS: IDocumentPageURL[] = [
  {
    id: DocumentTypeIds.PurchaseOrder,
    uri: 'procurement/inventory-transactions/purchase-orders/{id}'
  },
  {
    id: DocumentTypeIds.SalesOrder,
    uri: 'sales/sales-orders/{id}'
  },
  {
    id: DocumentTypeIds.ProcessingOrder,
    uri: 'production/processing-order/{id}'
  },
  {
    id: DocumentTypeIds.ProcessingReceipt,
    uri: 'production/processing-order/{id}/consumption-log'
  },
  {
    id: DocumentTypeIds.ProcessingConsume,
    uri: 'production/processing-order/{id}/production-results'
  },
  {
    id: DocumentTypeIds.Movement,
    uri: 'inventory/setup/movements/{id}'
  },
  {
    id: DocumentTypeIds.Adjustment,
    uri: 'inventory/setup/inventory-adjustment/{id}'
  },
  {
    id: DocumentTypeIds.TransferOrder,
    uri: 'logistic-and-warehousing/transfer-orders/{id}'
  },
  {
    id: DocumentTypeIds.SalesQuote,
    uri: 'sales/sales-quotes/{id}'
  },
  {
    id: DocumentTypeIds.PurchaseRequisition,
    uri: 'procurement/inventory-transactions/purchase-requisitions/{id}'
  },
  {
    id: DocumentTypeIds.InboundDelivery,
    uri: 'procurement/inventory-transactions/inbound-deliveries/{id}'
  },
  {
    id: DocumentTypeIds.POReceipt,
    uri: 'procurement/inventory-transactions/receipts/{id}'
  },
  {
    id: DocumentTypeIds.SOIssue,
    uri: 'sales/goods-issues/{id}'
  },
  {
    id: DocumentTypeIds.OutboundDelivery,
    uri: ''
  },
  {
    id: DocumentTypeIds.UnloadingAuthorization,
    uri: 'logistic-and-warehousing/unloading-authorizations/{id}'
  },
  {
    id: DocumentTypeIds.LoadingAuthorization,
    uri: 'logistic-and-warehousing/loading-authorizations/{id}'
  },
  {
    id: DocumentTypeIds.MaterialRelease,
    uri: 'sales/material-release/{id}'
  }
];

export const TABLET_DOCUMENT_PAGE_URLS: IDocumentPageURL[] = [
  {
    id: DocumentTypeIds.Movement,
    uri: 'tablet/logistic-and-warehousing/movements/{id}/edit'
  },
  {
    id: DocumentTypeIds.UnloadingAuthorization,
    uri: 'tablet/logistic-and-warehousing/unloading-authorizations/{id}'
  },
  {
    id: DocumentTypeIds.LoadingAuthorization,
    uri: 'tablet/logistic-and-warehousing/loading-authorization/{id}'
  },
  {
    id: DocumentTypeIds.Segregation,
    uri: 'tablet/logistic-and-warehousing/segregation/{id}/notification'
  },
  {
    id: DocumentTypeIds.Ncp,
    uri: 'tablet/quality-control/yard-ncp/{id}/edit'
  },
  {
    id: DocumentTypeIds.iNcp,
    uri: 'tablet/quality-control/yard-ncp/{id}/edit'
  }
];

export const TABLET_GO_BACK_URLS: IGoBackURL = {
  [DocumentTypeIds.Segregation]: '/tablet/logistic-and-warehousing/segregation',
  [DocumentTypeIds.Movement]: '/tablet/logistic-and-warehousing/movements',
  [DocumentTypeIds.LoadingAuthorization]: '/tablet/logistic-and-warehousing/loading-authorization/',
  [DocumentTypeIds.UnloadingAuthorization]: '/tablet/logistic-and-warehousing/unloading-authorizations'
};

export const DESKTOP_GO_BACK_URLS: IGoBackURL = {};
