<ng-container [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'flat'">
    <button [type]="type" [color]="color" [disabled]="disabled" [ngStyle]="{ textAlign: textAlign }" mat-flat-button>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <button [type]="type" [color]="color" [disabled]="disabled" [ngStyle]="{ textAlign: textAlign }" mat-icon-button>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <button
      class="erp-link-button"
      [ngStyle]="{ textAlign: textAlign }"
      [type]="type"
      [color]="color"
      [disabled]="disabled"
      mat-button
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
