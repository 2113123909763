import * as moment from 'moment';

export class ERPDateUtil {
  static getTimeFromDate(date: Date | null): string | null {
    if (date instanceof Date) {
      let hours = date.getUTCHours().toString();
      hours = hours.length === 1 ? `0${hours}` : hours;
      let minutes = date.getMinutes().toString();
      minutes = minutes.length === 1 ? `0${minutes}` : minutes;

      return `${hours}:${minutes}`;
    }

    return null;
  }

  static getnoUTCTimeFromDate(date: Date | null): string | null {
    if (date instanceof Date) {
      let hours = date.getHours().toString();
      hours = hours.length === 1 ? `0${hours}` : hours;
      let minutes = date.getMinutes().toString();
      minutes = minutes.length === 1 ? `0${minutes}` : minutes;

      return `${hours}:${minutes}`;
    }

    return null;
  }

  static getDatePart(date: Date | null): Date | null {
    if (!date) {
      return null;
    }

    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  static getUTCDatePart(date: Date | null): Date | null {
    if (!date) {
      return null;
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  static isTimeValid(time: string): boolean {
    if (time) {
      const regex = /^(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]$/;

      return regex.test(time);
    }

    return false;
  }

  static addTime(date: Date | null, time: string | null): Date | null {
    const minutesPerHour = 60;
    const msPerMinute = 60000;
    const msPerHour = msPerMinute * minutesPerHour;
    const timePartsNumber = 2;

    const timeParts = (time?.split(':') as unknown) as number[];
    if (!date || !timeParts || timeParts?.length < timePartsNumber) {
      return date;
    }

    return new Date(+date + timeParts[0] * msPerHour + timeParts[1] * msPerMinute);
  }

  static combineDateAndTime(date: Date | null, time: string | null): Date | null {
    const [hour, minute] = time ? time.split(':').map(st => Number(st)) : [null, null];
    if (!date || hour === null || minute === null) {
      return null;
    }

    return moment
      .utc(date)
      .set({ hour, minute })
      .add(-ERPDateUtil.caluclateTimeZoneDiffInMinutes(), 'minutes')
      .toDate();
  }

  static fixDateForDatePicker(date: Date | null): Date | null {
    return date ? moment(date).add(this.caluclateTimeZoneDiffInMinutes(), 'minutes').toDate() : null;
  }

  static caluclateTimeZoneDiffInMinutes(): number {
    return new Date().getTimezoneOffset() * -1;
  }
}
