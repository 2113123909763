import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import {
  AutoCleanupFeature,
  BaseFormComponent,
  CustomValidators,
  ERPFormStateDispatcher,
  ERPLoadingAuthSharedService,
  ERPMaterialsService,
  ERPReleaseService,
  ERPStockService,
  ERPUnloadingAuthSharedService,
  Features,
  ILoadingAuthAvailableMaterialRelease,
  ILoadingAuthAvailableSalesOrder,
  ILoadingReport,
  ILoadingReportEnum,
  IMatchedCustomer,
  IMatchedCustomerWithAvailableDates,
  IMetadataFiltering,
  IStockMaterialQuantityReference,
  IUnloadingAuthAvailableInboundDelivery,
  IUnloadingAuthAvailablePurchaseOrder,
  LoadingReportType,
  TableQueryBuilder,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';
import { CustomerStatus } from '../../../../../../../sales/src/lib/modules/customers/enums/customer-status.enum';
import { ERPCustomerService } from '../../../../../../../sales/src/lib/modules/customers/services/customer.service';

const DEFAULT_GRACE_PERIOD = 2;
const MAX_GRACE_PERIOD = 300;

@Component({
  selector: 'erp-logistic-loading-report',
  templateUrl: './logistic-loading-report.component.html',
  styleUrls: ['./logistic-loading-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPLogisticLoadingReportComponent<T> extends BaseFormComponent<T> implements OnInit {
  readonly destroyed$: Observable<unknown>;
  readonly loadingReportEnum = ILoadingReportEnum;

  readonly form = new UntypedFormGroup(
    {
      customerId: new UntypedFormControl(null),
      customerName: new UntypedFormControl(null),
      salesOrderNumber: new UntypedFormControl(null),
      materialReleaseNumber: new UntypedFormControl(null),
      purchaseOrder: new UntypedFormControl(null),
      inboundDelivery: new UntypedFormControl(null),
      from: new UntypedFormControl(null),
      to: new UntypedFormControl(null),
      customerReleaseNumber: new UntypedFormControl(null),
      materialId: new UntypedFormControl(null),
      destination: new UntypedFormControl(null),
      gracePeriod: new UntypedFormControl(DEFAULT_GRACE_PERIOD, [
        Validators.required,
        CustomValidators.greaterThanOrEqual(0),
        CustomValidators.lessThanOrEqual(MAX_GRACE_PERIOD),
        CustomValidators.integer
      ]),
      pricePerTon: new UntypedFormControl(0, [CustomValidators.greaterThanOrEqual(0), CustomValidators.decimal])
    },
    [CustomValidators.range]
  );
  isShipping: boolean;

  minDate: Date;
  maxDate: Date;

  customerOptionsFn = (match1: string): Observable<IMatchedCustomer[]> => {
    return this.customerService.getMatchedCustomersByStatus({
      customerName: match1,
      statusId: CustomerStatus.Customer
    });
  };

  get isCustomerRequired(): boolean {
    return ['storage-report-ftz', 'storage-report', 'shipping-package'].includes(this.data.type);
  }

  customerReleaseOptionsFn = (searchString: string) => {
    const params = searchString ? { searchString } : {};

    return this.releaseService.getCustomerReleases(params);
  };

  materialsOptionsFn = (match1: string) => {
    const query = new TableQueryBuilder({})
      .withFiltering([
        {
          by: 'materialNumber',
          op: 'contains',
          match1
        }
      ])
      .serialize();

    return this.materialsService.getMaterials({ query }).pipe(map(res => res.data));
  };

  materialLabelFn = (val: IStockMaterialQuantityReference) => (val instanceof Object ? val.materialNumber : val);

  destinationOptionsFn = (searchString: string) => {
    const params = searchString ? { searchString } : {};

    return this.loadingAuthService.getDestinations(params);
  };

  salesOrderNumberOptionsFn = (searchValue: string) => {
    const query = {
      filtering: [{ by: 'salesOrderNumber', match1: searchValue, op: 'contains' }]
    };
    const { customerId } = this.form.value;

    if (customerId) {
      query.filtering.push({ by: 'customerId', match1: customerId, op: 'eq' });
    }

    return this.loadingAuthService
      .getAvailableSalesOrderList({ query: JSON.stringify(query) })
      .pipe(map(response => response.data));
  };

  salesOrderNumberDisplayFn = (value: ILoadingAuthAvailableSalesOrder | string) =>
    value instanceof Object ? value?.salesOrderNumber : value;
  salesOrderNumberValueFn = (value: ILoadingAuthAvailableSalesOrder) => value;
  salesOrderNumberLabelFn = (value: ILoadingAuthAvailableSalesOrder) =>
    value instanceof Object ? value?.salesOrderNumber : value;

  materialReleaseOptionsFn = (searchValue: string) => {
    const query = {
      filtering: [{ by: 'materialReleaseNumber', match1: searchValue, op: 'contains' }]
    };
    const { customerId } = this.form.value;

    if (customerId) {
      query.filtering.push({ by: 'customerId', match1: customerId, op: 'eq' });
    }

    return this.loadingAuthService
      .getAvailableMaterialReleaseList({ query: JSON.stringify(query) })
      .pipe(map(response => response.data));
  };

  materialReleaseDisplayFn = (value: ILoadingAuthAvailableMaterialRelease | string) =>
    value instanceof Object ? value?.materialReleaseNumber : value;
  materialReleaseValueFn = (value: ILoadingAuthAvailableMaterialRelease) => value;
  materialReleaseLabelFn = (value: ILoadingAuthAvailableMaterialRelease) =>
    value instanceof Object ? value?.materialReleaseNumber : value;

  purchaseOrderLabelFn = (value: IUnloadingAuthAvailablePurchaseOrder) =>
    value instanceof Object ? value?.purchaseOrderNumber : value;

  poOptionsFn = (match1: string) => {
    const query = {
      filtering: [{ by: 'purchaseOrderNumber', match1, op: 'contains' }]
    };
    const { customerId } = this.form.value;

    if (customerId) {
      query.filtering.push({ by: 'customerId', match1: customerId, op: 'eq' });
    }

    return this.unloadingAuthService
      .getAvailablePurchaseOrderList({ query: JSON.stringify(query) })
      .pipe(map(res => res.data));
  };

  inboundDeliveryLabelFn = (value: IUnloadingAuthAvailableInboundDelivery) =>
    value instanceof Object ? value?.inboundDeliveryNumber : value;

  inboundDeliveryOptionsFn = (match1: string) => {
    const query = {
      filtering: [] as IMetadataFiltering[]
    };
    const { customerId } = this.form.value;

    if (match1) {
      query.filtering.push({ by: 'inboundDeliveryNumber', match1, op: 'contains' });
    }

    if (customerId) {
      query.filtering.push({ by: 'customerId', match1: customerId, op: 'eq' });
    }

    return this.unloadingAuthService
      .getAvailableInboundDeliveriesList({ query: JSON.stringify(query) })
      .pipe(map(res => res.data));
  };

  customerLabelFn = (val: IMatchedCustomer) => (val instanceof Object ? val.customerName : val);

  constructor(
    readonly formState: ERPFormStateDispatcher,
    readonly dialogRef: MatDialogRef<ERPLogisticLoadingReportComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { type: LoadingReportType },
    readonly unloadingAuthService: ERPUnloadingAuthSharedService,
    readonly loadingAuthService: ERPLoadingAuthSharedService,
    readonly releaseService: ERPReleaseService,
    @Inject(LOCALE_ID)
    readonly currentLocale: string,
    readonly stockService: ERPStockService,
    readonly customerService: ERPCustomerService,
    readonly materialsService: ERPMaterialsService
  ) {
    super();
  }

  ngOnInit() {
    this.onAddValidators();
  }

  onCreate() {
    const { invalid, value } = this.form;
    let minDate;
    let maxDate;
    let formattedMinDate;
    let formattedMaxDate;

    if (invalid) {
      return;
    }

    this.formState.onSubmit.notify();

    if (value.from) {
      minDate = moment(value.from)?.startOf('day').toDate();
      formattedMinDate = formatDate(minDate, 'MM/dd/yyyy h:mm:ss a', this.currentLocale);
    }

    if (value.to) {
      maxDate = moment(value.to)?.endOf('day').toDate();
      formattedMaxDate = formatDate(maxDate, 'MM/dd/yyyy h:mm:ss a', this.currentLocale);
    }

    this.dialogRef.close({
      customerId: value?.customerId,
      minDateIn: formattedMinDate,
      maxDateIn: formattedMaxDate,
      POId: value.purchaseOrder?.purchaseOrderId ?? null,
      InboundDeliveryId: value.inboundDelivery?.inboundDeliveryId ?? null,
      SOId: value.salesOrderNumber?.salesOrderId ?? null,
      MRId: value.materialReleaseNumber?.materialReleaseId ?? null,
      customerReleaseNumber: value?.customerReleaseNumber ? [value?.customerReleaseNumber] : [],
      materialId: value?.materialId?.materialId ? [value?.materialId?.materialId] : [],
      destination: value?.destination ? [value?.destination] : [],
      gracePeriod: value?.gracePeriod ?? null,
      pricePerTon: value?.pricePerTon ?? null,
      customerName: value?.customerName ?? null
    } as ILoadingReport);
  }

  onAddValidators() {
    if (this.isCustomerRequired) {
      this.form.get('customerName')?.addValidators([Validators.required]);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onCustomerNameChanged(match: IMatchedCustomerWithAvailableDates) {
    this.form.patchValue({
      customerId: match?.customerId ?? null,
      customerName: match?.customerName ?? null,
      salesOrderNumber: null,
      materialReleaseNumber: null,
      purchaseOrder: null,
      inboundDelivery: null
    });
  }

  onCustomerChanged(match: IMatchedCustomer) {
    this.form.patchValue({
      customerId: match?.id ?? null,
      customerName: match?.customerName ?? null,
      customerReleaseNumber: null,
      materialId: null,
      destination: null
    });
  }

  onSalesOrderChange(value: ILoadingAuthAvailableSalesOrder): void {
    this.form.patchValue({
      customerName: value?.customerName,
      customerId: value?.customerId,
      materialReleaseNumber: null
    });
  }

  onPurchaseOrderChange(value: IUnloadingAuthAvailablePurchaseOrder): void {
    this.form.patchValue({
      customerName: value?.customerName,
      customerId: value?.customerId,
      inboundDelivery: null
    });
  }

  onInboundDeliveryChange(value: IUnloadingAuthAvailableInboundDelivery): void {
    this.form.patchValue({
      customerName: value?.customerName,
      customerId: value?.customerId,
      purchaseOrder: null
    });
  }

  onMaterialReleaseChange(value: ILoadingAuthAvailableMaterialRelease): void {
    this.form.patchValue({
      customerName: value?.customerName,
      customerId: value?.customerId,
      salesOrderNumber: null
    });
  }
}
