import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';
import { ERPLabelModule } from '../label';
import { ERPTrimModule } from '../trim';

import { ERPMaterialDetailsComponent } from './components';

@NgModule({
  declarations: [ERPMaterialDetailsComponent],
  imports: [CommonModule, ERPButtonModule, ERPTrimModule, ERPLabelModule, ERPIconModule],
  exports: [ERPMaterialDetailsComponent]
})
export class ERPMaterialDetailsModule {}
