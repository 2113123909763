import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';

import { INcpDispositionResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPNcpDispositionsFactory {
  toRequest(dispositions?: ISelectOption[], showDisposition = true) {
    return showDisposition
      ? dispositions?.sort(this.orderNumberCompareFn).map((i, index) => this.toDispositionRequest(i, index)) ?? []
      : [];
  }

  fromResponse(dispositions?: INcpDispositionResponse[]) {
    return dispositions?.map(this.fromDispositionRequest) ?? [];
  }

  private orderNumberCompareFn(a: ISelectOption, b: ISelectOption): number {
    return (a.orderNumber ?? 0) - (b.orderNumber ?? 0);
  }

  private toDispositionRequest(item: ISelectOption, index: number): INcpDispositionResponse {
    return {
      dispositionId: item?.id,
      orderNumber: index,
      dispositionName: item?.value ?? ''
    };
  }

  private fromDispositionRequest(disposition: INcpDispositionResponse): ISelectOption {
    return {
      id: disposition?.dispositionId,
      value: disposition?.dispositionName,
      orderNumber: disposition.orderNumber
    };
  }
}
