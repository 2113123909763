export class ERPNumberUtil {
  static isValidInteger<R extends string>(value: R | null) {
    const allowChars = /^[-\+]?[0-9]*$/;

    return allowChars.test(value as R);
  }

  static isValidDecimal<R extends string>(value: R | null) {
    const allowChars = /^([-\+]?[0-9]*[.]?[0-9]{0,6})?$/;

    return allowChars.test(value as R);
  }

  static isValidAmount<R extends string>(value: R | null) {
    const allowChars = /^([-\+]?[0-9]*[.]?[0-9]{0,2})?$/;

    return allowChars.test(value as R);
  }
}
