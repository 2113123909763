import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ERPLogisticReportsService, ERPPDPRolesService, IPDPRoles } from '@erp/shared';

import { ERPLogisticLoadingReportComponent } from '../logistic-loading-report/logistic-loading-report.component';
import { ERPOnHandReportComponent } from '../on-hand-report';

@Component({
  selector: 'erp-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPSidenavListComponent {
  @Input() roles: IPDPRoles;

  readonly prefix = 'side-menu';

  constructor(
    private readonly logisticReportService: ERPLogisticReportsService,
    readonly pdpService: ERPPDPRolesService
  ) {}

  onShippingReport() {
    this.logisticReportService.onShowShippingReport(ERPLogisticLoadingReportComponent);
  }

  onReceivingReport() {
    this.logisticReportService.onShowReceivingReport(ERPLogisticLoadingReportComponent);
  }

  onOnHandReport() {
    this.logisticReportService.onShowOnHandReport(ERPOnHandReportComponent);
  }

  onShippingPackageReport() {
    this.logisticReportService.onShowShippingPackageReport(ERPLogisticLoadingReportComponent);
  }

  onStorageReport() {
    this.logisticReportService.onShowStorageReport(ERPLogisticLoadingReportComponent);
  }

  onStorageReportFTZ() {
    this.logisticReportService.onShowStorageReportFTZ(ERPLogisticLoadingReportComponent);
  }
}
