import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ERPToasterService } from '@erp/components';
import { ERPNcpDictionaryService, INcpDefect, NcpCategory, NcpType } from '@erp/shared';

@Injectable({ providedIn: 'root' })
export class ERPTabletNcpDefectsResolver {
  constructor(
    private readonly dictionaryService: ERPNcpDictionaryService,
    private readonly toastService: ERPToasterService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const ncp = route.parent?.data.ncp;

    return this.dictionaryService.getNcpDefects(NcpCategory.Yard, ncp?.ncpType ?? NcpType.Rework).pipe(
      catchError(() => {
        this.toastService.error('error.default');

        return of([]);
      })
    );
  }
}
