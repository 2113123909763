<div class="flex flex-column align-items-center padding-top-8">
  <h1 class="not-found__title" i18n="@@not-found.title">
    404
  </h1>
  <h3 class="not-found__message" i18n="@@not-found.message">
    Page not found
  </h3>
  <div class="not-found__description margin-bottom-5" i18n="@@not-found.description">
    The page you are looking for is not avaible.
  </div>
  <erp-button routerLink="/" i18n="@@not-found.buttons.go-to-homepage">
    Go to the Home Page
  </erp-button>
</div>
