import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { IdName } from '../types';

export function IsMatchValidator(options: IdName[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const isMatch = options.filter((element: IdName) => element.name === value).length > 0;

    return isMatch ? null : { isMatch: true };
  };
}
