import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export const erpDiscardChangesStateEnums = {
  Confirm: 'confirm',
  Cancel: 'cancel'
};

export interface IDiscardChangesState {
  state: string;
}

@Component({
  selector: 'erp-discard-changes-state-dialog',
  templateUrl: './discard-changes-state-dialog.component.html',
  styleUrls: ['./discard-changes-state-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPDiscardChangesStateDialogComponent {
  constructor(readonly dialogRef: MatDialogRef<ERPDiscardChangesStateDialogComponent>) {}

  onConfirm() {
    this.dialogRef.close({ state: erpDiscardChangesStateEnums.Confirm } as IDiscardChangesState);
  }

  onCancel() {
    this.dialogRef.close({ state: erpDiscardChangesStateEnums.Cancel } as IDiscardChangesState);
  }
}
