import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { ERPSharedModule } from '../../../../../shared/src/lib';

import { ERPInputComponent } from './input.component';

@NgModule({
  declarations: [ERPInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ERPSharedModule
  ],
  exports: [ReactiveFormsModule, MatFormFieldModule, ERPInputComponent]
})
export class ERPInputModule {}
