import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ERPIconModule } from '../icon';

import {
  ERPExpansionPanelBodyComponent,
  ERPExpansionPanelComponent,
  ERPExpansionPanelHeaderComponent
} from './components';
import { ERPExpansionPanelSubHeaderComponent } from './components/expansion-panel-sub-header';

@NgModule({
  declarations: [
    ERPExpansionPanelComponent,
    ERPExpansionPanelBodyComponent,
    ERPExpansionPanelHeaderComponent,
    ERPExpansionPanelSubHeaderComponent
  ],
  imports: [CommonModule, MatToolbarModule, MatDividerModule, MatCardModule, MatExpansionModule, ERPIconModule],
  exports: [
    ERPExpansionPanelComponent,
    ERPExpansionPanelBodyComponent,
    ERPExpansionPanelHeaderComponent,
    ERPExpansionPanelSubHeaderComponent
  ]
})
export class ERPExpansionPanelModule {}
