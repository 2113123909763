// This is data for generate PDP roles ids in format (to be unique):
// <prefix>:<resource>, prefix - location pathname, resource - if of HTML element
import { IPDPResourcesData } from '../interfaces';

import { getDesktopPDPResources, getTabletPDPResources } from './pdp-module-resources.constant';

export const PDP_RESOURCES: IPDPResourcesData = {
  'tablet-movement-list': {
    prefix: 'tablet/logistic-and-warehousing/movements',
    resources: ['fl-operator-input', 'view-all-checkbox', 'confirm-permission', 'create-permission', 'take-button']
  },
  'tablet-movement-edit': {
    prefix: 'tablet/logistic-and-warehousing/movements/{id}/edit',
    resources: [
      'fl-operator-input',
      'moved-qty-input',
      'requested-qty-input',
      'requested-qty-input-owner',
      'create-permission',
      'confirm-button'
    ]
  },
  'tablet-movement-report': {
    prefix: 'tablet/logistic-and-warehousing/movements/{id}/edit',
    resources: ['finish-redirect']
  },
  'tablet-segregation-edit': {
    prefix: 'tablet/logistic-and-warehousing/segregation/{id}/edit',
    resources: ['create-permission', 'confirm-button', 'location-from-update']
  },
  'tablet-round-thread-list': {
    prefix: 'tablet/production/processing-order/{id}/service/{serviceId}/production-forms/round-thread',
    resources: ['edit-item', 'view']
  },
  'tablet-buck-on-list': {
    prefix: 'tablet/production/processing-order/{id}/service/{serviceId}/production-forms/buck-on',
    resources: ['edit-item', 'view']
  },
  inventory: {
    prefix: 'inventory',
    resources: ['create-new-value']
  },
  'tablet-yard-ncp-edit': {
    prefix: 'tablet/quality-control/yard-ncp/{id}/edit',
    resources: ['confirm-button', 'add-ncp-button']
  },
  'inventory-adjustment': {
    prefix: 'inventory/setup/inventory-adjustment/{id}',
    resources: ['confirm-button']
  },
  'inventory-adjustment-edit': {
    prefix: 'inventory/setup/inventory-adjustment/{id}/edit',
    resources: ['delete-button']
  },
  'tablet-side-menu': {
    prefix: 'tablet-side-menu',
    resources: [...getTabletPDPResources()]
  },
  'tablet-production': {
    prefix: 'tablet/production',
    resources: ['update-permission']
  },
  'tablet-unloading-confirmation': {
    prefix: 'tablet/logistic-and-warehousing/unloading-authorizations/{id}/unloading-confirmation',
    resources: ['confirm-button', 'complete-button']
  },
  'tablet-loading-confirmation': {
    prefix: 'tablet/logistic-and-warehousing/loading-authorization/{id}',
    resources: ['confirm-button', 'complete-button']
  },
  'tablet-segregation-confirmation': {
    prefix: 'tablet/logistic-and-warehousing/segregation/{id}/edit',
    resources: ['confirm-button']
  },
  'tablet-yard-ncp-create': {
    prefix: 'tablet/quality-control/yard-ncp',
    resources: ['add-ncp-button']
  },
  'side-menu': {
    prefix: 'side-menu',
    resources: [...getDesktopPDPResources()]
  },
  'inventory-maintenance': {
    prefix: 'inventory/product-types',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'create-new-value']
  },
  security: {
    prefix: 'administration/user-roles',
    resources: ['create-permission', 'delete-permission']
  },
  receipts: {
    prefix: 'procurement/inventory-transactions/receipts',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  'inbound-deliveries': {
    prefix: 'procurement/inventory-transactions/inbound-deliveries',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  'goods-issues': {
    prefix: 'sales/goods-issues',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  contacts: {
    prefix: 'sales/contacts',
    resources: ['create-permission', 'update-permission', 'delete-permission']
  },
  'material-release': {
    prefix: 'sales/material-release',
    resources: ['create-permission', 'update-permission', 'delete-permission']
  },
  'transportation-companies': {
    prefix: 'logistic-and-warehousing/transportation-companies',
    resources: ['create-permission', 'update-permission', 'delete-permission']
  },
  'inventory-adjustment-screen': {
    prefix: 'inventory/setup/inventory-adjustment',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  'transfer-orders': {
    prefix: 'logistic-and-warehousing/transfer-orders',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  movements: {
    prefix: 'inventory/setup/movements',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  'loading-authorizations': {
    prefix: 'logistic-and-warehousing/loading-authorizations',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  'unloading-authorizations': {
    prefix: 'logistic-and-warehousing/unloading-authorizations',
    resources: ['create-permission', 'update-permission', 'delete-permission', 'confirm-permission']
  },
  'tablet-process-order': {
    prefix: 'tablet/production/processing-order',
    resources: [
      'prod-results-view',
      'prod-results-report',
      'prod-results-action-view',
      'prod-results-action-generate-report',
      'prod-forms-pipe-tallies',
      'prod-forms-vg-enter-sn',
      'prod-forms-vg-add-joints',
      'prod-forms-vg-dev-param',
      'prod-forms-vg-dev-inspector-link',
      'prod-forms-cut-off',
      'prod-forms-round-thread-inspection',
      'prod-forms-buck-on-enter-sn',
      'prod-forms-buck-on-classify',
      'prod-forms-buck-on-dev-param',
      'quality-control-view',
      'quality-control-report',
      'quality-control-defect-disp-view',
      'quality-control-defect-disp-qa-approve',
      'quality-control-defect-disp-po-approve',
      'quality-control-defect-disp-reclassify',
      'quality-control-defect-disp-close',
      'laboratory',
      'consumption-report',
      'consumption-add-new-record',
      'consumption-generate-report',
      'consumption-view',
      'entry-operation-view',
      'entry-operation-report',
      'entry-operation-add-new-record',
      'entry-operation-generate-report',
      'entry-operation-view-line',
      'inventory-details-view',
      'inventory-details-report',
      'inventory-details-wip',
      'reject-materials-report',
      'downtime-view',
      'downtime-report-resume',
      'downtime-new-downtime-report',
      'downtime-view-line',
      'downtime-resume',
      'production-time-view',
      'production-time-report',
      'production-time-new-prod-time-report',
      'production-time-update-prod-time',
      'production-time-update-view-line',
      'ancillary-services-view',
      'ancillary-services-add-record',
      'ancillary-services-view-line',
      'line-mode',
      'start-pause-finish-btn'
    ]
  },
  'sales-orders': {
    prefix: 'sales/sales-orders',
    resources: ['confirm-permission']
  },
  'sales-quotes': {
    prefix: 'sales/sales-quotes',
    resources: ['confirm-permission']
  },
  customers: {
    prefix: 'sales/customers',
    resources: ['create-permission', 'update-permission', 'delete-permission']
  },
  'purchase-requisitions': {
    prefix: 'procurement/inventory-transactions/purchase-requisitions',
    resources: ['confirm-permission']
  },
  'purchase-orders': {
    prefix: 'procurement/inventory-transactions/purchase-orders',
    resources: ['confirm-permission']
  },
  'processing-order': {
    prefix: 'production/processing-order',
    resources: ['update-permission', 'confirm-permission', 'view-permission']
  },
  services: {
    prefix: 'production/services',
    resources: ['create-permission']
  },
  'work-centers': {
    prefix: 'production/work-centers',
    resources: ['create-permission', 'update-permission', 'delete-permission']
  },
  planning: {
    prefix: 'planning/planning',
    resources: ['confirm-permission']
  },
  'production-scheduling': {
    prefix: 'planning/production-scheduling',
    resources: ['update-permission']
  }
};
