export enum TubeProperties {
  Grade = 'Grade',
  Design = 'Design',
  Range = 'Range',
  MillName = 'MillName',
  OD = 'OD',
  Connection = 'Connection',
  Condition = 'Condition',
  MaterialType = 'MaterialType',
  Wall = 'Wall',
  EndType = 'EndType',
  ProtectorType = 'ProtectorType',
  WeightPerFoot = 'WeightPerFoot'
}
