import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import { IManufacturer, IManufacturerListResponse, IManufacturerResponse, IPatchRequest } from '../interfaces';

import { ERPManufacturerFactory } from './manufacturer.factory';

@Injectable({
  providedIn: 'root'
})
export class ERPManufacturerService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient, readonly manufacturerFactory: ERPManufacturerFactory) {
    super('manufacturers');
  }

  getManufacturers(params: object) {
    return this.get<ICollectionResponse<IManufacturerListResponse>>(params);
  }

  getManufacturerReferences(searchString: string) {
    return this.get<IManufacturerListResponse[]>({ searchString }, 'reference');
  }

  getMatchedManufacturersNames(params: object) {
    return this.get<ICollectionResponse<string>>(params, 'names');
  }

  getManufacturer(id: string) {
    return this.get<IManufacturerResponse>({}, id).pipe(map(res => this.manufacturerFactory.fromResponse(res)));
  }

  createManufacturer(product: IManufacturer) {
    const request = this.manufacturerFactory.toRequest(product);

    return this.post<IManufacturerResponse>(request);
  }

  saveManufacturer(product: IManufacturer) {
    const request = this.manufacturerFactory.toRequest(product);

    return this.put<IManufacturerResponse>(request, {}, request.id as string);
  }

  updateManufacturer(status: IPatchRequest<IManufacturer>[], id: string) {
    return this.patch(status, {}, id);
  }

  deleteManufacturer(id: string) {
    return this.delete({}, id);
  }
}
