import { EventEmitter, Injectable } from '@angular/core';

import { INotification } from '..';

@Injectable({
  providedIn: 'root'
})
export class ERPNotificationEventsService {
  private _unreadCount = 0;

  get unreadCount() {
    return this._unreadCount;
  }

  readonly countChangedEvent = new EventEmitter<number>();
  readonly newNotificationEvent = new EventEmitter<INotification>();

  updateUnreadCount(newCount: number) {
    this._unreadCount = newCount;
    this.countChangedEvent.emit(newCount);
  }

  addNotification(notification: INotification) {
    this._unreadCount++;
    this.countChangedEvent.emit(this._unreadCount);
    this.newNotificationEvent.emit(notification);
  }
}
