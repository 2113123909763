import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { ERPCurrencyComponent } from './components';

@NgModule({
  declarations: [ERPCurrencyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatInputModule,
    MatFormFieldModule
  ],
  exports: [ReactiveFormsModule, MatFormFieldModule, ERPCurrencyComponent]
})
export class ERPCurrencyModule {}
