<div class="attachment">
  <div class="attachment-file-icon flex align-items-center">
    <erp-icon variant="large" [icon]="icon" size="fit"></erp-icon>
  </div>
  <div class="attachment-actions">
    <erp-button
      *ngIf="!isLoadingType && !withLabels"
      variant="icon"
      color="primary"
      class="margin-right-1"
      (click)="onDownload()"
    >
      <erp-icon icon="action:download"></erp-icon>
    </erp-button>
    <erp-button
      *ngIf="!isLoadingType && !withLabels"
      variant="icon"
      color="warn"
      class="margin-right-1"
      (click)="onRemove()"
    >
      <erp-icon icon="action:trash"></erp-icon>
    </erp-button>
    <erp-button *ngIf="isLoadingType" variant="icon" color="primary" (click)="onInterrupt()">
      <erp-icon variant="large" icon="action:cross-circle"></erp-icon>
    </erp-button>
  </div>

  <div
    class="attachment-info flex flex-column"
    [class]="withLabels ? 'justify-content-space-around' : 'justify-content-center'"
  >
    <div
      class="attachment-info__header mat-body-strong"
      [erpTooltip]="attachment?.fileName"
      [erpTooltipDisabled]="!trimmedFileName"
    >
      {{ trimmedFileName || attachment?.fileName }}
    </div>
    <div *ngIf="!isLoadingType" class="flex justify-content-space-between align-items-center">
      <div class="mat-caption attachment-info__subheader">
        <ng-container i18n="@@common.attachment.upload-date">
          Loaded date:
        </ng-container>
        <ng-container>
          {{ attachment?.loadedDate | date: 'longDate' }}
        </ng-container>
      </div>
      <div *ngIf="withLabels">
        <erp-button *ngIf="!isLoadingType" variant="link" color="primary" class="margin-right-1" (click)="onDownload()">
          <erp-icon icon="action:download"></erp-icon>
          <ng-container i18n="@@common.attachment.download-btn">
            Download
          </ng-container>
        </erp-button>
        <erp-button
          dense
          *ngIf="!isLoadingType"
          variant="link"
          color="warn"
          class="margin-right-1"
          (click)="onRemove()"
        >
          <erp-icon icon="action:trash"></erp-icon>
          <ng-container i18n="@@common.attachment.delete-btn">
            Delete
          </ng-container>
        </erp-button>
      </div>
    </div>

    <mat-progress-bar
      *ngIf="isLoadingType"
      [color]="errorLoading ? 'warn' : 'primary'"
      mode="determinate"
      [value]="errorLoading ? 100 : loadingProgress"
    >
    </mat-progress-bar>
    <div *ngIf="isLoadingType">
      <ng-container *ngIf="!errorLoading">
        <ng-container i18n="@@common.attachment.loaded">Loaded:</ng-container>
        {{ loadingProgress }} %
      </ng-container>
      <span *ngIf="errorLoading" class="mat-error" i18n="@@common.attachment.error-loading">
        Error loading
      </span>
    </div>
  </div>
</div>
<mat-divider *ngIf="underline"></mat-divider>
