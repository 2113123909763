<erp-confirm
  i18n-message="@@common.dialogs.expired-session.message"
  message="Do you want to start a new session to continue?"
  i18n-cancel="@@common.buttons.cancel"
  cancel="Cancel"
  i18n-confirm="@@common.buttons.yes"
  confirm="Yes"
  color="accent"
>
  <div class="flex" header>
    <erp-icon class="margin-right-2" [icon]="'action:warning-second'" [color]="'warn'"></erp-icon>
    <ng-container i18n="@@common.dialogs.expired-session.header">
      Session has been expired
    </ng-container>
  </div>
</erp-confirm>
