import { Observable, Subject } from 'rxjs';

import { OnDestroy } from '@angular/core';

import { Destroyable } from '../../abstracts';

import { IWidget } from './widget-component.interface';

const available = Symbol('available');

export abstract class Widget extends Destroyable implements IWidget, OnDestroy {
  readonly [available] = new Subject<boolean>();
  readonly available$ = this[available].asObservable();

  protected abstract readonly restrict: number[];
}
