import { BehaviorSubject, Observable, Subject, combineLatest, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListRange } from '@angular/cdk/collections';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';

export class TableVirtualScrollDataSource<T> extends MatTableDataSource<T> {
  renderedRangeStream: Subject<ListRange>;

  _updateChangeSubscription() {
    this.initRenderedRangeStream();
    // @ts-ignore
    const _sort: MatSort | null = this._sort;
    // @ts-ignore
    const _paginator: MatPaginator | null = this._paginator;
    // @ts-ignore
    const _internalPageChanges: Subject<void> = this._internalPageChanges;
    // @ts-ignore
    const _filter: BehaviorSubject<string> = this._filter;
    // @ts-ignore
    const _renderData: BehaviorSubject<T[]> = this._renderData;

    const sortChange: Observable<Sort | null | void> = _sort ? merge(_sort.sortChange, _sort.initialized) : of(null);
    const pageChange: Observable<PageEvent | null | void> = _paginator
      ? merge(_paginator.page, _internalPageChanges, _paginator.initialized)
      : of(null);
    // @ts-ignore
    const dataStream: Observable<T[]> = this._data;
    const filteredData = combineLatest([dataStream, _filter]).pipe(map(([data]) => this._filterData(data)));
    const orderedData = combineLatest([filteredData, sortChange]).pipe(map(([data]) => this._orderData(data)));
    const paginatedData = combineLatest([orderedData, pageChange]).pipe(map(([data]) => this._pageData(data)));

    const sliced = combineLatest([paginatedData, this.renderedRangeStream.asObservable()]).pipe(
      map(([data, { start, end }]) => data.slice(start, end))
    );

    this._renderChangesSubscription?.unsubscribe();
    this._renderChangesSubscription = sliced.subscribe(data => _renderData.next(data));
  }

  private initRenderedRangeStream() {
    if (!this.renderedRangeStream) {
      this.renderedRangeStream = new Subject<ListRange>();
    }
  }
}
