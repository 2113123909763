import { AbstractControl } from '@angular/forms';

import { QueryOperation } from '../abstracts';

export function DifferenceNumberValidator(
  comparator: AbstractControl | number,
  diffNumber: QueryOperation,
  format?: string
) {
  return (control: AbstractControl) => {
    const actualValue = control.value;
    const compareValue = comparator instanceof AbstractControl ? comparator.value : comparator;
    let valid = true;
    let diffValue = '';

    if (typeof actualValue !== 'number') {
      return null;
    }

    if (diffNumber === 'gte' && actualValue < compareValue) {
      valid = false;
      diffValue = `-${setFormat(compareValue - actualValue, format)}`;
    }

    if (diffNumber === 'lte' && actualValue > compareValue) {
      valid = false;
      diffValue = `+${setFormat(actualValue - compareValue, format)}`;
    }

    return valid ? null : { diffNumber: diffValue };
  };
}

function setFormat(number: number, format?: string): string | number {
  if (format) {
    const fixedDigits = format.split('.')[1]?.length ?? 0;

    return Number(number).toFixed(fixedDigits) ?? null;
  }

  return number;
}
