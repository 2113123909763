import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import { NgControl, UntypedFormControl, Validators } from '@angular/forms';

import { AutoCleanupFeature, BaseControlComponent, ERPFormStateDispatcher, Features } from '@erp/shared';

const DEFAULT_ROWS = 5;
const MAX_LONG_FIELD = 250;

@Component({
  selector: 'erp-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature()])
export class ERPTextareaComponent<T> extends BaseControlComponent<string> implements OnInit {
  readonly destroyed$: Observable<unknown>;
  @Input() readonly placeholder: string;
  @Input() readonly rows = DEFAULT_ROWS;
  @Input() readonly trimValue = true;
  @Input() readonly isFocused: boolean;
  @Input() readonly maxlength: number = MAX_LONG_FIELD;
  readonly control = new UntypedFormControl(null);
  readonly defaultValidator = Validators.maxLength(MAX_LONG_FIELD);

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.setValidators();
    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  private setValidators() {
    const validators = this.ctrl.control?.validator ? this.ctrl.control.validator : this.defaultValidator;
    const asyncValidators = this.ctrl.control?.asyncValidator ?? null;

    this.control.setValidators(validators);
    this.control.setAsyncValidators(asyncValidators);

    this.ctrl.control?.setValidators(validators);
    this.ctrl.control?.setAsyncValidators(asyncValidators);

    this.onValidatorChange?.();
  }

  onFocus() {
    this.onTouched?.();
  }

  onBlur() {
    const value = this.control.value as string | null;
    const trimmedValue = value ? value.trim().replace(/\s{2,}/gm, ' ') : value;

    if (trimmedValue !== value && this.trimValue) {
      this.control.setValue(trimmedValue);
    }
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);

    this.changeDetector.markForCheck();
  }
}
