<mat-card class="mat-elevation-z-0">
  <mat-expansion-panel
    (opened)="onOpen()"
    (closed)="onClose()"
    [expanded]="expanded"
    [@.disabled]="expandDirection === 'up'"
    hideToggle
  >
    <!-- Panel header -->
    <mat-expansion-panel-header>
      <ng-container *ngIf="!expanded">
        <mat-panel-title>
          <ng-content select="erp-expansion-panel-header"></ng-content>
          <!-- <erp-icon
            class="flex align-items-center fadeIn"
            [icon]="expanded ? 'action:chevron-up' : 'action:chevron-down'"
            color="primary"
          ></erp-icon> -->
        </mat-panel-title>
      </ng-container>

      <ng-container *ngIf="expandDirection === 'up'">
        <ng-container *ngIf="!expanded">
          <!-- <div class="margin-right-4 flex-grow-1">
            <ng-content select="erp-expansion-panel-header"></ng-content>
          </div> -->
          <div class="flex justify-content-center show-btn">
            <a i18n="@@common.expansion-panel.label.show">Show</a>
            <erp-icon icon="action:chevron-up" color="primary"></erp-icon>
          </div>
        </ng-container>
        <ng-container *ngIf="expanded">
          <div class="flex justify-content-center align-items-center hide-btn">
            <a i18n="@@common.expansion-panel.label.hide">Hide</a>
            <erp-icon icon="action:chevron-down" color="primary"></erp-icon>
          </div>
        </ng-container>
      </ng-container>
    </mat-expansion-panel-header>

    <!-- Panel content -->
    <ng-content select="erp-expansion-panel-body"></ng-content>
  </mat-expansion-panel>
</mat-card>
