import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

import { AutoCleanupFeature, BaseControlComponent, ERPFormStateDispatcher, Features } from '@erp/shared';

@Component({
  selector: 'erp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPCheckboxComponent<T extends boolean> extends BaseControlComponent<T> implements OnInit {
  readonly destroyed$: Observable<unknown>;
  readonly control = new UntypedFormControl(null);

  @Input() readonly color: ThemePalette = 'primary';
  @Output() readonly changed = new EventEmitter<T>();

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  get checked() {
    return this.value;
  }

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: T) => {
      this.changed.emit(this.viewToModelParser(value) as T);
    });

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }
}
