import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { CustomValidators, ERPFiltersUtil, IMetadataFiltering, QueryOperation } from '@erp/shared';

import { BaseTableFilter } from '../../abstracts';

type InputType = 'integer' | 'decimal' | 'amount';
const MAX_DECIMAL_FRACTION_DIGITS = 6;
const MAX_AMOUNT_FRACTION_DIGITS = 6;

@Component({
  selector: 'erp-table-range-filter',
  templateUrl: './table-range-filter.component.html',
  styleUrls: ['./table-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPTableRangeFilterComponent extends BaseTableFilter implements OnInit {
  form: UntypedFormGroup;
  type: InputType = 'integer';

  constructor(
    readonly dialogRef: MatDialogRef<ERPTableRangeFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { trigger: HTMLElement; filter: IMetadataFiltering; inputType: InputType },
    readonly dialogElementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() {
    let from;
    let to = null;
    this.type = this.data.inputType ?? 'integer';
    if (ERPFiltersUtil.isBetweenFilter(this.data.filter)) {
      from = this.data.filter.match1;
      to = this.data.filter.match2;
    } else {
      from = ERPFiltersUtil.isGreaterThanOrEqualFilter(this.data.filter) ? this.data.filter.match1 : null;
      to = ERPFiltersUtil.isLaterThanOrEqualFilter(this.data.filter) ? this.data.filter.match1 : null;
    }
    const validators = [];

    switch (this.type) {
      case 'integer':
        validators.push(CustomValidators.safeInteger);
        break;
      case 'decimal':
        validators.push(CustomValidators.safeDecimal);
        break;
      case 'amount':
        validators.push(CustomValidators.safeAmount);
    }

    this.form = new UntypedFormGroup(
      {
        from: new UntypedFormControl(from, [...validators]),
        to: new UntypedFormControl(to, [...validators])
      },
      [CustomValidators.range]
    );
    this.setDialogPosition();
  }

  onApply() {
    const from: number = this.form.get('from')?.value;
    const to: number = this.form.get('to')?.value;
    if (this.form.invalid) {
      return;
    }

    if (!!from || !!to || from === 0 || to === 0) {
      let op: QueryOperation;
      let match1: number;
      let match2: number | null;

      if ((!!from || from === 0) && (!!to || to === 0)) {
        op = 'between';
        match1 = +from;
        match2 = +to;
      } else {
        if (!!from || from === 0) {
          op = 'gte';
          match1 = +from;
          match2 = null;
        } else {
          op = 'lte';
          match1 = +to;
          match2 = null;
        }
      }

      const filter: IMetadataFiltering = { by: this.data.filter?.by, match1, match2, op };

      return this.dialogRef.close(filter);
    }
    this.dialogRef.close(null);
  }
}
