<h1 class="mat-headline dialog-header margin-right-5" mat-dialog-title>
  <ng-container>Device Parameters</ng-container>
</h1>
<section class="dialog-content erp-tablet-module" mat-dialog-content>
  <div [formGroup]="form" class="padding-top-3 grid grid-column-gap-c grid-row-gap-p">
    <div class="col-6">
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container>Pi Tape SN:</ng-container>
      </erp-label>
      <erp-input formControlName="piTapeSn" [isFocused]="true"></erp-input>
    </div>
    <div class="col-6">
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container>Ovality / Dial SN:</ng-container>
      </erp-label>
      <erp-input formControlName="ovalityDialSn"></erp-input>
    </div>
    <div class="col-6">
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container>Micrometer SN:</ng-container>
      </erp-label>
      <erp-input formControlName="micrometerSn"></erp-input>
    </div>
    <div class="col-6">
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container>Straightedge SN:</ng-container>
      </erp-label>
      <erp-input formControlName="straightedgeSn"></erp-input>
    </div>
    <div class="col-12">
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container>Entry Stencil:</ng-container>
      </erp-label>
      <erp-textarea formControlName="entryStencil" [rows]="3" placeholder="Type here..."></erp-textarea>
    </div>
  </div>
</section>
<section class="flex justify-content-flex-end erp-app erp-app-tablet" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" (click)="onCancel()" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel">
      Cancel
    </ng-container>
  </erp-button>
  <erp-button
    [disabled]="!form.valid || data.mode === 'view'"
    medium
    class="dialog-action-confirm"
    color="accent"
    (click)="onSave()"
  >
    <ng-container>
      Save
    </ng-container>
  </erp-button>
</section>
