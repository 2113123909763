export enum DocumentTypes {
  PurchaseOrder = 'Purchase order',
  SalesOrder = 'Sales order',
  WorkOrder = 'Work order',
  WOReceipt = 'WO Receipt',
  WOConsume = 'WO Consume',
  Movement = 'Movement',
  Adjustment = 'Adjustment',
  TransferOrder = 'Transfer order',
  SalesQuote = 'Sales quote'
}

export enum DocumentTypeIds {
  PurchaseOrder = 1,
  SalesOrder = 2,
  ProcessingOrder = 3,
  ProcessingReceipt = 4,
  ProcessingConsume = 5,
  Movement = 6,
  Adjustment = 7,
  TransferOrder = 8,
  SalesQuote = 9,
  PurchaseRequisition = 10,
  InboundDelivery = 11,
  POReceipt = 12,
  SOIssue = 13,
  OutboundDelivery = 14,
  UnloadingAuthorization = 15,
  LoadingAuthorization = 16,
  MaterialRelease = 17,
  Segregation = 18,
  Ncp = 20,
  iNcp = 21
}
