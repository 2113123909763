<div
  class="erp-toaster animated faster"
  [class.fadeOutRight]="(removing$ | async) && containment === 'root'"
  [class.fadeInRight]="containment === 'root'"
>
  <erp-button
    color="none"
    variant="icon"
    class="flex erp-toaster__close"
    [ngClass]="[showTitle ? '' : 'without-title']"
  >
    <erp-icon (click)="onRemove()" icon="action:cross"> </erp-icon>
  </erp-button>
  <div [ngSwitch]="type" (click)="contentClicked()" [class.clickable]="!!onClick">
    <div *ngSwitchCase="'error'" class="erp-toaster-content erp-toaster-content__error flex">
      <erp-icon class="erp-toaster-content__icon erp-toaster-content__error-icon" icon="action:notification" size="fit">
      </erp-icon>
      <div class="flex-grow-1">
        <div *ngIf="showTitle" class="erp-toaster-content__title">
          <ng-container *ngIf="title">
            {{ title }}
          </ng-container>
          <ng-container *ngIf="!title" i18n="@@common.toaster.title.error">
            Error
          </ng-container>
        </div>
        <div class="erp-toaster-message">
          <ng-container *ngIf="isMessage">
            <div [ngSwitch]="$any(data)">
              <ng-container *ngSwitchCase="'error.default'" i18n="@@common.toaster.error.default"
                >Sorry, we are experiencing a problem. Please try again later.</ng-container
              >
              <ng-container *ngSwitchCase="'error.deleted'" i18n="@@common.toaster.error.deleted"
                >Hmm, looks like this record no longer exists.</ng-container
              >
              <ng-container *ngSwitchDefault>
                {{ message }}
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="!isMessage">
            <ng-container *ngTemplateOutlet="$any(data).template; context: $any(data).context"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'warning'" class="erp-toaster-content erp-toaster-content__warning flex">
      <erp-icon
        class="erp-toaster-content__icon erp-toaster-content__warning-icon"
        icon="action:notification"
        size="fit"
      >
      </erp-icon>
      <div class="flex-grow-1">
        <div *ngIf="showTitle" class="erp-toaster-content__title">
          <ng-container *ngIf="title">
            {{ title }}
          </ng-container>
          <ng-container *ngIf="!title" i18n="@@common.toaster.title.warning">
            Warning
          </ng-container>
        </div>
        <div class="erp-toaster-message">
          <ng-container *ngIf="isMessage">
            {{ message }}
          </ng-container>
          <ng-container *ngIf="!isMessage">
            <ng-container *ngTemplateOutlet="$any(data).template; context: $any(data).context"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'success'" class="erp-toaster-content erp-toaster-content__success flex">
      <erp-icon
        class="erp-toaster-content__icon erp-toaster-content__success-icon"
        icon="action:notification"
        size="fit"
      >
      </erp-icon>
      <div class="flex-grow-1">
        <div *ngIf="showTitle" class="erp-toaster-content__title">
          <ng-container *ngIf="title">
            {{ title }}
          </ng-container>
          <ng-container *ngIf="!title" i18n="@@common.toaster.title.success">
            Success
          </ng-container>
        </div>
        <div class="erp-toaster-message">
          <ng-container *ngIf="isMessage">
            <div [ngSwitch]="$any(data)">
              <ng-container *ngSwitchCase="'success.created'" i18n="@@common.toaster.success.created"
                >Great! The record was successfully created.</ng-container
              >
              <ng-container *ngSwitchCase="'success.updated'" i18n="@@common.toaster.success.updated"
                >Great! The record was successfully updated.</ng-container
              >
              <ng-container *ngSwitchCase="'success.deleted'" i18n="@@common.toaster.success.deleted"
                >The record was removed from the system.</ng-container
              >
              <ng-container *ngSwitchDefault>{{ message }}</ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="!isMessage">
            <ng-container *ngTemplateOutlet="$any(data).template; context: $any(data).context"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'info'" class="erp-toaster-content erp-toaster-content__info flex">
      <erp-icon class="erp-toaster-content__icon erp-toaster-content__info-icon" icon="action:notification" size="fit">
      </erp-icon>
      <div class="flex-grow-1">
        <div *ngIf="showTitle" class="erp-toaster-content__title">
          <ng-container *ngIf="title">
            {{ title }}
          </ng-container>
          <ng-container *ngIf="!title" i18n="@@common.toaster.title.info">
            Info
          </ng-container>
        </div>
        <div class="erp-toaster-message">
          <ng-container *ngIf="isMessage">
            {{ message }}
          </ng-container>
          <ng-container *ngIf="!isMessage">
            <ng-container *ngTemplateOutlet="$any(data).template; context: $any(data).context"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
