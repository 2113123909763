<h1 class="dialog-header" mat-dialog-title>
  <ng-container i18n="common.dialogs.cant_create_so">Can't create Sales Order</ng-container>
</h1>
<ng-container>
  <erp-button color="primary" variant="icon" class="dialog-close" [mat-dialog-close]="false">
    <erp-icon icon="action:cross"></erp-icon>
  </erp-button>
</ng-container>
<section class="dialog-content" mat-dialog-content>
  <ng-container i18n="@@common.errors.GEN-86" #GEN86>
    Can’t create sales orders for customers with <strong>Prospect/Other</strong> status. Contact the Accounting
    department to validate the customer status.
  </ng-container>
</section>
<section class="dialog-actions flex justify-content-flex-end" mat-dialog-actions>
  <erp-button type="button" class="dialog-action-got-it" variant="flat" [mat-dialog-close]="true">
    <ng-container i18n="@@common.buttons.got-it">
      Got It
    </ng-container>
  </erp-button>
</section>
