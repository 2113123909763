import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from '@angular/core';

import { AutoCleanupFeature, ERPProductsSearchService, Features, IProductSearch } from '@erp/shared';

import { ERPSidenavPositionService } from '../../../sidenav/services/sidenav-position/sidenav-position.service';

@Component({
  selector: 'erp-material-details',
  templateUrl: './material-details.component.html',
  styleUrls: ['./material-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPMaterialDetailsComponent implements OnInit {
  readonly destroyed$: Observable<void>;
  private readonly closedHeight = 3;
  private readonly openedHeight = 13;
  readonly trimmedSize = 15;

  opened = false;
  product: IProductSearch | null;

  @Input()
  set materialDetailsData(materialDetailsData: { skuId: string; materialId: number } | null) {
    if (materialDetailsData?.skuId) {
      const { skuId, materialId } = materialDetailsData;
      this.loadProduct(skuId, materialId);
    } else {
      this.product = null;
    }
  }
  @HostBinding('style.left') left = '20rem';
  @HostBinding('style.height.rem')
  get height(): number {
    return this.opened ? this.openedHeight : this.closedHeight;
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly productService: ERPProductsSearchService,
    readonly sidenavPositionService: ERPSidenavPositionService
  ) {}

  ngOnInit() {
    this.sidenavPositionService.$sidenavWidthObservable.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.left = res;
      this.changeDetectorRef.markForCheck();
    });
  }

  private loadProduct(skuId: string, materialId: number) {
    this.productService.getProduct(skuId, materialId).subscribe(res => {
      this.product = res;
      this.opened = true;
      this.changeDetectorRef.markForCheck();
    });
  }
}
