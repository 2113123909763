<erp-confirm
  i18n-header="@@common.dialogs.complete-line.header"
  header="Complete this line?"
  i18n-message="@@common.dialogs.complete-line.message"
  message="After completion you will not be able to make any changes to the line."
  i18n-cancel="@@common.dialogs.complete-line.buttons.keep-it"
  cancel="No"
  i18n-confirm="@@common.dialogs.complete-line.buttons.delete-it"
  confirm="Yes"
  color="warn"
></erp-confirm>
