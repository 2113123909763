import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import {
  ERPProductsSearchService,
  ICollectionResponseMetadata,
  IMetadataFiltering,
  IMetadataPaging,
  IMetadataSorting,
  IProductSearch,
  ProductStatus
} from '@erp/shared';

export interface IDialogData {
  productsFiltering: IMetadataFiltering[];
  populateFiltering: IMetadataFiltering[];
}

@Component({
  selector: 'erp-inventory-sku-smart-search-dialog',
  templateUrl: './sku-smart-search-dialog.component.html',
  styleUrls: ['./sku-smart-search-dialog.component.scss']
})
export class ERPSkuSmartSearchDialogComponent implements OnInit {
  tableData: IProductSearch[];
  sorting: IMetadataSorting[];
  paging: IMetadataPaging = { page: 1, perPage: 5, total: 0 };
  filtering: IMetadataFiltering[] = [];
  defaultFiltering: IMetadataFiltering[] = [
    {
      by: 'status',
      match1: ProductStatus.Active,
      op: 'eq'
    }
  ];

  form = new UntypedFormGroup({
    filter: new UntypedFormControl()
  });

  constructor(
    readonly dialogRef: MatDialogRef<ERPSkuSmartSearchDialogComponent>,
    readonly productSearchService: ERPProductsSearchService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}

  ngOnInit() {
    let populateFiltering: IMetadataFiltering[] = [];

    if (this.data?.productsFiltering) {
      this.defaultFiltering.push(...this.data.productsFiltering);
    }
    if (this.data?.populateFiltering) {
      const patchValue: { [key: string]: unknown | string } = {};

      populateFiltering = this.data.populateFiltering;

      this.data.populateFiltering.forEach((i: IMetadataFiltering<string>) => {
        const key = i.by as string;
        patchValue[key] = i.match1;
      });
      this.form.get('filter')?.patchValue(patchValue);
    }
    this.filtering = [...this.defaultFiltering, ...populateFiltering];
    this.getData();
  }

  onProductSelected(product: IProductSearch) {
    this.dialogRef.close(product);
  }

  onSortingChange(sorting: IMetadataSorting[]) {
    this.sorting = sorting;
    this.paging = { ...this.paging, page: 1 };
    this.getData();
  }

  onFilteringChange(filtering: IMetadataFiltering[]) {
    this.filtering = [...filtering, ...this.defaultFiltering];
    this.paging = { ...this.paging, page: 1 };
    this.getData();
  }

  onChangePage(page: number) {
    this.paging = { ...this.paging, page };
    this.getData();
  }

  getData() {
    const query: ICollectionResponseMetadata = {
      paging: this.paging,
      sorting: this.sorting ?? [],
      filtering: this.filtering ?? []
    };

    return this.productSearchService.getProducts({ searchString: JSON.stringify(query) }).subscribe(res => {
      this.tableData = res.data;
      this.paging = res.metadata.paging;
    });
  }
}
