<ng-container>
  {{ title }}
</ng-container>
<div class="flex" [formGroup]="form">
  <erp-date-picker
    formControlName="fromDate"
    icon="action:cross-circle"
    (ngModelChange)="fromDateChanged.emit($event)"
  ></erp-date-picker>
  <div class="margin-left-3 margin-right-3">-</div>
  <erp-date-picker
    formControlName="toDate"
    icon="action:cross-circle"
    (ngModelChange)="toDateChanged.emit($event)"
  ></erp-date-picker>
</div>

<mat-error class="mat-caption" *ngIf="isError" i18n="@@common.form.errors.range-date">
  'End date' must be greater than 'Start date'
</mat-error>
