<erp-confirm
  i18n-header="@@common.dialogs.confirm-loading.header"
  header="Confirm Loading Authorization?"
  i18n-cancel="@@common.dialogs.confirm-loading.buttons.cancel"
  cancel="Cancel"
  i18n-confirm="@@common.dialogs.confirm-loading.buttons.confirm"
  confirm="Confirm"
>
  <span message>
    <ng-container i18n="@@common.dialogs.confirm-loading.message"
      >Once confirmed, you can't edit this information.</ng-container
    >
  </span>
</erp-confirm>
