import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AutoCleanupFeature,
  ERPEmitService,
  EmitEvent,
  Features,
  IDestroyable
} from '../../../../../../../shared/src/lib';
import { ERPToasterService } from '../../../toaster';
import { ERPSidenavPositionService } from '../../services';

@Component({
  selector: 'erp-sidenav-container',
  templateUrl: './sidenav-container.component.html',
  styleUrls: ['./sidenav-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPSidenavContainerComponent implements OnInit, IDestroyable {
  readonly destroyed$: Observable<unknown>;
  @ViewChild('sidenav') sidenav: MatSidenav;
  collapsed = false;

  collapsedWith = '2.5rem';
  fullWidth = '20rem';

  isTabletModule = false;

  constructor(
    readonly activeRoute: ActivatedRoute,
    private readonly emitService: ERPEmitService,
    readonly router: Router,
    private changeDetector: ChangeDetectorRef,
    readonly toaster: ERPToasterService,
    readonly route: ActivatedRoute,
    private sidenavPositionService: ERPSidenavPositionService
  ) {}

  ngOnInit(): void {
    this.activeRoute.url.pipe(takeUntil(this.destroyed$)).subscribe(e => {
      this.sidenav?.toggle(false);
      this.changeDetector.markForCheck();
      this.isTabletModule = this.router.url.startsWith('/tablet');
      this.collapsed = this.isTabletModule;
    });

    this.emitService.events$.pipe(takeUntil(this.destroyed$)).subscribe(e => {
      const collapseOn: EmitEvent[] = ['tablet:sidebar-navigation', 'tablet:check-in', 'tablet:check-out'];

      if (collapseOn.includes(e)) {
        this.collapsed = true;
        this.changeDetector.detectChanges(); // to handle click on the same URL (it's detected only on second click)
      }
    });
  }

  onToggle() {
    this.collapsed = !this.collapsed;
    this.sidenavPositionService.onPushNewWith(this.collapsed ? this.collapsedWith : this.fullWidth);
  }

  onContentClick() {
    if (this.isTabletModule && !this.collapsed) {
      this.collapsed = true;
    }
  }
}
