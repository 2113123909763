import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import {
  AuthInterceptor,
  ConvertDateInterceptor,
  LoadingInterceptor,
  TabletInterceptor,
  TimeoutInterceptor,
  TrimSlashInterceptor
} from './interceptors';

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useExisting: TrimSlashInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: TimeoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: ConvertDateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: TabletInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: LoadingInterceptor, multi: true }
  ]
})
export class ERPHttpModule {}
