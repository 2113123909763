import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';
import { ERPDateUtil, ERPNcpDispositionsFactory, INcpDispositionResponse, IdValue } from '@erp/shared';
import { IReclassifyNcpItem } from '@erp/tablet';
import { InventoryItemClassificationType } from '@erp/tablet/production/modules/processing-order/modules/entry-operation/enums';
import {
  IEntryOperationInventoryItem,
  IEntryOperationInventoryItemResponse,
  IInventoryItemNcpDetail,
  IInventoryItemNcpDetailResponse
} from '@erp/tablet/production/modules/processing-order/modules/entry-operation/interfaces';

import {
  IProcessingOrderPipeMeasurements,
  IProductionProcessingOrder,
  IProductionProcessingOrderResponse
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ProcessingOrderFactory {
  constructor(readonly dispositionsFactory: ERPNcpDispositionsFactory) {}

  toRequest(order: IProductionProcessingOrder): IProductionProcessingOrderResponse {
    return {
      id: order.id,
      rowVersion: order.rowVersion,
      skuDetail: order.skuDetail,
      generalNotes: order.generalNotes,
      ...order.mainInfo,
      lines: order.lines,
      materials: order.materials,
      hasConsumption: order.hasConsumption,
      hasOutcome: order.hasOutcome,
      ...order.materialDetails
    };
  }

  fromResponse(response: IProductionProcessingOrderResponse): IProductionProcessingOrder {
    return {
      id: response.id,
      rowVersion: response.rowVersion,
      skuDetail: response.skuDetail,
      generalNotes: response.generalNotes,
      mainInfo: {
        approvedBy: response.approvedBy,
        parentProcessingOrder: response.parentProcessingOrder,
        customerRefNumber: response.customerRefNumber,
        customerId: response.customerId,
        customerName: response.customerName,
        documentNumber: response.documentNumber,
        dueDate: response.dueDate,
        endDate: response.endDate,
        endTime: ERPDateUtil.getTimeFromDate(response.endDate),
        estimatedEndDate: response.estimatedEndDate,
        estimatedEndTime: ERPDateUtil.getTimeFromDate(response.estimatedEndDate),
        locationId: response.locationId,
        locationName: response.locationName,
        processingOrderStandardId: response.processingOrderStandardId,
        purchaseTypeId: response.purchaseTypeId,
        salesOrderLineId: response.salesOrderLineId,
        salesOrderId: response.salesOrderId,
        salesOrderNumber: response.salesOrderNumber,
        startDate: response.startDate,
        startTime: ERPDateUtil.getTimeFromDate(response.startDate),
        statusId: response.statusId,
        thirdParty: response.thirdParty,
        trialRun: response.trialRun,
        vendorId: response.vendorId,
        vendorName: response.vendorName,
        ownerId: response.ownerId,
        ownerName: response.ownerName,
        isReservationOrder: response.isReservationOrder,
        purchaseOrderId: response.purchaseOrderId,
        purchaseOrderNumber: response.purchaseOrderNumber,
        revisionNumber: response.revisionNumber
      },
      lines: response.lines,
      materials: response.materials,
      hasConsumption: response.hasConsumption,
      hasOutcome: response.hasOutcome,
      materialDetails: {
        materialId: response.materialId,
        materialNumber: response.materialNumber,
        millId: response.millId,
        millName: response.millName,
        processorId: response.processorId,
        processorName: response.processorName,
        customerRefNumber: response.customerRefNumber,
        receiptDate: response.receiptDate,
        endDateIsReceiptDate: response.endDateIsReceiptDate
      }
    };
  }

  parseTallyItems(items: IProcessingOrderPipeMeasurements[]) {
    items.forEach(
      row =>
        (row.items = row.items.map(i => ({
          id: i.id,
          joint: i.joint,
          lengthFt: i.lengthFt,
          weight: i.weight ? +i.weight : undefined,
          rowVersion: i.rowVersion
        })))
    );

    return items;
  }

  toInventoryItemRequest(item: IEntryOperationInventoryItem): IEntryOperationInventoryItemResponse {
    const { classification, ...inventoryProperties } = item;

    return {
      ...inventoryProperties,
      classificationQty: item.classification.classificationQty,
      classificationType: item.classification.classificationType,
      ncpDetail: [InventoryItemClassificationType.Prime, InventoryItemClassificationType.FirstPrime].includes(
        item.classification.classificationType
      )
        ? undefined
        : ({
            ...item.classification.ncpDetail,
            serviceDetails: !item.classification.ncpDetail?.serviceDetails[0]?.serviceType
              ? undefined
              : item.classification.ncpDetail.serviceDetails,
            dispositions: this.dispositionsFactory.toRequest(
              item.classification.ncpDetail?.dispositions as ISelectOption[] | undefined,
              item.classification.classificationType !== InventoryItemClassificationType.Reject
            ),
            joints:
              item.classification.ncpDetail?.enterByJoint && item.classification.ncpDetail?.joints.some(j => !!j)
                ? item.classification.ncpDetail?.joints
                : []
          } as IInventoryItemNcpDetailResponse)
    } as IEntryOperationInventoryItemResponse;
  }

  toInventoryItemResponse(item: IEntryOperationInventoryItemResponse): IEntryOperationInventoryItem {
    return {
      ...item,
      materialNumber: null,
      inventoryStatus: null,
      classification: {
        classificationQty: item.classificationQty,
        classificationType: item.classificationType,
        ncpDetail: {
          ...item.ncpDetail,
          enterByJoint: item.ncpDetail?.joints.some(c => !!c) || false,
          dispositions: this.dispositionsFactory.fromResponse(
            item.ncpDetail?.dispositions as INcpDispositionResponse[] | undefined
          )
        } as IInventoryItemNcpDetail
      }
    };
  }

  toNcpItemRequest(item: IReclassifyNcpItem): IEntryOperationInventoryItemResponse {
    return {
      ...item,
      ncpDetail: [InventoryItemClassificationType.Prime, InventoryItemClassificationType.FirstPrime].includes(
        item.classificationType
      )
        ? ({
            ncpDetails: item.ncpDetail.ncpDetails
          } as IInventoryItemNcpDetailResponse)
        : ({
            ...item.ncpDetail,
            dispositions: this.dispositionsFactory.toRequest(
              item.ncpDetail?.dispositions as ISelectOption[] | undefined,
              item.classificationType !== InventoryItemClassificationType.Reject
            ),
            joints: item.ncpDetail?.enterByJoint && item.ncpDetail?.joints.some(j => !!j) ? item.ncpDetail?.joints : []
          } as IInventoryItemNcpDetailResponse)
    };
  }
}
