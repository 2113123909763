import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import {
  AutoCleanupFeature,
  BaseFormComponent,
  CustomValidators,
  DictionaryService,
  ERPFormStateDispatcher,
  Features,
  IInventorySpecialty,
  IMetadataFiltering,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

import { ISelectOption } from '../../../select';

@Component({
  selector: 'erp-inventory-sku-smart-search-filtering',
  templateUrl: './sku-smart-search-filtering.component.html',
  styleUrls: ['./sku-smart-search-filtering.component.scss'],
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPSkuSmartSearchFilteringComponent<T> extends BaseFormComponent<T> implements OnInit {
  readonly destroyed$: Observable<unknown>;
  private readonly debounceMs = 800;
  @Output() readonly filtering = new EventEmitter<unknown>();

  constructor(readonly dictionaryService: DictionaryService) {
    super();
  }

  sizes$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  walls$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  grades$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  connections$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  threads$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  ranges$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  conditions$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  protectorTypes$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  designs$: Observable<ISelectOption<string | undefined, string | undefined>[]>;
  specialties$: Observable<ISelectOption<string>[]>;
  materialTypes$: Observable<ISelectOption[]>;

  filters: IMetadataFiltering[] = [];

  readonly form = new UntypedFormGroup({
    size: new UntypedFormControl(null),
    weight: new UntypedFormControl(null, [CustomValidators.greaterThan(0)]),
    connection: new UntypedFormControl(null),
    range: new UntypedFormControl(null),
    condition: new UntypedFormControl(null),
    specialty: new UntypedFormControl(null),
    wall: new UntypedFormControl(null),
    grade: new UntypedFormControl(null),
    thread: new UntypedFormControl(null),
    protectorType: new UntypedFormControl(null),
    design: new UntypedFormControl(null),
    materialTypeId: new UntypedFormControl(null)
  });

  ngOnInit() {
    this.sizes$ = this.addConverter(this.dictionaryService.sizes);
    this.walls$ = this.addConverter(this.dictionaryService.walls);
    this.grades$ = this.addConverter(this.dictionaryService.grades);
    this.connections$ = this.addConverter(this.dictionaryService.connections);
    this.threads$ = this.addConverter(this.dictionaryService.threads);
    this.ranges$ = this.addConverter(this.dictionaryService.ranges);
    this.conditions$ = this.addConverter(this.dictionaryService.conditions);
    this.protectorTypes$ = this.addConverter(this.dictionaryService.protectorTypes);
    this.designs$ = this.addConverter(this.dictionaryService.designs);
    this.specialties$ = this.addSpecialtyConverter(this.dictionaryService.specialties);
    this.materialTypes$ = this.dictionaryService.materialTypes;

    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged(), debounceTime(this.debounceMs))
      .subscribe(data => {
        this.filters = [];

        for (const [key, value] of Object.entries(data)) {
          if (value !== null && value !== undefined) {
            this.filters.push({ by: key, match1: value, op: 'eq' });
          }
        }
        this.filtering.emit(this.filters);
      });
  }

  private addConverter(obs: Observable<ISelectOption[]>) {
    return obs.pipe(
      map(res => {
        return res.map(el => {
          return { id: el.value, value: el.value };
        });
      })
    );
  }

  private addSpecialtyConverter(obs: Observable<IInventorySpecialty[]>) {
    return obs.pipe(
      map(res => {
        return res.map(el => {
          return { id: el.value, value: `${el.value}: ${el.description}` } as ISelectOption<string>;
        });
      })
    );
  }
}
