export enum GoodsIssueStatus {
  Open = 1,
  Confirmed = 2,
  Processing = 3
}

export enum GoodIssueDocumentType {
  GoodIssue = 13,
  RMA = 19
}
