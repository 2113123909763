import { Pipe, PipeTransform } from '@angular/core';

import { ERPHourUtil } from '../utils';

@Pipe({
  name: 'erpTimeFromNumber'
})
export class ERPTimeFromNumberPipe implements PipeTransform {
  transform(value: number): string | null {
    return ERPHourUtil.getHourFromNumber(value);
  }
}
