<erp-panel variant="secondary" (dragenter)="onDragEnter($event)">
  <erp-panel-header>
    <div
      class="flex flex-wrap flex-grow-1 justify-content-space-between align-items-center"
      (click)="collapsed = !collapsed"
    >
      <div class="flex align-items-center">
        <erp-icon
          *ngIf="collapsible"
          color="primary"
          [icon]="collapsed ? 'action:chevron-right' : 'action:chevron-down'"
        ></erp-icon>
        <span class="white-space-normal overflow-wrap-anywhere margin-right-1">
          <ng-content></ng-content>
        </span>
        <span>({{ form.length }})</span>
      </div>
      <input
        type="file"
        multiple="multiple"
        accept="*"
        (change)="onSelectFiles()"
        #input
        hidden
        (click)="$event.stopPropagation()"
      />
      <erp-button dense variant="link" color="primary" (click)="onUploadClick($event)">
        <erp-icon icon="action:upload"></erp-icon>
        <ng-container i18n="@@common.attachments.upload-new">
          Upload New
        </ng-container>
      </erp-button>
    </div>
  </erp-panel-header>
  <erp-panel-body class="attachments" [class.attachments-collapsed]="collapsible && collapsed">
    <ng-container *ngFor="let control of form.controls; trackBy: trackByControl; index as index">
      <erp-attachment
        class="margin-bottom-2"
        [attachment]="control.value"
        (delete)="onDelete(control.value, index)"
        (download)="onDownload(control.value)"
        [underline]="attachmentUnderline"
        [withLabels]="attachmentWithLabels"
        [trimSize]="trimSize"
      >
      </erp-attachment>
    </ng-container>
    <ng-container *ngFor="let loading of loadings | keyvalue; trackBy: trackByKey">
      <erp-attachment
        class="margin-bottom-2"
        [isLoadingType]="true"
        [attachment]="loading.value"
        [loadingProgress]="loading.value?.loadingProgress"
        [errorLoading]="loading.value?.errorLoading"
        (interruptLoading)="onInterrupt($event)"
        [underline]="attachmentUnderline"
        [withLabels]="attachmentWithLabels"
        [trimSize]="trimSize"
      ></erp-attachment>
    </ng-container>
  </erp-panel-body>
</erp-panel>
<ng-container *ngIf="dragOver">
  <div class="flex align-items-center justify-content-center drag-over">
    <erp-icon icon="action:upload" color="primary"></erp-icon>
    <a class="margin-left-2 drag-over-label">
      <ng-container i18n="@@common.attachments.upload-drag-over">
        Drop files to upload
      </ng-container>
    </a>
  </div>
  <div
    class="drag-over-overlay"
    (dragenter)="onDragEnter($event)"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
    (dragleave)="onDragLeave($event)"
  ></div>
</ng-container>

<ng-template>
  <ng-container i18n="@@common.attachments.confirm-delete">
    Yes
  </ng-container>
  <ng-container i18n="@@common.attachments.cancel-delete">
    No
  </ng-container>
  <ng-container i18n="@@common.attachments.delete-header">
    Permanently delete this document?
  </ng-container>
  <ng-container i18n="@@common.attachments.rewrite-header">
    A file with this name already exists.
  </ng-container>
  <ng-container i18n="@@common.attachments.rewrite-message">
    Do you want to replace it?
  </ng-container>
  <ng-container i18n="@@common.attachments.confirm-rewrite">
    Yes, replace it
  </ng-container>
  <ng-container i18n="@@common.error.GEN-80">
    Looks like the file is too large. Contact the system administrator for assistance.
  </ng-container>
</ng-template>
