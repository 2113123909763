import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import { ERPRadioComponent } from './components';

@NgModule({
  declarations: [ERPRadioComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatRadioModule
  ],
  exports: [MatFormFieldModule, ReactiveFormsModule, ERPRadioComponent]
})
export class ERPRadioModule {}
