import * as moment from 'moment';
import { Observable } from 'rxjs';

import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ERPCustomerService } from '../../../../../../../sales/src/lib/modules/customers/services';

import {
  AutoCleanupFeature,
  BaseFormComponent,
  ERPFormStateDispatcher,
  ERPLoadingAuthSharedService,
  ERPUnloadingAuthSharedService,
  Features,
  IMatchedCustomer,
  IMatchedCustomerWithAvailableDates,
  IOnHandReport,
  LoadingReportType,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

export const customerStatusEnum = {
  Customer: 2
};

@Component({
  selector: 'erp-on-hand-report',
  templateUrl: './on-hand-report.component.html',
  styleUrls: ['./on-hand-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPOnHandReportComponent<T> extends BaseFormComponent<T> {
  readonly destroyed$: Observable<unknown>;

  readonly form = new UntypedFormGroup({
    customerId: new UntypedFormControl(null),
    customerName: new UntypedFormControl(null, [Validators.required]),
    date: new UntypedFormControl(null, [Validators.required])
  });

  date: Date;

  customerOptionsFn = (customerName: string) =>
    this.customerService.getMatchedCustomersByStatus({ customerName, statusId: customerStatusEnum.Customer });

  customerLabelFn = (val: IMatchedCustomer) => (val instanceof Object ? val.customerName : val);

  customerDisplayFn = (customer: IMatchedCustomer | string) =>
    customer instanceof Object ? customer.customerName : null;

  customerValueFn = (customer: IMatchedCustomer | string) => (customer instanceof Object ? customer : null);

  constructor(
    readonly formState: ERPFormStateDispatcher,
    readonly dialogRef: MatDialogRef<ERPOnHandReportComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { type: LoadingReportType },
    readonly customerService: ERPCustomerService,
    readonly unloadingAuthService: ERPUnloadingAuthSharedService,
    readonly loadingAuthService: ERPLoadingAuthSharedService,
    @Inject(LOCALE_ID)
    readonly currentLocale: string
  ) {
    super();
  }

  onCreate() {
    const { invalid, value } = this.form;
    let date;
    let formattedDate;

    if (invalid) {
      return;
    }

    this.formState.onSubmit.notify();

    if (value.date) {
      date = moment(value.date)?.startOf('day').toDate();
      formattedDate = formatDate(date, 'MM/dd/yyyy h:mm:ss a', this.currentLocale);
    }

    this.dialogRef.close({
      customerId: value?.customerId,
      date: formattedDate
    } as IOnHandReport);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onCustomerChanged(match: IMatchedCustomer) {
    this.form.patchValue({
      customerId: match?.id ?? null,
      customerName: match?.customerName ?? null
    });
  }
}
