import { Observable } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { AutoCleanupFeature, Features, ValidatorFeature, ValueAccessorFeature } from '@erp/shared';

const MIN_COLS = 2;

@Component({
  selector: 'erp-table-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPTableOrderModalComponent implements OnInit {
  readonly destroyed$: Observable<unknown>;

  source = new Map<string, { name: string; disabled: boolean }>();
  columns: string[] = [];
  orderedColumns: string[] = [];
  disabledColumns: string[] = [];
  requiredColumns: string[] = [];
  hiddenColumns: string[] = [];

  form = new UntypedFormGroup({});
  lastColsSelected = [];

  constructor(
    readonly dialogRef: MatDialogRef<string>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: {
      columnNames: Map<string, { name: string; disabled: boolean }>;
      columnOrder: string[];
      columns: string[];
      disabledColumns: string[];
      activeColumns: string[];
      hiddenColumns: string[];
      requiredColumns: string[];
    }
  ) {}

  ngOnInit(): void {
    const activeCols = this.data.activeColumns;

    this.form = new UntypedFormGroup({});
    this.hiddenColumns = this.data.hiddenColumns;
    this.source = this.data.columnNames;
    this.columns = this.data.columns;
    this.requiredColumns = this.data.requiredColumns;
    this.orderedColumns = this.data.columnOrder.filter(i => !this.data.hiddenColumns.includes(i));
    this.disabledColumns = this.data.disabledColumns.filter(i => !this.data.hiddenColumns.includes(i));

    if (activeCols.length) {
      this.columns.forEach(col => this.addControl(col, activeCols.includes(col)));
    } else {
      this.columns.forEach(col => this.addControl(col));
    }
    this.lastColsSelected = this.form.value;

    // disable uncheck all columns
    this.form.valueChanges.pipe(startWith(this.lastColsSelected), pairwise()).subscribe(([prev, next]) => {
      if (this.activeColumns.filter(col => !this.disabledColumns.includes(col)).length < MIN_COLS) {
        this.form.patchValue(this.lastColsSelected, { emitEvent: false });
      } else {
        this.lastColsSelected = next;
      }
    });
  }

  private addControl(col: string, state = true) {
    if (this.hiddenColumns.includes(col)) {
      return;
    }

    this.form.addControl(
      col,
      new UntypedFormControl({
        value: state || this.disabledColumns.includes(col) || this.requiredColumns.includes(col),
        disabled: this.disabledColumns.includes(col) || this.requiredColumns.includes(col)
      })
    );
  }

  private get activeColumns() {
    const formVal = this.form.getRawValue();

    return Object.keys(formVal).filter(key => formVal[key]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.orderedColumns, event.previousIndex, event.currentIndex);
  }

  onSave() {
    this.dialogRef.close({ order: this.orderedColumns, active: this.activeColumns });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
