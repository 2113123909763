export enum ModulesLinks {
  Customers = '/sales/customers',
  Casing = '/inventory/product-types/casing',
  Tubing = '/inventory/product-types/tubing',
  LinePipe = '/inventory/product-types/line-pipe',
  DrillPipe = '/inventory/product-types/drill-pipe',
  Marker = '/inventory/product-types/marker',
  CouplingStock = '/inventory/product-types/coupling-stock',
  CouplingMaterial = '/inventory/product-types/coupling-stock',
  Coupling = '/inventory/product-types/coupling',
  Protector = '/inventory/product-types/protector',
  Service = '/inventory/product-types/service',
  ProductionServices = '/production/services/',
  Manufacturer = '/inventory/setup/manufacturer',
  Site = '/inventory/setup/location',
  PurchaseRequisitions = '/procurement/inventory-transactions/purchase-requisitions',
  PurchaseOrders = '/procurement/inventory-transactions/purchase-orders',
  Receipts = '/procurement/inventory-transactions/receipts',
  InboundDeliveries = '/procurement/inventory-transactions/inbound-deliveries',
  SalesOrder = '/sales/sales-orders/',
  LoadingAuthorizations = '/logistic-and-warehousing/loading-authorizations',
  Vendor = '/procurement/inventory-transactions/vendors',
  SalesQuotes = '/sales/sales-quotes/',
  MaterialRelease = '/sales/material-release',
  SalesContact = '/sales/contacts',
  TransferOrders = '/logistic-and-warehousing/transfer-orders',
  ProcessingOrder = '/production/processing-order',
  TransportationCompanies = '/logistic-and-warehousing/transportation-companies',
  MaterialIdHistory = '/inventory/inquiries/material-id-history'
}
