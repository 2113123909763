import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'erp-complete-unloading-dialog',
  templateUrl: './complete-unloading-dialog.component.html',
  styleUrls: ['./complete-unloading-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPCompleteUnloadingDialogComponent {}
