import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

import { ISelectOption, TableFilterType } from '@erp/components';

import { ERPTableFilterOpener } from '../../../../../components/src/lib/modules/table/services';
import { TableQueryBuilder } from '../../builders';
import { IInventoryUom } from '../../interfaces';
import { IMetadataFiltering, IMetadataSorting } from '../http';
import { IDestroyable } from '../lifecycle';

type labelFnType = (val: IInventoryUom) => string;
type valueFnType = (val: IInventoryUom[], id: number) => string;

@Directive()
export abstract class BaseTableComponent<T> implements IDestroyable {
  readonly destroyed$: Observable<unknown>;

  abstract readonly tableFilterOpener: ERPTableFilterOpener;
  abstract readonly changeDetector: ChangeDetectorRef;

  protected filters: IMetadataFiltering[] = [];

  readonly dataSource = new MatTableDataSource<T>([]);
  @ViewChild(MatSort, { static: true }) readonly sort: MatSort;
  @Output() readonly filteringChange: EventEmitter<IMetadataFiltering[]> = new EventEmitter();
  @Output() readonly sortingChange: EventEmitter<IMetadataSorting[]> = new EventEmitter();
  @Input() readonly total: number;

  @Input() set tableData(data: T[]) {
    this.dataSource.data = data ?? [];
  }

  @Input() set filtering(filters: IMetadataFiltering[]) {
    this.filters = filters ?? [];
  }

  get filtering() {
    return this.filters;
  }

  @Input() set sorting(sorting: IMetadataSorting[]) {
    if (sorting?.length) {
      this.sort.active = sorting[0]?.by;
      this.sort.direction = sorting[0]?.order;
    } else {
      this.sort.sort({
        id: (null as unknown) as string,
        start: 'asc',
        disableClear: true
      });
    }

    this.changeDetector.markForCheck();
  }

  onInit() {
    this.initChangeSortListener();
  }

  onFilterOpen(
    event: MouseEvent,
    column: string,
    type: TableFilterType,
    options?: ISelectOption[],
    labelFn?: labelFnType,
    displayFn?: valueFnType
  ) {
    event.stopPropagation();

    const target = event.target as HTMLElement;
    const trigger = target.closest('th') as HTMLElement;

    const current =
      this.filters?.find(x => x.by === column) ??
      ({
        by: column as string
      } as IMetadataFiltering);

    this.tableFilterOpener
      .open(type, {
        filter: current,
        trigger,
        options,
        labelFn,
        displayFn
      })
      .subscribe(updated => {
        this.updateFiltering(updated, current);
      });
  }

  private updateFiltering(updated: IMetadataFiltering | null, current: IMetadataFiltering) {
    let builder = new TableQueryBuilder({
      filtering: this.filters
    });
    builder = updated ? builder.setFilter(updated) : builder.removeFilter(current);

    const { filtering } = builder.build();
    this.filters = filtering;
    this.filteringChange.emit(filtering);
    this.changeDetector.markForCheck();
  }

  hasFilter(column: keyof T) {
    return this.filters?.some(x => x.by === column);
  }

  initChangeSortListener() {
    this.sort.sortChange
      .pipe(
        filter(sort => Boolean(sort.active)),
        takeUntil(this.destroyed$)
      )
      .subscribe(sort => {
        if (sort.direction) {
          this.sortingChange.emit([
            {
              by: sort.active,
              order: sort.direction
            }
          ]);
        } else {
          this.sortingChange.emit([]);
        }
      });
  }
}
