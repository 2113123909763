<table mat-table erpResizeTable [dataSource]="dataSource" [@.disabled]="true" matSort>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p>
        <ng-container i18n="@@inventory.sku-smart-search.sku">
          SKU
        </ng-container>
        ({{ total }})
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="[getLink(element.id), element.id]" (click)="$event.stopPropagation(); onSelect($event)">
        {{ element.id }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="skuDescription">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@inventory.sku-smart-search.sku-description">
        SKU Description
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="description" [title]="element.skuDescription">
        {{ element.skuDescription }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="od">
      <!--  Label "Size" changed to "OD" per customer request -->
      <p i18n="@@inventory.sku-smart-search.size">
        OD
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.size?.toFixed(3) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="wall">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@inventory.sku-smart-search.wall">
        Wall
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.wall?.toFixed(3) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="weight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@inventory.sku-smart-search.weight">
        Weight per Foot
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.weight?.toFixed(2) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="grade">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@inventory.sku-smart-search.grade">
        Grade
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.grade }}
    </td>
  </ng-container>

  <ng-container matColumnDef="connection">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@inventory.sku-smart-search.connection">
        Connection
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.connection }}
    </td>
  </ng-container>

  <ng-container matColumnDef="thread">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <!--  Label "Thread" changed to "End type" per customer request -->
      <p i18n="@@inventory.sku-smart-search.end-type">
        End type
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.thread }}
    </td>
  </ng-container>

  <ng-container matColumnDef="range">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <p i18n="@@inventory.sku-smart-search.range">
        Range
      </p>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.range }}
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="actions"></th>
    <td mat-cell *matCellDef="let element">
      <erp-button dense color="primary" variant="link" (click)="onSelect(element)">Select</erp-button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></tr>
  <tr mat-row class="cursor-pointer" *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div
  *ngIf="dataSource.data.length === 0"
  class="erp-table-nothing-found margin-top-8 text-center"
  i18n="@@common.table.no-matches"
>
  No matches for your filtering criteria
</div>
