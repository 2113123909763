import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TableQueryBuilder } from '../../builders';
import { ICollectionResponseMetadata, IMetadataFiltering, IMetadataSorting } from '../http';
import { IDestroyable } from '../lifecycle';

export abstract class BaseListComponent<T = unknown> implements IDestroyable {
  abstract readonly destroyed$: Observable<void>;

  abstract readonly route: ActivatedRoute;
  abstract readonly router: Router;
  abstract readonly changeDetector: ChangeDetectorRef;

  query: ICollectionResponseMetadata<T> = TableQueryBuilder.empty<T>();

  abstract get query$(): Observable<ICollectionResponseMetadata<T>>;

  onInit() {
    this.query$.pipe(takeUntil(this.destroyed$)).subscribe(query => {
      this.query = TableQueryBuilder.from(query).build();

      this.changeDetector.markForCheck();
    });
  }

  onFilteringChanged(filtering: IMetadataFiltering<T>[]) {
    const query = TableQueryBuilder.from(this.query)
      .withFiltering([...filtering])
      .withPaging({
        ...this.query.paging,
        page: 1
      })
      .build();

    this.onQueryChanged(query);
  }

  onLocalFilteringChanged(filtering: IMetadataFiltering<T>[]) {
    const query = TableQueryBuilder.from(this.query)
      .withFiltering([...filtering])
      .withPaging({
        ...this.query.paging,
        page: 1
      })
      .build();

    this.onLocalQueryChanged(query);
  }

  onPageChanged(page: number) {
    const query = TableQueryBuilder.from(this.query)
      .withPaging({
        ...this.query.paging,
        page
      })
      .build();

    this.onQueryChanged(query);
  }

  onSortingChanged(sorting: IMetadataSorting<T>[]) {
    const query = TableQueryBuilder.from(this.query)
      .withSorting([...sorting])
      .withPaging({
        ...this.query.paging,
        page: 1
      })
      .build();

    this.onQueryChanged(query);
  }

  onLocalSortingChanged(sorting: IMetadataSorting<T>[]) {
    const query = TableQueryBuilder.from(this.query)
      .withSorting([...sorting])
      .withPaging({
        ...this.query.paging,
        page: 1
      })
      .build();

    this.onLocalQueryChanged(query);
  }

  onQueryChanged(query: ICollectionResponseMetadata<T>) {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        query: TableQueryBuilder.from(query).serialize()
      }
    });
  }

  onLocalQueryChanged(query: ICollectionResponseMetadata<T>) {
    this.query = query;
  }
}
