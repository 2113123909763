<erp-confirm
  i18n-header="@@logistic.tablet.unloading-auth.mismatch.header.GEN-177"
  header="The loaded quantity differs from the requested quantity."
  i18n-message="@@logistic.tablet.unloading-auth.mismatch.message"
  message="Check the total count."
  i18n-cancel="@@common.buttons.confirm"
  cancel="Confirm"
  i18n-confirm="@@common.buttons.cancel"
  confirm="Cancel"
></erp-confirm>
