import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';
import {
  DictionaryService,
  ERPProductsSearchService,
  IProductSearch,
  Memoise,
  ProductStatus,
  TableQueryBuilder
} from '@erp/shared';

import { ILine } from '../components';
import { IProductionProcessingOrder } from '../interfaces';
import { generateServiceStencil } from '../utils/generate-service-stencil';

import { ERPQcSpecificationService } from './qc-specification.service';

@Injectable({
  providedIn: 'root'
})
export class ERPGenerateStencilService {
  constructor(
    readonly productsSearchService: ERPProductsSearchService,
    readonly qcSpecificationService: ERPQcSpecificationService,
    readonly dictionaryService: DictionaryService
  ) {}

  @Memoise()
  get processingOrderStandards$(): Observable<ISelectOption[]> {
    return this.dictionaryService.processingOrderStandards;
  }

  onGenerateNewStencilForLine(order: IProductionProcessingOrder, line: ILine): Observable<ILine> {
    const searchStringSKU = (match1: string) =>
      new TableQueryBuilder({
        filtering: [
          { by: 'status', match1: ProductStatus.Active, op: 'eq' },
          { by: 'id', match1, op: 'contains' }
        ]
      }).serialize();

    const searchStringHeaderSKU = (match1: string) =>
      new TableQueryBuilder({
        filtering: [{ by: 'id', match1, op: 'contains' }]
      }).serialize();

    const getQCSpecification = (skuHeader: IProductSearch) =>
      new TableQueryBuilder({
        filtering: [
          {
            by: 'wallValue',
            match1: skuHeader.wall,
            op: 'eq'
          },
          {
            by: 'gradeName',
            match1: skuHeader.grade,
            op: 'eq'
          },
          { by: 'odName', match1: skuHeader.size, op: 'eq' }
        ]
      }).serialize();

    return forkJoin({
      skuData: this.productsSearchService
        .getProducts({ searchString: searchStringSKU(line.outputSku.id) })
        .pipe(map(res => res.data[0])),
      skuHeader: this.productsSearchService
        .getProducts({ searchString: searchStringHeaderSKU(order.skuDetail.id) })
        .pipe(map(res => res.data[0])),
      standards: this.processingOrderStandards$
    }).pipe(
      switchMap(({ skuData, skuHeader, standards }) => {
        return this.qcSpecificationService
          .getQCSpecification({ query: getQCSpecification(skuHeader) })
          .pipe(map(skuSpec => ({ skuData, skuHeader, skuSpec: skuSpec.data[0], standards })));
      }),
      switchMap(({ skuData, skuHeader, skuSpec, standards }) => {
        const processingOrderStandardId = (standards as ISelectOption[]).filter(
          item => item.id === order.mainInfo.processingOrderStandardId
        )[0]?.value;

        const stencil = generateServiceStencil({
          serviceType: line.service.typeId,
          order,
          sku: skuData,
          headerSku: skuHeader,
          skuSpecification: skuSpec,
          processingOrderStandardId,
          spec: line.specification
        });

        return of({ ...line, specification: { ...line.specification, stencil } });
      })
    ) as Observable<ILine>;
  }
}
