import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { ERPButtonComponent } from './components';

@NgModule({
  declarations: [ERPButtonComponent],
  imports: [CommonModule, MatButtonModule],
  exports: [ERPButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ERPButtonModule {}
