import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';

import { ERPRoutingSpinnerComponent } from './components';

@NgModule({
  declarations: [ERPRoutingSpinnerComponent],
  exports: [ERPRoutingSpinnerComponent],
  imports: [CommonModule, RouterModule, MatProgressSpinnerModule]
})
export class ERPRoutingSpinnerModule {}
