<h1 class="dialog-header" mat-dialog-title>
  <ng-container *ngIf="header"> {{ header }}</ng-container>
  <ng-content select="[header]"></ng-content>
</h1>
<ng-container *ngIf="allowClose">
  <erp-button color="primary" variant="icon" class="dialog-close" [mat-dialog-close]="undefined">
    <erp-icon icon="action:cross"></erp-icon>
  </erp-button>
</ng-container>
<section class="dialog-content" mat-dialog-content>
  <ng-container *ngIf="message"> {{ message }}</ng-container>
  <ng-content select="[message]"></ng-content>
</section>
<section class="dialog-actions flex justify-content-flex-end" mat-dialog-actions>
  <erp-button
    class="margin-right-4"
    color="primary"
    variant="link"
    [mat-dialog-close]="cancelAction"
    *ngIf="allowCancel"
  >
    <ng-container *ngIf="cancel"> {{ cancel }}</ng-container>
    <ng-container *ngIf="!cancel" i18n="@@common.buttons.cancel">
      Cancel
    </ng-container>
    <ng-content select="[cancel]"></ng-content>
  </erp-button>
  <erp-button type="button" medium class="dialog-action-confirm" [color]="color" [mat-dialog-close]="true">
    <ng-container *ngIf="confirm"> {{ confirm }}</ng-container>
    <ng-container *ngIf="!confirm" i18n="@@common.buttons.confirm">
      Confirm
    </ng-container>
    <ng-content select="[confirm]"></ng-content>
  </erp-button>
</section>
