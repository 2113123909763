<ng-container [formGroup]="form">
  <div class="grid form-grid align-items-baseline">
    <div class="col-6">
      <erp-label>
        <ng-container i18n="@@common.address.label.line-1">
          Address Line 1:
        </ng-container>
      </erp-label>
    </div>
    <div class="col-6 mat-body-strong">
      <erp-input formControlName="addressLine1" *ngIf="mode !== 'view'"></erp-input>
      <ng-container *ngIf="mode === 'view'">{{ form.get('addressLine1')?.value }}</ng-container>
    </div>
    <div class="col-6">
      <erp-label>
        <ng-container i18n="@@common.address.label.line-2">
          Address Line 2:
        </ng-container>
      </erp-label>
    </div>
    <div class="col-6 mat-body-strong">
      <erp-input formControlName="addressLine2" *ngIf="mode !== 'view'"></erp-input>
      <ng-container *ngIf="mode === 'view'">{{ form.get('addressLine2')?.value }}</ng-container>
    </div>
    <div class="col-6">
      <erp-label>
        <ng-container i18n="@@common.address.label.city">
          City:
        </ng-container>
      </erp-label>
    </div>
    <div class="col-6 mat-body-strong">
      <erp-input formControlName="city" *ngIf="mode !== 'view'"></erp-input>
      <ng-container *ngIf="mode === 'view'">{{ form.get('city')?.value }}</ng-container>
    </div>
    <div class="col-6">
      <erp-label>
        <ng-container i18n="@@common.address.label.region">
          State/Province/Region:
        </ng-container>
      </erp-label>
    </div>
    <div class="col-6 mat-body-strong">
      <erp-input formControlName="region" *ngIf="mode !== 'view'"></erp-input>
      <ng-container *ngIf="mode === 'view'">{{ form.get('region')?.value }}</ng-container>
    </div>
    <div class="col-6">
      <erp-label>
        <ng-container i18n="@@common.address.label.zip">
          Zip:
        </ng-container>
      </erp-label>
    </div>
    <div class="col-6 mat-body-strong">
      <erp-input formControlName="zip" *ngIf="mode !== 'view'"></erp-input>
      <ng-container *ngIf="mode === 'view'">{{ form.get('zip')?.value }}</ng-container>
    </div>
    <div class="col-6">
      <erp-label [required]="mode !== 'view' && isCountryRequired">
        <ng-container i18n="@@common.address.label.country">
          Country:
        </ng-container>
      </erp-label>
    </div>
    <div class="col-6 mat-body-strong">
      <erp-select
        formControlName="countryId"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="countries"
        *ngIf="mode !== 'view'"
      ></erp-select>
      <ng-container *ngIf="mode === 'view'">
        {{ getCountryName(form.get('countryId')?.value) }}
      </ng-container>
    </div>
    <ng-container *ngIf="withPhone">
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@common.address.label.phone">
            Phone Number:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        <erp-input formControlName="phoneNumber" *ngIf="mode !== 'view'"></erp-input>
        <ng-container *ngIf="mode === 'view'">
          <a href="tel:{{ form.get('phoneNumber')?.value }}">{{ form.get('phoneNumber')?.value }}</a>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="withEmail">
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@common.address.label.email">
            Email:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        <erp-input formControlName="email" *ngIf="mode !== 'view'"></erp-input>
        <ng-container *ngIf="mode === 'view'"
          ><a href="mailto:{{ form.get('email')?.value }}">{{ form.get('email')?.value }}</a></ng-container
        >
      </div>
    </ng-container>
  </div>
</ng-container>
