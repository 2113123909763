<h1 class="mat-headline dialog-header margin-right-5" mat-dialog-title>
  <ng-container>Device Parameters</ng-container>
</h1>
<section class="dialog-content erp-tablet-module" mat-dialog-content>
  <div [formGroup]="form" class="grid grid-column-gap-c grid-row-gap-p">
    <div class="col-6">
      <erp-label [required]="true">
        <ng-container>Drift SN #:</ng-container>
      </erp-label>
      <erp-input formControlName="driftSnNumber" [isFocused]="true"></erp-input>
    </div>
    <div class="col-6">
      <erp-label [required]="true">
        <ng-container>Drift OD:</ng-container>
      </erp-label>
      <erp-input formControlName="driftOdNumber"></erp-input>
    </div>
    <div class="col-6">
      <erp-label [required]="true">
        <ng-container>OD Mic SN #:</ng-container>
      </erp-label>
      <erp-input formControlName="odMiscSnNumber"></erp-input>
    </div>
    <div class="col-6">
      <erp-label [required]="true">
        <ng-container>Calibration Due Date:</ng-container>
      </erp-label>
      <erp-date-picker [touchUi]="true" formControlName="calibrationDueDate"></erp-date-picker>
    </div>
    <div
      *ngFor="let line of form.get('driftVerifications')?.controls"
      [formGroup]="line"
      class="col-12 grid grid-column-gap-c grid-row-gap-p"
    >
      <div class="col-3">
        <erp-label [required]="true">
          <ng-container>Time:</ng-container>
        </erp-label>
        <erp-time-picker formControlName="verificationTime"></erp-time-picker>
      </div>
      <div class="col-3">
        <erp-label [required]="true">
          <ng-container>Edge 1:</ng-container>
        </erp-label>
        <erp-input formControlName="edge1"></erp-input>
      </div>
      <div class="col-3">
        <erp-label [required]="true">
          <ng-container>Edge 2:</ng-container>
        </erp-label>
        <erp-input formControlName="edge2"></erp-input>
      </div>
      <div class="col-3">
        <erp-label [required]="true">
          <ng-container>Center:</ng-container>
        </erp-label>
        <erp-input formControlName="center"></erp-input>
      </div>
    </div>
    <div class="col-6">
      <erp-button
        color="primary"
        variant="link"
        [disabled]="!canAddMeasurement || form.disabled"
        (click)="onAddMeasurement()"
      >
        <ng-container>+ Add Measurement </ng-container>
      </erp-button>
    </div>
  </div>
</section>
<section class="flex justify-content-flex-end erp-app erp-app-tablet" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" (click)="onCancel()" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button [disabled]="isSaveDisabled" medium class="dialog-action-confirm" color="accent" (click)="onSave()">
    <ng-container> Save </ng-container>
  </erp-button>
</section>
