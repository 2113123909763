import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import { SegregationFactory } from '../factories';
import {
  IPatchRequest,
  ISegregation,
  ISegregationHeat,
  ISegregationHeatList,
  ISegregationList,
  ISegregationMaterial,
  ISegregationResponse
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPSegregationService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient, readonly factory: SegregationFactory) {
    super('segregation');
  }

  getList(params: object) {
    return this.get<ICollectionResponse<ISegregationList>>(params);
  }

  getSegregation(id: string): Observable<ISegregation> {
    return this.get<ISegregationResponse>({}, id).pipe(map(response => this.factory.fromResponse(response)));
  }

  update(item: ISegregation): Observable<ISegregation> {
    const request = this.factory.toRequest(item);

    return this.put<ISegregationResponse>(request, {}, request.id).pipe(
      map(response => this.factory.fromResponse(response))
    );
  }

  create(item: ISegregation): Observable<ISegregation> {
    const request = this.factory.toRequest(item);

    return this.post<ISegregationResponse>(request).pipe(map(response => this.factory.fromResponse(response)));
  }

  confirmSegregation(id: number, request: IPatchRequest<ISegregationList>[]) {
    return this.patch<IPatchRequest<ISegregationList>[]>(request, {}, id);
  }

  getHeatNumbers(params: object): Observable<ICollectionResponse<ISegregationHeat>> {
    return this.get<ICollectionResponse<ISegregationHeat>>(params, 'heatNumber');
  }

  getHeatList(params: object): Observable<ICollectionResponse<ISegregationHeatList>> {
    return this.get<ICollectionResponse<ISegregationHeatList>>(params, 'heatList');
  }

  getMaterials(params?: object): Observable<ICollectionResponse<ISegregationMaterial>> {
    return this.get<ICollectionResponse<ISegregationMaterial>>(params, 'materials');
  }
}
