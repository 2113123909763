import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatToolbarModule } from '@angular/material/toolbar';

import { ERPIconModule } from '../icon';

import { ERPPanelBodyComponent, ERPPanelComponent, ERPPanelHeaderComponent } from './components';

@NgModule({
  declarations: [ERPPanelComponent, ERPPanelHeaderComponent, ERPPanelBodyComponent],
  imports: [CommonModule, MatToolbarModule, MatDividerModule, MatCardModule, MatExpansionModule, ERPIconModule],
  exports: [ERPPanelComponent, ERPPanelHeaderComponent, ERPPanelBodyComponent]
})
export class ERPPanelModule {}
