import { AbstractControl, ValidatorFn } from '@angular/forms';

export function LessThanMultipleDynamicValidator(comparators: string[], comparable: string): ValidatorFn {
  return (control: AbstractControl) => {
    const comparatorsValue = comparators.map(comparator => control.get(comparator)?.value ?? 0);
    const comparableValue = control.get(comparable)?.value;

    const valid = comparatorsValue.every(comparatorValue => !comparableValue || comparableValue <= comparatorValue);

    if (!valid) {
      control.get(comparable)?.setErrors({ ...control.get(comparable)?.errors, lessThanMultipleDynamic: true });
    } else {
      removeFormControlError(control.get(comparable) as AbstractControl, 'lessThanMultipleDynamic');
    }

    return valid ? null : { lessThanMultipleDynamic: true };
  };
}

function removeFormControlError(control: AbstractControl, errorName: string) {
  if (control?.errors && control?.errors[errorName]) {
    delete control.errors[errorName];
    if (Object.keys(control.errors).length === 0) {
      control.setErrors(null);
    }
  }
}
