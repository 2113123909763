import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

import { IToolbarAction } from '../../../toolbar';

@Component({
  selector: 'erp-actions-select',
  templateUrl: './actions-select.component.html',
  styleUrls: ['./actions-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPActionsSelectComponent {
  @Input() actionList: IToolbarAction[];
  @Input() title: string;
  @Input() disabled = false;
  @Input() params?: object;
  @Input() readonly color: ThemePalette = 'primary';

  constructor(readonly changeDetector: ChangeDetectorRef) {}

  emitCall(item: IToolbarAction) {
    this.params ? item.action(this.params) : item.action();
  }
}
