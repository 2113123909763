<div class="material-details mat-elevation-z3 padding-right-8">
  <div
    class="material-details__header padding-left-6 padding-right-6 flex justify-content-space-between align-items-center margin-top-1"
  >
    <span class="material-details__header__title">
      <ng-container i18n="@@inventory.setup.adjustment.material-details.title">
        MATERIAL DETAILS
      </ng-container>
    </span>
    <erp-button dense color="primary" variant="link" (click)="opened = !opened">
      <ng-container *ngIf="opened" i18n="@@inventory.setup.adjustment.material-details.hide-btn">
        Hide
      </ng-container>
      <ng-container *ngIf="!opened" i18n="@@inventory.setup.adjustment.material-details.show-btn">
        Show
      </ng-container>

      <erp-icon [icon]="opened ? 'action:chevron-down' : 'action:chevron-up'"></erp-icon>
    </erp-button>
  </div>
  <div class="material-details__body grid align-content-stretch margin-right-7" *ngIf="product">
    <div class="col-3 padding-top-6 padding-left-6 grid align-items-baseline grid-gap-p">
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.grade">
            Grade:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.grade ? product.grade : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <!--  Label "Size" changed to "OD" per customer request -->
          <ng-container i18n="@@components.material-details.size">
            OD:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.size ? product.size : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.wall">
            Wall:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.wall ? product.wall : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.design">
            Design:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.design ? product.design : '-' }}
      </div>
    </div>
    <div class="col-3 padding-top-6 padding-left-6 grid align-items-baseline grid-gap-p">
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.connection">
            Connection:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.connection ? product.connection : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <!--  Label "Thread" changed to "End type" per customer request -->
          <ng-container i18n="@@components.material-details.end-type">
            End type:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.thread ? product.thread : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.range">
            Range:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.range ? product.range : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.condition">
            Condition:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.condition ? product.condition : '-' }}
      </div>
    </div>
    <div class="col-3 padding-top-6 padding-left-6 grid align-items-baseline grid-gap-p">
      <div class="col-6">
        <erp-label>
          <ng-container i18n="components.material-details.weight">
            Weight per Foot:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.weight ? product.weight : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.protector-type">
            Protector type:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.protectorType ? product.protectorType : '-' }}
      </div>
      <div class="col-6">
        <erp-label>
          <ng-container i18n="@@components.material-details.specialty">
            Specialty:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-6 mat-body-strong">
        {{ product?.specialty ? product.specialty : '-' }}
      </div>
    </div>
    <div
      [class.mat-app-background]="opened"
      class="col-3 padding-top-6 padding-left-6 grid align-items-baseline grid-gap-p"
    >
      <div class="col-7">
        <erp-label>
          <ng-container i18n="@@components.material-details.mill">
            Mill:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-5 mat-body-strong">
        <erp-trim-text
          [size]="trimmedSize"
          [text]="product?.materialMillName ? product.materialMillName : '-'"
        ></erp-trim-text>
      </div>
      <div class="col-7">
        <erp-label>
          <ng-container i18n="@@inventory.setup.adjustment.material-details.owner">
            Owner:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-5 mat-body-strong">
        <erp-trim-text
          [size]="trimmedSize"
          [text]="product?.materialOwnerName ? product.materialOwnerName : '-'"
        ></erp-trim-text>
      </div>
      <div class="col-7">
        <erp-label>
          <ng-container i18n="@@inventory.setup.adjustment.material-details.wa-cost">
            WA Cost, $:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-5 mat-body-strong">
        -
      </div>
      <div class="col-7">
        <erp-label>
          <ng-container i18n="@@inventory.setup.adjustment.material-details.material-cost">
            Cost for Material ID, $:
          </ng-container>
        </erp-label>
      </div>
      <div class="col-5 mat-body-strong">
        -
      </div>
    </div>
  </div>

  <div
    class="material-details__make-choice padding-left-8 flex justify-content-center align-items-center erp-table-nothing-found"
    *ngIf="!product && opened"
  >
    <ng-container i18n="@@inventory.setup.adjustment.material-details.please-select">
      Please, select SKU to see details
    </ng-container>
  </div>
</div>
