<mat-nav-list>
  <!-- LOGISTIC -->
  <erp-sidenav-expansion-panel
    *ngIf="
      roles &&
      (roles[prefix + ':unloading-authorizations']?.allowed ||
        roles[prefix + ':loading-authorizations']?.allowed ||
        roles[prefix + ':movements']?.allowed ||
        roles[prefix + ':transfer-orders']?.allowed ||
        roles[prefix + ':material-id-history']?.allowed ||
        roles[prefix + ':shipping-report']?.allowed ||
        roles[prefix + ':receiving-report']?.allowed ||
        roles[prefix + ':inventory-adjustment']?.allowed ||
        roles[prefix + ':transportation-companies']?.allowed)
    "
    i18n-header="logistic.sidebar.title"
    header="Logistics and Warehousing"
  >
    <a
      *ngIf="roles && roles[prefix + ':unloading-authorizations']?.allowed"
      i18n="@@logistic.unloading-auth.link"
      mat-list-item
      routerLink="/logistic-and-warehousing/unloading-authorizations"
      routerLinkActive="active"
    >
      Unloading Authorizations
    </a>
    <a
      *ngIf="roles && roles[prefix + ':loading-authorizations']?.allowed"
      i18n="@@logistic.loading-auth.link"
      mat-list-item
      routerLink="/logistic-and-warehousing/loading-authorizations"
      routerLinkActive="active"
    >
      Loading Authorizations
    </a>
    <!-- moved from inventory: -->
    <a
      *ngIf="roles && roles[prefix + ':movements']?.allowed"
      i18n="@@inventory.setup.common.sidebar..movements.link"
      mat-list-item
      routerLink="/inventory/setup/movements"
      routerLinkActive="active"
    >
      Movements
    </a>
    <a
      *ngIf="roles && roles[prefix + ':transfer-orders']?.allowed"
      i18n="@@logistic.transfer-orderes.link"
      mat-list-item
      routerLink="/logistic-and-warehousing/transfer-orders"
      routerLinkActive="active"
    >
      Transfer Orders
    </a>
    <!-- moved from inquiries -->
    <a
      *ngIf="roles && roles[prefix + ':material-id-history']?.allowed"
      i18n="@@inventory.setup.common.sidebar.material-id-history.link"
      mat-list-item
      routerLink="/inventory/inquiries/material-id-history"
      routerLinkActive="active"
    >
      Material ID History
    </a>

    <span
      class="nav-list-caption"
      i18-n="@@logistic.sidenav.reports.title"
      *ngIf="(roles && roles[prefix + ':shipping-report']?.allowed) || roles[prefix + ':receiving-report']?.allowed"
      >Reports</span
    >
    <a *ngIf="roles && roles[prefix + ':shipping-report']?.allowed" mat-list-item (click)="onShippingReport()">
      <erp-icon class="margin-right-1" icon="action:print"></erp-icon>
      <ng-container i18n="@@logistic.shipping-report.link"> Shipping Report </ng-container>
    </a>
    <a *ngIf="roles && roles[prefix + ':receiving-report']?.allowed" mat-list-item (click)="onReceivingReport()">
      <erp-icon class="margin-right-1" icon="action:print"></erp-icon>
      <ng-container i18n="@@logistic.receiving-report.link"> Receiving Report </ng-container>
    </a>
    <a *ngIf="roles && roles[prefix + ':on-hand-report']?.allowed" mat-list-item (click)="onOnHandReport()">
      <erp-icon class="margin-right-1" icon="action:print"></erp-icon>
      <ng-container i18n="@@logistic.on-hand-report.link"> On-hand Report To Date </ng-container>
    </a>
    <a *ngIf="roles && roles[prefix + ':receiving-report']?.allowed" mat-list-item (click)="onShippingPackageReport()">
      <erp-icon class="margin-right-1" icon="action:print"></erp-icon>
      <ng-container i18n="@@logistic.shipping-package-report.link"> Shipping Package Report </ng-container>
    </a>
    <a *ngIf="roles && roles[prefix + ':receiving-report']?.allowed" mat-list-item (click)="onStorageReport()">
      <erp-icon class="margin-right-1" icon="action:print"></erp-icon>
      <ng-container i18n="@@logistic.storage-report.link"> Storage Report </ng-container>
    </a>
    <a *ngIf="roles && roles[prefix + ':receiving-report']?.allowed" mat-list-item (click)="onStorageReportFTZ()">
      <erp-icon class="margin-right-1" icon="action:print"></erp-icon>
      <ng-container i18n="@@logistic.storage-report-ftz.link"> Storage Report FTZ </ng-container>
    </a>
    <a
      *ngIf="roles && roles[prefix + ':inventory-adjustment']?.allowed"
      i18n="@@inventory.setup.common.sidebar.inventory-adjustment.link"
      mat-list-item
      routerLink="/inventory/setup/inventory-adjustment"
      routerLinkActive="active"
    >
      Inventory Adjustment
    </a>
    <a
      *ngIf="roles && roles[prefix + ':transportation-companies']?.allowed"
      i18n="@@logistic.transportation-companies.link"
      mat-list-item
      routerLink="/logistic-and-warehousing/transportation-companies"
      routerLinkActive="active"
    >
      Transportation Companies
    </a>
  </erp-sidenav-expansion-panel>
  <!-- SALES -->
  <erp-sidenav-expansion-panel
    i18n-header="@@sales.sidebar.title"
    header="Sales"
    *ngIf="
      roles &&
      (roles[prefix + ':sales-quotes']?.allowed ||
        roles[prefix + ':sales-orders']?.allowed ||
        roles[prefix + ':sales-goods-issue']?.allowed ||
        roles[prefix + ':sales-customers']?.allowed ||
        roles[prefix + ':sales-contacts']?.allowed ||
        roles[prefix + ':sales-material-releases']?.allowed)
    "
  >
    <a
      *ngIf="roles && roles[prefix + ':sales-quotes']?.allowed"
      mat-list-item
      routerLink="/sales/sales-quotes"
      routerLinkActive="active"
      i18n="@@sales.sidebar.sales-quotes.link"
    >
      Sales Quotes
    </a>
    <a
      *ngIf="roles && roles[prefix + ':sales-orders']?.allowed"
      mat-list-item
      routerLink="/sales/sales-orders"
      routerLinkActive="active"
      i18n="@@sales.sidebar.sales-orders.link"
    >
      Sales Orders
    </a>
    <a
      *ngIf="roles && roles[prefix + ':sales-goods-issue']?.allowed"
      mat-list-item
      routerLink="/sales/goods-issues"
      routerLinkActive="active"
      i18n="@@sales.sidebar.goods-issues.link"
    >
      Goods Issue
    </a>
    <a
      *ngIf="roles && roles[prefix + ':sales-customers']?.allowed"
      mat-list-item
      routerLink="/sales/customers"
      routerLinkActive="active"
      i18n="@@sales.sidebar.customers.link"
    >
      Customers
    </a>
    <a
      *ngIf="roles && roles[prefix + ':sales-contacts']?.allowed"
      mat-list-item
      routerLink="/sales/contacts"
      routerLinkActive="active"
      i18n="@@sales.sidebar.contacts.link"
    >
      Contacts
    </a>
    <a
      *ngIf="roles && roles[prefix + ':sales-material-releases']?.allowed"
      mat-list-item
      routerLink="/sales/material-release"
      routerLinkActive="active"
    >
      Material Releases
    </a>
  </erp-sidenav-expansion-panel>
  <!-- PROCUREMENT -->
  <erp-sidenav-expansion-panel
    *ngIf="
      roles &&
      (roles[prefix + ':procurement-purchase-requisitions']?.allowed ||
        roles[prefix + ':procurement-purchase-orders']?.allowed ||
        roles[prefix + ':procurement-receipts']?.allowed ||
        roles[prefix + ':procurement-inbound-deliveries']?.allowed ||
        roles[prefix + ':procurement-vendors']?.allowed)
    "
    i18n-header="procurement.inventory-transaction.sidebar.inventory-transactions.title"
    header="Procurement"
  >
    <a
      *ngIf="roles && roles[prefix + ':procurement-purchase-requisitions']?.allowed"
      i18n="@@procurement.inventory-transaction.purchase-requisitions.link"
      mat-list-item
      routerLink="/procurement/inventory-transactions/purchase-requisitions"
      routerLinkActive="active"
      >Purchase requisitions</a
    >

    <a
      *ngIf="roles && roles[prefix + ':procurement-purchase-orders']?.allowed"
      i18n="@@procurement.inventory-transaction.purchase-orders.link"
      mat-list-item
      routerLink="/procurement/inventory-transactions/purchase-orders"
      routerLinkActive="active"
      >Purchase orders</a
    >

    <a
      *ngIf="roles && roles[prefix + ':procurement-receipts']?.allowed"
      i18n="@@procurement.inventory-transaction.receipts.link"
      mat-list-item
      routerLink="/procurement/inventory-transactions/receipts"
      routerLinkActive="active"
      >Receipts</a
    >
    <a
      *ngIf="roles && roles[prefix + ':procurement-inbound-deliveries']?.allowed"
      i18n="@@procurement.inventory-transaction.inbound-deliveries.link"
      mat-list-item
      routerLink="/procurement/inventory-transactions/inbound-deliveries"
      routerLinkActive="active"
      >Inbound Deliveries</a
    >
    <a
      *ngIf="roles && roles[prefix + ':procurement-vendors']?.allowed"
      i18n="@@procurement.inventory-transaction.vendors.link"
      mat-list-item
      routerLink="/procurement/inventory-transactions/vendors"
      routerLinkActive="active"
      >Vendors
    </a>
  </erp-sidenav-expansion-panel>
  <!-- PRODUCTION -->
  <erp-sidenav-expansion-panel
    i18n-header="@@production.sidebar.title"
    header="Production"
    *ngIf="
      roles &&
      (roles[prefix + ':production-process-orders']?.allowed ||
        roles[prefix + ':production-services']?.allowed ||
        roles[prefix + ':production-work-centers']?.allowed)
    "
  >
    <a
      *ngIf="roles && roles[prefix + ':production-process-orders']?.allowed"
      i18n="@@production.sidebar.processing-order.link"
      mat-list-item
      routerLink="/production/processing-order"
      routerLinkActive="active"
      >Process Orders</a
    >
    <a
      *ngIf="roles && roles[prefix + ':production-services']?.allowed"
      i18n="@@production.sidebar.services.link"
      mat-list-item
      routerLink="/production/services"
      routerLinkActive="active"
      >Services</a
    >
    <a
      *ngIf="roles && roles[prefix + ':production-work-centers']?.allowed"
      i18n="@@production.sidebar.work-centers.link"
      mat-list-item
      routerLink="/production/work-centers"
      routerLinkActive="active"
    >
      Work Centers
    </a>
  </erp-sidenav-expansion-panel>
  <!-- PLANNING -->
  <erp-sidenav-expansion-panel
    i18n-header="@@planning.production.common.sidebar.title"
    header="Planning"
    *ngIf="
      roles &&
      (roles[prefix + ':planning-board']?.allowed || roles[prefix + ':planning-schedule-per-operation']?.allowed)
    "
  >
    <a
      *ngIf="roles && roles[prefix + ':planning-board']?.allowed"
      mat-list-item
      i18n="@@planning.production.common.sidebar.planning"
      routerLink="/planning/planning"
      routerLinkActive="active"
      >Planning Board</a
    >
    <a
      *ngIf="roles && roles[prefix + ':planning-schedule-per-operation']?.allowed"
      mat-list-item
      i18n="@@planning.production.common.sidebar.production-scheduling'"
      routerLink="/planning/production-scheduling"
      routerLinkActive="active"
      >Schedule per Operation</a
    >
  </erp-sidenav-expansion-panel>
  <!-- INVENTORY -->
  <a
    *ngIf="roles && roles[prefix + ':inventory-on-hand']?.allowed"
    i18n="@@inventory.setup.common.sidebar.inventory-on-hand.link"
    mat-list-item
    routerLink="/inventory/setup/on-hand"
    routerLinkActive="active"
    class="standalone"
  >
    Inventory on-hand
  </a>
  <!-- QUALITY CONTROL -->
  <erp-sidenav-expansion-panel
    *ngIf="roles && roles[prefix + ':quality-yarn-ncp']?.allowed"
    i18n-header="@@quality-control.sidebar.title"
    header="Quality Control"
  >
    <a
      *ngIf="roles && roles[prefix + ':quality-yarn-ncp']?.allowed"
      mat-list-item
      i18n="@@tablet.production.sidebar.quality-control.link"
      routerLink="/quality-control/yard-ncp"
      routerLinkActive="active"
      >Yard NCP</a
    >
  </erp-sidenav-expansion-panel>
  <!-- ADMINISTRATION -->
  <erp-sidenav-expansion-panel
    *ngIf="
      (roles && roles[prefix + ':administration-inventory-maintenance']?.allowed) ||
      roles[prefix + ':administration-security']?.allowed
    "
    i18n-header="@@administration.user-roles.common.sidebar.title"
    header="Administration"
  >
    <ng-container *ngIf="roles && roles[prefix + ':administration-inventory-maintenance']?.allowed">
      <span class="nav-list-caption" i18-n="@@administration.sidenav.inventory-maintenance.title"
        >Inventory Maintenance</span
      >
      <a
        i18n="@@inventory.setup.common.sidebar.manufacturer.link"
        mat-list-item
        routerLink="/inventory/setup/manufacturer"
        routerLinkActive="active"
        >Manufacturer</a
      >
      <!--  Label "Location" changed to "Site" per customer request -->
      <a
        i18n="@@inventory.setup.common.sidebar.site.link"
        mat-list-item
        routerLink="/inventory/setup/location"
        routerLinkActive="active"
        >Site</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.casing.link"
        mat-list-item
        routerLink="/inventory/product-types/casing"
        routerLinkActive="active"
        >Casing</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.tubing.link"
        mat-list-item
        routerLink="/inventory/product-types/tubing"
        routerLinkActive="active"
        >Tubing</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.line-pipe.link"
        mat-list-item
        routerLink="/inventory/product-types/line-pipe"
        routerLinkActive="active"
        >Line Pipe</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.drill-pipe.link"
        mat-list-item
        routerLink="/inventory/product-types/drill-pipe"
        routerLinkActive="active"
        >Drill Pipe</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.markers.link"
        mat-list-item
        routerLink="/inventory/product-types/marker"
        routerLinkActive="active"
        >Markers</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.pup-joints.link"
        mat-list-item
        routerLink="/inventory/product-types/pup-joint"
        routerLinkActive="active"
        >Pup Joints</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.cross-over.link"
        mat-list-item
        routerLink="/inventory/product-types/cross-over"
        routerLinkActive="active"
        >Cross Over</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.coupling-stock.link"
        mat-list-item
        routerLink="/inventory/product-types/coupling-stock"
        routerLinkActive="active"
        >Coupling Stock</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.coupling-material.link"
        mat-list-item
        routerLink="/inventory/product-types/coupling-material"
        routerLinkActive="active"
        >Coupling Material</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.coupling.link"
        mat-list-item
        routerLink="/inventory/product-types/coupling"
        routerLinkActive="active"
        >Coupling</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.protector.link"
        mat-list-item
        routerLink="/inventory/product-types/protector"
        routerLinkActive="active"
        >Protector</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.service.link"
        mat-list-item
        routerLink="/inventory/product-types/service"
        routerLinkActive="active"
        >Service</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.consumables.link"
        mat-list-item
        routerLink="/inventory/product-types/consumables"
        routerLinkActive="active"
        >Consumables</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.maintenance-part.link"
        mat-list-item
        routerLink="/inventory/product-types/maintenance-part"
        routerLinkActive="active"
        >Maintenance Part</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.operation-part.link"
        mat-list-item
        routerLink="/inventory/product-types/operation-part"
        routerLinkActive="active"
        >Operation Part</a
      >
      <a
        i18n="@@inventory.product-type.common.sidebar.other.link"
        mat-list-item
        routerLink="/inventory/product-types/other"
        routerLinkActive="active"
        >Other</a
      >
    </ng-container>

    <!-- Hide in scope of RTED-11291 -->
    <!-- <span class="nav-list-caption" i18-n="@@inventory.setup.common.sidebar.other">Other</span>
    <a
      mat-list-item
      routerLink="/inventory/setup/aging-report"
      routerLinkActive="active"
      i18n="@@inventory.setup.common.sidebar.aging-report.link"
      >Aging Report</a
    > -->
    <ng-container *ngIf="roles && roles[prefix + ':administration-security']?.allowed">
      <span class="nav-list-caption" i18-n="@@administration.sidenav.security.title">Security</span>
      <a
        i18n="@@administration.user-roles.common.sidebar.user-roles.link"
        mat-list-item
        routerLink="/administration/user-roles"
        routerLinkActive="active"
        >User Roles</a
      >
    </ng-container>
  </erp-sidenav-expansion-panel>
</mat-nav-list>
