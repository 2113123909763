import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import { IPatchRequest, IWorkCenter, IWorkCentersList } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPWorkCenterService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('workCenters');
  }

  getWorkCenters(params: object) {
    return this.get<ICollectionResponse<IWorkCentersList>>(params);
  }
  getAllowedWorkCenters(params: object) {
    return this.get<ICollectionResponse<IWorkCentersList>>(params, 'allowed');
  }

  getWorkCentersDescription(params: object) {
    return this.getWorkCenters(params).pipe(map(response => response?.data.map(item => item.description)));
  }

  getById(id: string) {
    return this.get<IWorkCenter>({}, id);
  }

  deleteById(id: string) {
    return this.delete<IWorkCenter>({}, id);
  }

  createWorkCenter(data: IWorkCenter) {
    return this.post<IWorkCenter>(data, {}, ``);
  }

  updateWorkCenter(data: IWorkCenter) {
    return this.put<IWorkCenter>(data, {}, data.id);
  }

  patchItem(id: number, request: IPatchRequest<IWorkCentersList>[]) {
    return this.patch<IPatchRequest<IWorkCentersList>[]>(request, {}, id);
  }
}
