export type LoadingReportType =
  | 'receiving'
  | 'shipping'
  | 'shipping-package'
  | 'storage-report'
  | 'storage-report-ftz'
  | 'on-hand';

export interface ILoadingReport {
  customerId: number;
  customerName: string;
  minDateIn: string;
  maxDateIn: string;
  POId: number;
  InboundDeliveryId: number;
  SOId: number;
  MRId: number;
  materialId: number[];
  customerReleaseNumber: string[];
  gracePeriod: number;
  pricePerTon: number;
}

export enum ILoadingReportEnum {
  RECEIVING = 'receiving',
  SHIPPING = 'shipping',
  SHIPPING_PACKAGE = 'shipping-package',
  STORAGE_REPORT = 'storage-report',
  STORAGE_REPORT_FTZ = 'storage-report-ftz'
}

export interface IOnHandReport {
  date: string;
  customerId: number;
}
