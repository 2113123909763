import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { ERPSharedModule } from '../../../../../shared/src/lib';
import { ERPIconModule } from '../icon';

import { ERPAutocompleteComponent } from './autocomplete.component';

@NgModule({
  declarations: [ERPAutocompleteComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    ERPIconModule,
    MatButtonModule,
    ERPSharedModule
  ],
  exports: [ERPAutocompleteComponent, ReactiveFormsModule]
})
export class ERPAutocompleteModule {}
