import { AbstractControl } from '@angular/forms';

export function UniqueByValidator<T>(
  properties: (keyof T)[],
  selectors?: Partial<{ [P in keyof T]: (entry: T[P]) => unknown }>
) {
  return (control: AbstractControl) => {
    const lines = control.value as T[];

    const valid = lines.every((lineCompare, indexCompare) =>
      lines.every((lineCompareTo, indexCompareTo) => {
        return (
          indexCompare === indexCompareTo ||
          properties.some(prop => {
            const propCompare = selectors?.[prop]?.(lineCompare[prop]) ?? lineCompare[prop];
            const propCompareTo = selectors?.[prop]?.(lineCompareTo[prop]) ?? lineCompareTo[prop];

            return propCompare !== propCompareTo;
          })
        );
      })
    );

    return valid ? null : { 'unique-by': true };
  };
}
