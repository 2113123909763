import { identity } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ERPTabletVolumetricGrowthDialogComponent } from '../components/volumetric-growth-dialog/volumetric-growth-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ERPVolumetricGrowthService {
  constructor(private dialogService: MatDialog) {}

  openDialog(mode: 'create' | 'view' | 'edit', volumetricData?: object) {
    return this.dialogService
      .open(ERPTabletVolumetricGrowthDialogComponent, {
        data: { mode, volumetricData }
      })
      .afterClosed()
      .pipe(filter(identity));
  }
}
