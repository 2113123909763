import { InjectionToken } from '@angular/core';

export const ERP_DEFAULT_TABLE_PAGING = new InjectionToken('ERP_DEFAULT_TABLE_PAGING');
export const ERP_HUGE_TABLE_PAGING = new InjectionToken('ERP_HUGE_TABLE_PAGING');
export const ERP_MEDIUM_TABLE_PAGING = new InjectionToken('ERP_MEDIUM_TABLE_PAGING');
export const ERP_LARGE_TABLE_PAGING = new InjectionToken('ERP_LARGE_TABLE_PAGING');
export const ERP_READY_FOR_PICKUP_TABLE_PAGING = new InjectionToken('ERP_READY_FOR_PICKUP_TABLE_PAGING');
export const ERP_SMALL_TABLE_PAGING = new InjectionToken('ERP_SMALL_TABLE_PAGING');
export const ERP_EXTRASMALL_TABLE_PAGING = new InjectionToken('ERP_EXTRASMALL_TABLE_PAGING');
export const ERP_PO_LIST_TABLET_PAGING = new InjectionToken('ERP_PO_LIST_TABLET_PAGING');
