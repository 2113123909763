import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

import { ISelectOption, TableFilterType } from '@erp/components';

import { ERPTableFilterOpener } from '../../../../../components/src/lib/modules/table/services';
import { TableQueryBuilder } from '../../builders';
import { IInventoryUom } from '../../interfaces';
import { IdName } from '../../types';
import { BaseArrayComponent, ERPFormStateDispatcher } from '../controls';
import { IMetadataFiltering, IMetadataSorting } from '../http';
import { IDestroyable } from '../lifecycle';

type labelFnType = (val: IInventoryUom) => string;
type valueFnType = (val: IInventoryUom[], id: number) => string;

@Directive()
export abstract class BaseEditableTableComponent<T> extends BaseArrayComponent<T> implements IDestroyable {
  readonly destroyed$: Observable<unknown>;

  abstract readonly tableFilterOpener: ERPTableFilterOpener;
  abstract readonly formState: ERPFormStateDispatcher;
  abstract readonly changeDetector: ChangeDetectorRef;

  protected filters: IMetadataFiltering<T>[] = [];
  protected sorters: IMetadataSorting<T>[] = [];
  editableRow = -1;
  selectedRow = -1;
  readonly form = new UntypedFormArray([]);
  readonly dataSource = new MatTableDataSource<number | T>([]);
  @ViewChild(MatSort, { static: true }) readonly sort: MatSort;
  @Output() readonly filteringChange = new EventEmitter<IMetadataFiltering<T>[]>();
  @Output() readonly localFilteringChange = new EventEmitter<IMetadataFiltering<T>[]>();
  @Output() readonly sortingChange = new EventEmitter<IMetadataSorting<T>[]>();
  @Output() readonly localSortingChange = new EventEmitter<IMetadataSorting<T>[]>();

  @Input() readonly localQueryColumns: (keyof T)[] = [];

  @Input()
  set filtering(filters: IMetadataFiltering<T>[]) {
    if (!filters) {
      return;
    }
    if (this.form.at(this.editableRow)?.invalid) {
      this.filteringChange.emit(this.filters);
      this.formState.onSubmit.notify();

      return;
    }

    this.filters = filters;

    this.onRebuildTable?.();
  }

  get filtering() {
    return this.filters;
  }

  get localFiltering() {
    return this.filtering.filter(x => this.localQueryColumns.includes(x.by as keyof T));
  }

  get localSorting() {
    return this.sorting.filter(x => this.localQueryColumns.includes(x.by as keyof T));
  }

  @Input()
  set sorting(sorters: IMetadataSorting<T>[]) {
    if (sorters?.length) {
      this.sort.active = sorters[0]?.by as string;
      this.sort.direction = sorters[0]?.order;
    } else {
      this.sort.sort({
        id: null as unknown as string,
        start: 'asc',
        disableClear: true
      });
    }

    this.sorters = sorters;

    this.changeDetector.markForCheck();
  }

  get sorting() {
    return this.sorters;
  }

  abstract onRebuildTable?(): void;

  onRowEdit(index: number) {
    if (this.editableRow === index) {
      return;
    }

    if (this.form.at(this.editableRow)?.invalid && this.editableRow !== -1) {
      return this.formState.onSubmit.notify();
    }

    this.editableRow = index;
    this.selectedRow = index;
  }

  onRowSelect(index: number) {
    if (this.selectedRow === index) {
      return;
    }

    if (this.form.at(this.editableRow)?.invalid && this.editableRow !== -1) {
      return this.formState.onSubmit.notify();
    }

    this.selectedRow = index;
    this.editableRow = -1;
  }

  onFilterOpen(
    event: MouseEvent,
    column: keyof T,
    type: TableFilterType,
    options?: ISelectOption<unknown>[],
    labelFn?: labelFnType,
    displayFn?: valueFnType
  ) {
    event.stopPropagation();

    if (this.form.at(this.editableRow)?.invalid) {
      return this.formState.onSubmit.notify();
    }

    const target = event.target as HTMLElement;
    const trigger = target.closest('[mat-header-cell]') as HTMLElement;

    const current =
      this.filters?.find(x => x.by === column) ??
      ({
        by: column as string
      } as IMetadataFiltering<T>);

    this.tableFilterOpener
      .open(type, {
        filter: current,
        trigger,
        options,
        labelFn,
        displayFn
      })
      .subscribe((updated: IMetadataFiltering<T> | null) => {
        this.updateFiltering(updated, current);
      });
  }

  private isLocalQueryColumn(column: keyof T) {
    return this.localQueryColumns.includes(column);
  }

  private updateFiltering(updated: IMetadataFiltering<T> | null, current: IMetadataFiltering<T>) {
    const isLocalQueryColumn = this.isLocalQueryColumn(current.by as keyof T);
    const builder = new TableQueryBuilder({
      filtering: this.filtering
    });

    if (updated) {
      builder.setFilter(updated);
    } else {
      builder.removeFilter(current);
    }

    const { filtering } = builder.build();

    this.filters = filtering;

    if (isLocalQueryColumn) {
      this.localFilteringChange.emit(filtering);
    } else {
      this.filteringChange.emit(filtering);
    }

    this.onRebuildTable?.();
  }

  hasFilter(column: keyof T) {
    return this.filters.some(x => x.by === column);
  }

  onInit() {
    this.initChangeSortListener();
  }

  initChangeSortListener() {
    this.sort.sortChange
      .pipe(
        takeUntil(this.destroyed$),
        filter(sort => !!sort.active)
      )
      .subscribe(sort => {
        if (this.form.at(this.editableRow)?.invalid) {
          return this.formState.onSubmit.notify();
        }

        const sorters = sort.direction ? [{ by: sort.active, order: sort.direction }] : [];
        const isLocalQueryColumn = this.isLocalQueryColumn(sort.active as keyof T);

        this.sorters = sorters;

        if (isLocalQueryColumn) {
          this.localSortingChange.emit(sorters);
        } else {
          this.sortingChange.emit(sorters);
        }

        this.onRebuildTable?.();
      });
  }
}
