import '@angular/localize/init';

import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { IDestroyable } from '@erp/shared';

import { IToolbarAction } from '../../interfaces/toolbar-actions.interface';

@Component({
  selector: 'erp-toolbar-actions',
  templateUrl: './toolbar-actions.component.html',
  styleUrls: ['./toolbar-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPToolbarActionsComponent implements OnInit, IDestroyable {
  @Input() actionList: IToolbarAction[] = [];
  @Input() moreActionList: IToolbarAction[] = [];

  title: string = $localize`:@@common.buttons.actions:Actions`;

  isSmallScreen: boolean;
  readonly destroyed$ = new Subject<void>();

  constructor(readonly breakpointObserver: BreakpointObserver, readonly changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.breakpointObserver
      .observe([`(max-width: 1740px)`])
      .pipe(
        takeUntil(this.destroyed$),
        map((res: BreakpointState) => res.matches)
      )
      .subscribe(result => {
        this.isSmallScreen = result;
        this.setTitle();
        this.changeDetector.markForCheck();
      });
  }

  private setTitle(): void {
    if (this.actionList.length && this.isSmallScreen) {
      this.title = $localize`:@@common.buttons.actions:Actions`;

      return;
    }

    if (this.moreActionList.length && !this.isSmallScreen) {
      this.title = $localize`:@@common.buttons.more-actions:More Actions`;

      return;
    }
  }
}
