import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const DEFAULT_TRIMMED_SIZE = 50;

@Component({
  selector: 'erp-trim-text',
  templateUrl: './trim-text.component.html',
  styleUrls: ['./trim-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPTrimTextComponent {
  @Input() readonly text: string;
  @Input() readonly size = DEFAULT_TRIMMED_SIZE;
}
