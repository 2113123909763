import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'erpDateTime'
})
export class ERPDateTimePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: Date | number | string): string | null {
    try {
      return new DatePipe(this.locale).transform(value, 'MM/dd/yyyy HH:mm');
    } catch {
      return value as string | null;
    }
  }
}
