import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import {
  ERPAuthService,
  ERP_MEDIUM_TABLE_PAGING,
  ICollectionResponse,
  ICollectionResponseMetadata,
  IMetadataPaging
} from '@erp/shared';

import { INotification } from '../interfaces';
import { ERPNotificationService } from '../services';

const PER_PAGE = 12;

@Injectable({ providedIn: 'root' })
export class ERPNotificationListResolver {
  constructor(
    @Inject(ERP_MEDIUM_TABLE_PAGING)
    readonly paging: IMetadataPaging,
    readonly notificationService: ERPNotificationService,
    readonly authService: ERPAuthService
  ) {}

  get defaultQuery() {
    return {
      paging: { ...this.paging, perPage: PER_PAGE },
      sorting: [],
      filtering: []
    } as ICollectionResponseMetadata;
  }

  resolve(route: ActivatedRouteSnapshot) {
    const query = route.queryParamMap.get('query');
    const metadata: ICollectionResponseMetadata = query ? JSON.parse(query) : this.defaultQuery;
    const userId = this.authService.getCustomUserID();

    return this.notificationService
      .getList(userId, {
        query: JSON.stringify(metadata)
      })
      .pipe(
        catchError(() => {
          return of({
            data: [],
            metadata
          });
        })
      );
  }
}
