import { AbstractControl } from '@angular/forms';

export function MaxFractionDigitsValidator(fraction: number) {
  return (control: AbstractControl) => {
    const value = control.value as string | number | null;
    const string = typeof value === 'number' ? value.toString() : typeof value === 'string' ? value : '';
    const [, decimals] = string.split('.');
    const actualDigits = decimals?.length ?? 0;
    const valid = !value || actualDigits <= fraction;

    return valid
      ? null
      : {
          maxFractionDigits: {
            maxDigits: fraction,
            actualDigits
          }
        };
  };
}
