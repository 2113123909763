import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';

import { ERPButtonModule } from '../button';
import { ERPCheckboxModule } from '../checkbox';
import { ERPDatePickerModule } from '../date-picker';
import { ERPIconModule } from '../icon';
import { ERPInputModule } from '../input';
import { ERPLabelModule } from '../label';
import { ERPNumberModule } from '../number';
import { ERPSelectModule } from '../select';
import { ERPTimePickerModule } from '../time-picker';

import {
  ERPFilteredByComponent,
  ERPTableDateFilterComponent,
  ERPTableDateTimeFilterComponent,
  ERPTableFilterIconComponent,
  ERPTableNoResultsComponent,
  ERPTableOrderComponent,
  ERPTableOrderModalComponent,
  ERPTableRangeFilterComponent,
  ERPTableSelectFilterComponent,
  ERPTableStringFilterComponent
} from './components';
import { ERPTableOrderDirective } from './directive';
import { ERP_TABLE_FILTER_TYPE } from './providers';
import { TableFilterType } from './types';
import { ERPTableItemSizeDirective } from './virtual-scroll';

type InjectionType = { component: Type<unknown>; data: object };

@NgModule({
  declarations: [
    ERPTableItemSizeDirective,
    ERPTableStringFilterComponent,
    ERPTableRangeFilterComponent,
    ERPTableSelectFilterComponent,
    ERPFilteredByComponent,
    ERPTableDateFilterComponent,
    ERPTableFilterIconComponent,
    ERPTableNoResultsComponent,
    ERPTableDateTimeFilterComponent,
    ERPTableOrderComponent,
    ERPTableOrderModalComponent,
    ERPTableOrderDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    ERPIconModule,
    ERPNumberModule,
    ERPButtonModule,
    ERPDatePickerModule,
    ERPInputModule,
    ERPLabelModule,
    ERPSelectModule,
    ERPTimePickerModule,
    MatDialogModule,
    DragDropModule,
    ERPCheckboxModule
  ],
  exports: [
    MatSortModule,
    MatTableModule,
    ERPTableItemSizeDirective,
    ERPTableStringFilterComponent,
    ERPTableRangeFilterComponent,
    ERPTableSelectFilterComponent,
    ERPFilteredByComponent,
    ERPTableDateFilterComponent,
    ERPTableFilterIconComponent,
    ERPTableNoResultsComponent,
    ERPTableOrderComponent,
    ERPTableOrderModalComponent,
    ERPTableOrderDirective
  ],
  providers: [
    {
      provide: ERP_TABLE_FILTER_TYPE,
      useValue: [
        'date-range',
        {
          component: ERPTableDateFilterComponent,
          data: {}
        }
      ] as [TableFilterType, InjectionType],
      multi: true
    },
    {
      provide: ERP_TABLE_FILTER_TYPE,
      useValue: [
        'date-time-range',
        {
          component: ERPTableDateTimeFilterComponent,
          data: {}
        }
      ] as [TableFilterType, InjectionType],
      multi: true
    },
    {
      provide: ERP_TABLE_FILTER_TYPE,
      useValue: [
        'numeric-range',
        {
          component: ERPTableRangeFilterComponent,
          data: {}
        }
      ] as [TableFilterType, InjectionType],
      multi: true
    },
    {
      provide: ERP_TABLE_FILTER_TYPE,
      useValue: [
        'numeric-decimal-range',
        {
          component: ERPTableRangeFilterComponent,
          data: { inputType: 'decimal' }
        }
      ] as [TableFilterType, InjectionType],
      multi: true
    },
    {
      provide: ERP_TABLE_FILTER_TYPE,
      useValue: [
        'numeric-amount-range',
        {
          component: ERPTableRangeFilterComponent,
          data: { inputType: 'amount' }
        }
      ] as [TableFilterType, InjectionType],
      multi: true
    },
    {
      provide: ERP_TABLE_FILTER_TYPE,
      useValue: [
        'multi-select',
        {
          component: ERPTableSelectFilterComponent,
          data: {}
        }
      ] as [TableFilterType, InjectionType],
      multi: true
    },
    {
      provide: ERP_TABLE_FILTER_TYPE,
      useValue: [
        'string-contains',
        {
          component: ERPTableStringFilterComponent,
          data: {}
        }
      ] as [TableFilterType, InjectionType],
      multi: true
    }
  ]
})
export class ERPTableModule {}
