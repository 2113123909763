import * as moment from 'moment';

export class ERPHourUtil {
  static getHourFromNumber(time: number): string {
    const DIVISOR = 60;
    const minuteInDecimal = 1 / DIVISOR;

    let hh = Math.floor(time);
    let mm = minuteInDecimal * Math.round((time - hh) / minuteInDecimal);
    mm = Math.floor(mm * DIVISOR);

    const isMMequalOneHH = mm === DIVISOR;

    hh = isMMequalOneHH ? ++hh : hh;
    mm = isMMequalOneHH ? 0 : mm;

    const parsedHH = `${hh}`.length === 1 ? `0${hh}` : String(hh);
    const parsedMM = String(mm).length === 1 ? `0${mm}` : String(mm);

    return `${parsedHH}:${parsedMM}`;
  }

  static getNumberFromHour(time: string): number {
    if (time) {
      return moment.duration(time, 'hours').asHours();
    }

    return 0;
  }
}
