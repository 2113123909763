import { identity } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ERPTabletRoundThreadDialogComponent } from '../components';

@Injectable({
  providedIn: 'root'
})
export class ERPRoundThreadService {
  constructor(private dialogService: MatDialog) {}

  openDialog(mode: 'create' | 'view' | 'edit', roundThreadData?: object) {
    return this.dialogService
      .open(ERPTabletRoundThreadDialogComponent, {
        data: { mode, roundThreadData }
      })
      .afterClosed()
      .pipe(filter(identity));
  }
}
