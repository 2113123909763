<erp-confirm
  i18n-header="@@common.dialogs.discard-creation.header"
  header="Quit creating this record?"
  i18n-message="@@common.dialogs.discard-creation.message"
  message="It will not be saved."
  i18n-cancel="@@common.buttons.no"
  cancel="No"
  [cancelAction]="undefined"
  i18n-confirm="@@common.buttons.yes"
  confirm="Yes"
></erp-confirm>
