import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'any' })
export class DiscardChangesGuard {
  canDeactivate(
    component: IDiscardChanges,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    if (component.canDiscardChanges instanceof Function) {
      return component.canDiscardChanges(nextState);
    }

    return of(true);
  }
}

export interface IDiscardChanges {
  canDiscardChanges(nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean;
}
