<form [formGroup]="form" (ngSubmit)="onApply()" autocomplete="off">
  <div class="grid grid-column-gap-t">
    <div class="col-6 text-end">
      <erp-label>
        <div class="text-start" i18n="@@common.table.filters.start-date">
          Start Date:
        </div>
      </erp-label>
      <erp-date-picker formControlName="from"></erp-date-picker>
    </div>
    <div class="col-6">
      <erp-label i18n="@@common.table.filters.end-date">
        End Date:
      </erp-label>
      <erp-date-picker formControlName="to"></erp-date-picker>
    </div>

    <div class="col-6 text-end">
      <erp-label>
        <div class="text-start" i18n="@@common.table.filters.start-time">
          Start Time:
        </div>
      </erp-label>
      <erp-time-picker formControlName="fromTime"></erp-time-picker>
    </div>
    <div class="col-6">
      <erp-label i18n="@@common.table.filters.end-time">
        End Time:
      </erp-label>
      <erp-time-picker formControlName="toTime"></erp-time-picker>
    </div>
  </div>
  <mat-error class="mat-caption" *ngIf="form.hasError('range')" i18n="@@common.form.errors.range-date">
    'End date' must be greater than 'Start date'
  </mat-error>
  <div class="flex justify-content-flex-end margin-top-3">
    <erp-button color="primary" variant="link" type="reset" i18n="@@common.buttons.clear">
      Clear
    </erp-button>
    <erp-button medium color="accent" type="submit" class="margin-left-4" i18n="@@common.buttons.apply">
      Apply
    </erp-button>
  </div>
</form>
