import { AbstractControl } from '@angular/forms';

const BASE = 2;
const POWER = 31;
const MIN_SAFE_VALUE = -(BASE ** POWER);
const MAX_SAFE_VALUE = Math.abs(MIN_SAFE_VALUE) - 1;

SafeIntegerValidator.MIN_SAFE_VALUE = MIN_SAFE_VALUE;
SafeIntegerValidator.MAX_SAFE_VALUE = MAX_SAFE_VALUE;

export function SafeIntegerValidator(control: AbstractControl) {
  const value = control.value as string | number | null;
  const valid = !value || (+value >= MIN_SAFE_VALUE && +value <= MAX_SAFE_VALUE);

  return valid ? null : { safeInteger: true };
}
