import { CDK_DRAG_CONFIG, DragRefConfig } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import {
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';

import { ERPReloadWindowDialogModule } from '@erp/components';
import {
  ERPAuthService,
  ERPSettingsService,
  ERPSharedModule,
  ERPSignalRConnectionService,
  ERP_DEFAULT_LOCATION,
  ERP_DEFAULT_TABLE_PAGING,
  ERP_EXTRASMALL_TABLE_PAGING,
  ERP_HUGE_TABLE_PAGING,
  ERP_LARGE_TABLE_PAGING,
  ERP_MEDIUM_TABLE_PAGING,
  ERP_PO_LIST_TABLET_PAGING,
  ERP_READY_FOR_PICKUP_TABLE_PAGING,
  ERP_SMALL_TABLE_PAGING
} from '@erp/shared';

import { ERPAuthModule } from './auth';
import { ERPErrorHandlerService } from './errors';
import { ERPHttpModule } from './http';
import { ERPInitializer } from './services';

@NgModule({
  imports: [HttpClientModule, ERPSharedModule, ERPAuthModule, ERPHttpModule, ERPReloadWindowDialogModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ERPInitializer,
      multi: true,
      deps: [ERPAuthService, ERPSettingsService, ERPSignalRConnectionService]
    },
    {
      provide: ErrorHandler,
      useExisting: ERPErrorHandlerService
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true
      } as RippleGlobalOptions
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        closeOnNavigation: true,
        disableClose: false,
        hasBackdrop: true,
        minWidth: '25vw',
        position: {
          top: '10%'
        }
      } as MatDialogConfig
    },
    {
      provide: CDK_DRAG_CONFIG,
      useValue: {
        dragStartThreshold: 10,
        pointerDirectionChangeThreshold: 10
      } as DragRefConfig
    },
    {
      provide: ERP_DEFAULT_TABLE_PAGING,
      useValue: {
        page: 1,
        perPage: 40
      }
    },
    {
      provide: ERP_READY_FOR_PICKUP_TABLE_PAGING,
      useValue: {
        page: 1,
        perPage: 50
      }
    },
    {
      provide: ERP_HUGE_TABLE_PAGING,
      useValue: {
        page: 1,
        perPage: 100
      }
    },
    {
      provide: ERP_MEDIUM_TABLE_PAGING,
      useValue: {
        page: 1,
        perPage: 20
      }
    },
    {
      provide: ERP_LARGE_TABLE_PAGING,
      useValue: {
        page: 1,
        perPage: 500
      }
    },
    {
      provide: ERP_SMALL_TABLE_PAGING,
      useValue: {
        page: 1,
        perPage: 10
      }
    },
    {
      provide: ERP_EXTRASMALL_TABLE_PAGING,
      useValue: {
        page: 1,
        perPage: 5
      }
    },
    {
      provide: ERP_PO_LIST_TABLET_PAGING,
      useValue: {
        page: 1,
        perPage: 6
      }
    },
    {
      provide: ERP_DEFAULT_LOCATION,
      useFactory: (erpSettingsService: ERPSettingsService) => {
        return erpSettingsService.defaultLocation;
      },
      deps: [ERPSettingsService]
    }
  ]
})
export class ERPCoreModule {}
