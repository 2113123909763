import { PDP_RESOURCES } from '../constants';

export const getResourcesIDs = (screen: string) => {
  const { prefix, resources } = PDP_RESOURCES[screen];

  if (!resources) {
    return [];
  }

  return resources.map((id: string) => `${prefix}:${id}`);
};

export const getResourcesPrefix = (screen: string) => {
  const { prefix } = PDP_RESOURCES[screen];

  return prefix;
};
