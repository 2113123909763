import { Subject } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class ERPDestroy implements OnDestroy {
  readonly destroyed$: Subject<unknown> = new Subject();

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
