import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import { IMaterialsResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPMaterialsService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('materials');
  }

  getMaterials(params: object): Observable<ICollectionResponse<IMaterialsResponse>> {
    return this.get<ICollectionResponse<IMaterialsResponse>>(params, 'reference');
  }

  getExternalNumbers(searchString: string): Observable<string[]> {
    return this.get<string[]>({ searchString }, 'externalNumbers');
  }

  getVesselName(params: object): Observable<string[]> {
    return this.get<string[]>(params, 'vesselNames');
  }
}
