import { of } from 'rxjs';
import { filter, map, mapTo, pairwise, switchMap } from 'rxjs/operators';

import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { IdTokenClaims } from '@azure/msal-common';
import { environment } from '@environment';
import { ERPAuthService, ERPSettingsService, ERPSignalRConnectionService } from '@erp/shared';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

export function ERPInitializer(
  authService: ERPAuthService,
  erpSettingsService: ERPSettingsService,
  erpSignalRConnectionService: ERPSignalRConnectionService
) {
  return () =>
    authService.msalBroadcastEventMessage
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        pairwise(),
        map(([prev, next]) => {
          const prevPayload = prev.payload as AuthenticationResult;
          const nextPayload = next.payload as AuthenticationResult;

          return {
            eventMessage: next,
            isTokenUpdated: prevPayload?.accessToken !== nextPayload?.accessToken
          };
        }),
        switchMap((result: { eventMessage: EventMessage; isTokenUpdated: boolean }) => {
          const payload = result.eventMessage.payload as AuthenticationResult;
          const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

          if (payload.accessToken) {
            authService.deleteCookie('JWT-Cookie');
            authService.setCookie('JWT-Cookie', payload.accessToken);
          } else {
            // tslint:disable-next-line:no-console
            console.warn('No access token found');
          }

          if (payload.account) {
            authService.setActiveAccount(payload.account);
          } else {
            // tslint:disable-next-line:no-console
            console.warn('No active account found');
          }

          return of(result);
        }),
        switchMap((result: { eventMessage: EventMessage; isTokenUpdated: boolean }) => {
          return erpSettingsService.onLoadDefaultLocation().pipe(mapTo(result));
        }),
        switchMap((result: { eventMessage: EventMessage; isTokenUpdated: boolean }) => {
          if (result.isTokenUpdated) {
            erpSignalRConnectionService.disconnect().then(() => {
              erpSignalRConnectionService.initConnection();
            });
          }

          return of(result);
        })
      )
      .subscribe((result: { eventMessage: EventMessage; isTokenUpdated: boolean }) => {
        // console.log('ERPInitializer', result);
      });
}
