/**
 * SVG Fixer
 *
 * Fixes references to inline SVG elements when the <base> tag is in use.
 * Should be removed when mat-icon will have the right behaviour
 *
 * More info:
 * - http://stackoverflow.com/a/18265336/796152
 * - http://www.w3.org/TR/SVG/linking.html
 */
export const changeSvgPaths = () => {
  [].slice
    .call(document.querySelectorAll('*[fill]'))
    .filter((element: HTMLElement) => {
      return element.getAttribute('fill')?.indexOf('url(') !== -1;
    })
    .forEach((element: HTMLElement) => {
      const attrVal = element.getAttribute('fill');

      element.setAttribute('fill', `url('${attrVal?.slice(attrVal.indexOf('#'))}`);
    });

  [].slice
    .call(document.querySelectorAll('*[mask]'))
    .filter((element: HTMLElement) => {
      return element.getAttribute('mask')?.indexOf('url(') !== -1;
    })
    .forEach((element: HTMLElement) => {
      const attrVal = element.getAttribute('mask');

      element.setAttribute('mask', `url('${attrVal?.slice(attrVal.indexOf('#'))}`);
    });
};
