import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { CustomValidators, ERPFiltersUtil, IMetadataFiltering, QueryOperation } from '@erp/shared';

import { BaseTableFilter } from '../../abstracts';

@Component({
  selector: 'erp-table-date-filter',
  templateUrl: './table-date-filter.component.html',
  styleUrls: ['./table-date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPTableDateFilterComponent extends BaseTableFilter implements OnInit {
  form: UntypedFormGroup;

  constructor(
    readonly dialogRef: MatDialogRef<ERPTableDateFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: { trigger: HTMLElement; filter: IMetadataFiltering },
    readonly dialogElementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() {
    let from;
    let to = null;
    if (ERPFiltersUtil.isBetweenFilter(this.data.filter)) {
      from = this.data.filter.match1;
      to = this.data.filter.match2;
    } else {
      from = ERPFiltersUtil.isGreaterThanOrEqualFilter(this.data.filter) ? this.data.filter.match1 : null;
      to = ERPFiltersUtil.isLaterThanOrEqualFilter(this.data.filter) ? this.data.filter.match1 : null;
    }
    this.form = new UntypedFormGroup(
      {
        from: new UntypedFormControl(from),
        to: new UntypedFormControl(to)
      },
      [CustomValidators.range]
    );
    this.setDialogPosition();
  }

  onApply() {
    const from: Date = this.form.get('from')?.value;
    const to: Date | null = this.form.get('to')?.value;

    if (from || to) {
      let op: QueryOperation;
      let match1: Date;
      let match2: Date | null = to;

      if (from && to) {
        if (from > to) {
          return;
        }
        op = 'between';
        match1 = from;
      } else {
        if (from) {
          op = 'gte';
          match1 = from;
          match2 = null;
        } else {
          op = 'lte';
          match1 = to as Date;
          match2 = null;
        }
      }

      const filter: IMetadataFiltering = { by: this.data.filter?.by, match1, match2, op };

      return this.dialogRef.close(filter);
    }
    this.dialogRef.close(null);
  }
}
