import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

import { ERPToasterService } from '../../../../components/src/lib/modules/toaster/services';
import { ICollectionResponse } from '../abstracts';
import { IWorkCenter, IWorkCentersList } from '../interfaces';
import { ERPWorkCenterService } from '../services';

@Injectable({ providedIn: 'any' })
export class ERPWorkCenterResolver {
  constructor(
    readonly toasterService: ERPToasterService,
    readonly workCenterService: ERPWorkCenterService,
    readonly router: Router,
    readonly route: ActivatedRoute
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const { id } = route.params;
    const { workCenterId } = route.queryParams;
    const workCenters = route.parent?.data.workCenters as ICollectionResponse<IWorkCentersList>;

    const actualId = id ?? +workCenterId;
    if (workCenters) {
      if (!actualId) {
        this.redirectToDefault(workCenters);

        return of(null);
      }

      return of(workCenters.data.filter(c => c.id === actualId)[0]);
    }

    return this.workCenterService.getById(actualId).pipe(
      catchError(error => {
        this.toasterService.error('error.default');

        return of(error);
      })
    );
  }

  private redirectToDefault(workCenters: ICollectionResponse<IWorkCentersList>) {
    const defaultWorkCenter = workCenters.data[0];
    this.router.navigate(['tablet/production/processing-order'], {
      relativeTo: this.route,
      queryParams: { workCenterId: defaultWorkCenter?.id }
    });
  }
}
