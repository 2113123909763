import { Pipe, PipeTransform } from '@angular/core';

interface ITruncate {
  truncate(value: string, limit: number, trail: string): string;
}

type TruncateType = 'text' | 'email';

const DEFAULT_LIMIT = 20;

@Pipe({
  name: 'erpTruncate'
})
export class ERPTruncatePipe implements PipeTransform {
  transform(value: string, limit: number = DEFAULT_LIMIT, type: TruncateType = 'text', trail: string = '...') {
    if (value?.length > limit) {
      switch (type) {
        case 'email':
          return new TruncateEmail().truncate(value, limit, trail);
        case 'text':
          return new TruncateText().truncate(value, limit, trail);
        default:
          return this.exception(type);
      }
    } else {
      return value;
    }
  }

  private exception(type: TruncateType) {
    throw new Error(`[${ERPTruncatePipe.name}]: Invalid type. Got ${type}.`);
  }
}

export class TruncateText implements ITruncate {
  truncate(value: string, limit: number, trail: string) {
    const cutLength = limit - trail.length;

    return value.substring(0, cutLength) + trail;
  }
}

export class TruncateEmail implements ITruncate {
  truncate(value: string, limit: number, trail: string) {
    const arr = value.split('@');
    const divider = 2;
    const halfLength = limit / divider - 1;
    let cutLength = limit - trail.length - 1;

    if (arr[0].length > halfLength && arr[1].length < halfLength) {
      cutLength -= arr[1].length;

      arr[0] = arr[0].substr(0, cutLength) + trail;
    } else if (arr[1].length > halfLength && arr[0].length < halfLength) {
      cutLength -= arr[0].length;

      arr[1] = trail + arr[1].substr(-cutLength);
    } else {
      cutLength = halfLength - trail.length;
      arr[0] = arr[0].substr(0, cutLength) + trail;
      arr[1] = trail + arr[1].substr(-cutLength);
    }

    return arr.join('@');
  }
}
