import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Dictionary, ERPAuthService } from '@erp/shared';
import { ERPVolumetricGrowthService } from '@erp/tablet/shared';

import { ERPCheckInService } from '../../services';

@Injectable({ providedIn: 'root' })
export class TabletInterceptor<T extends Dictionary> implements HttpInterceptor {
  constructor(
    readonly route: ActivatedRoute,
    readonly checkInService: ERPCheckInService,
    readonly volumetricGrowthService: ERPVolumetricGrowthService,
    readonly authService: ERPAuthService
  ) {}

  intercept(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(req).pipe(
      catchError(error => {
        const checkInCode = 'GEN-138';
        const deviceCode = 'GEN-142';

        if (error.error?.error?.code === checkInCode && this.route.snapshot.children[0]?.url[0]?.path === 'tablet') {
          this.checkInService.showExpiredSessionPopup();
        }

        if (error.error?.error?.code === deviceCode && this.route.snapshot.children[0]?.url[0]?.path === 'tablet') {
          const data = {
            userId: this.authService.getCustomUserID()
          };

          this.volumetricGrowthService.openDialog('create', data);
        }

        return throwError(error);
      })
    );
  }
}
