import { Injectable } from '@angular/core';

import { IHttpEntityFactory } from '../abstracts';
import { ILocation, ILocationResponse } from '../interfaces';

@Injectable({ providedIn: 'any' })
export class ERPLocationFactory implements IHttpEntityFactory<ILocation, ILocationResponse> {
  fromResponse(location: ILocationResponse): ILocation {
    return {
      id: location.id,
      locationId: location.locationId,
      description: location.description,
      isDock: location.isDock,
      address: {
        ...location.address
      },
      racks: location.racks,
      name: location.name
    };
  }

  toRequest(location: ILocation): ILocationResponse {
    return {
      id: location.id,
      locationId: location.locationId,
      description: location.description,
      isDock: location.isDock,
      address: {
        ...location.address
      },
      racks: location.racks,
      name: location.name
    };
  }
}
