import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'erp-notification-header',
  templateUrl: './notification-header.component.html',
  styleUrls: ['./notification-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPNotificationHeaderComponent {
  @Output() readonly markAllAsRead = new EventEmitter();
}
