import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPConfirmModule } from '../../confirm';

import {
  ERPDeletionDialogComponent,
  ERPDeletionLineDialogComponent,
  ERPDisablingLineDialogComponent
} from './components';

@NgModule({
  declarations: [ERPDeletionDialogComponent, ERPDeletionLineDialogComponent, ERPDisablingLineDialogComponent],
  imports: [CommonModule, ERPConfirmModule],
  exports: [ERPDeletionDialogComponent, ERPDeletionLineDialogComponent, ERPDisablingLineDialogComponent]
})
export class ERPDeletionDialogModule {}
