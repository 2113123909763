export enum PDPDesktopSideNavModule {
  logisticAndWarehousing = 'logisticAndWarehousing',
  sales = 'sales',
  procurement = 'procurement',
  production = 'production',
  planning = 'planning',
  inventoryOnHand = 'inventoryOnHand',
  qualityControl = 'qualityControl',
  administration = 'administration'
}

export enum PDPTabletSideNavModule {
  'logistic-and-warehousing' = 'logistic-and-warehousing',
  'production' = 'production',
  'quality-control' = 'quality-control'
}
