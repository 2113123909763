import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ERPErrorHandlerService implements ErrorHandler {
  handleError<TError extends Error>(error: TError) {
    console.error(error);
  }
}
