import { Observable } from 'rxjs/internal/Observable';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';
import { ITallyFile } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ERPTallyFilesService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('');
  }

  getTallyFiles(): Observable<ITallyFile[]> {
    return this.get<ITallyFile[]>({}, `https://localhost:5004/api/v1/tallyfiles`, {}, true);
  }

  getTallyFile(filePath: string) {
    return this.get({}, `https://localhost:5004/api/v1/tallyfiles/${filePath}`, { responseType: 'blob' }, true);
  }
}
