<mat-nav-list>
  <!-- LOGISTICS -->
  <erp-sidenav-expansion-panel
    i18n-header="logistic.sidebar.title"
    header="Logistics and Warehousing"
    *ngIf="
      roles &&
      (roles[':tablet-side-menudriver-sign-in']?.allowed ||
        roles['tablet-side-menu:unloading-authorizations']?.allowed ||
        roles['tablet-side-menu:loading-authorization']?.allowed ||
        roles['tablet-side-menu:movements']?.allowed ||
        roles['tablet-side-menu:segregation']?.allowed)
    "
  >
    <a
      *ngIf="roles && roles['tablet-side-menu:driver-sign-in']?.allowed"
      i18n="@@tablet.logistic.sidebar.driver-sign-in.link"
      mat-list-item
      routerLink="/tablet/logistic-and-warehousing/driver-sign-in"
      routerLinkActive="active"
      (click)="emitService.emit('tablet:sidebar-navigation')"
      >Driver Sign In</a
    >
    <a
      *ngIf="roles && roles['tablet-side-menu:unloading-authorizations']?.allowed"
      i18n="@@tablet.logistic.sidebar.unloading-authorizations.link"
      mat-list-item
      routerLink="/tablet/logistic-and-warehousing/unloading-authorizations"
      routerLinkActive="active"
      (click)="emitService.emit('tablet:sidebar-navigation')"
      >Unloading Authorization</a
    >
    <a
      *ngIf="roles && roles['tablet-side-menu:loading-authorization']?.allowed"
      mat-list-item
      routerLink="/tablet/logistic-and-warehousing/loading-authorization"
      routerLinkActive="active"
      (click)="emitService.emit('tablet:sidebar-navigation')"
      i18n="@@tablet.logistic.sidebar.loading-authorizationі.link"
    >
      Loading Authorizations
    </a>
    <a
      *ngIf="roles && roles['tablet-side-menu:movements']?.allowed"
      mat-list-item
      routerLink="/tablet/logistic-and-warehousing/movements"
      routerLinkActive="active"
      (click)="emitService.emit('tablet:sidebar-navigation')"
      i18n="@@tablet.logistic.sidebar.movements.link"
    >
      Movements
    </a>
    <a
      *ngIf="roles && roles['tablet-side-menu:segregation']?.allowed"
      mat-list-item
      routerLink="/tablet/logistic-and-warehousing/segregation"
      routerLinkActive="active"
      (click)="emitService.emit('tablet:sidebar-navigation')"
      i18n="@@tablet.logistic.sidebar.segregation.link"
    >
      Segregations
    </a>
  </erp-sidenav-expansion-panel>
  <!-- PRODUCTION -->
  <erp-sidenav-expansion-panel
    i18n-header="@@production.sidebar.title"
    header="Production"
    *ngIf="roles && roles['tablet-side-menu:processing-order']?.allowed"
  >
    <ng-container *ngFor="let facilityName of facilityNames">
      <span class="nav-list-caption">
        {{ facilityName }}
      </span>
      <ng-container *ngFor="let workCenter of facilities[facilityName]">
        <a
          mat-list-item
          routerLink="/tablet/production/processing-order"
          [queryParams]="{ workCenterId: workCenter.id }"
          (click)="emitService.emit('tablet:sidebar-navigation')"
          routerLinkActive="active"
        >
          {{ workCenter.name }}
        </a>
      </ng-container>
    </ng-container>
  </erp-sidenav-expansion-panel>
  <!-- QUALITY CONTROL -->
  <erp-sidenav-expansion-panel
    i18n-header="@@quality-control.sidebar.title"
    header="Quality Control"
    *ngIf="roles && roles['tablet-side-menu:yard-ncp']?.allowed"
  >
    <a
      *ngIf="roles && roles['tablet-side-menu:yard-ncp']?.allowed"
      mat-list-item
      i18n="@@tablet.production.sidebar.quality-control.link"
      (click)="emitService.emit('tablet:sidebar-navigation')"
      routerLink="/tablet/quality-control/yard-ncp"
      routerLinkActive="active"
      >Yard NCP</a
    >
  </erp-sidenav-expansion-panel>
</mat-nav-list>
