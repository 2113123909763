import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPSharedModule } from '@erp/shared';

import { ERPAutocompleteModule } from '../autocomplete';
import { ERPButtonModule } from '../button';
import { ERPExpansionPanelModule } from '../expansion-panel';
import { ERPIconModule } from '../icon';
import { ERPLabelModule } from '../label';
import { ERPPaginatorModule } from '../paginator';
import { ERPSlideToggleModule } from '../slide-toggle';
import { ERPToolbarModule } from '../toolbar';

import {
  ERPNotificationCardsComponent,
  ERPNotificationCounterComponent,
  ERPNotificationFilterComponent,
  ERPNotificationHeaderComponent,
  ERPNotificationListComponent
} from './components';

@NgModule({
  declarations: [
    ERPNotificationFilterComponent,
    ERPNotificationHeaderComponent,
    ERPNotificationListComponent,
    ERPNotificationCardsComponent,
    ERPNotificationCounterComponent
  ],
  imports: [
    CommonModule,
    ERPButtonModule,
    ERPToolbarModule,
    ERPSlideToggleModule,
    ERPLabelModule,
    ERPAutocompleteModule,
    ERPExpansionPanelModule,
    ERPIconModule,
    ERPSharedModule,
    ERPPaginatorModule,
    ScrollingModule
  ],
  exports: [ERPNotificationListComponent, ERPNotificationCounterComponent]
})
export class ERPNotificationsModule {}
