import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AutoCleanupFeature, ERPAuthService, ERPUtilsService, Features } from '@erp/shared';

import { NotificationType } from '../../enums';
import { INotification, INotificationGroup } from '../../interfaces';
import {
  ERPNotificationEventsService,
  ERPNotificationListenerService,
  ERPNotificationService,
  ERPNotificationUtilityService
} from '../../services';

@Component({
  selector: 'erp-notification-cards',
  templateUrl: './notification-cards.component.html',
  styleUrls: ['./notification-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPNotificationCardsComponent {
  destroyed$: Observable<void>;

  private _today: Date;
  private _yesterday: Date;

  private _todayTitle = $localize`:@@notifications.list.group.today.title:Today`;
  private _yesterdayTitle = $localize`:@@notifications.list.group.yesterday.title:Yesterday`;
  private _olderTitle = $localize`:@@notifications.list.group.older.title:Older`;

  @Input() isTablet: boolean;
  @Input() set data(data: INotification[]) {
    this.createGroups(data);
    this.changeDetector.markForCheck();
  }

  notificationTypes = Object.fromEntries(
    Object.entries(NotificationType)
      .filter(k => isNaN(Number(k[1])))
      .map(k => [k[0], k[1].toString().toLowerCase()])
  );

  notificationGroups: INotificationGroup[] = [];

  constructor(
    readonly utilsService: ERPUtilsService,
    readonly router: Router,
    readonly notificationService: ERPNotificationService,
    readonly authService: ERPAuthService,
    readonly changeDetector: ChangeDetectorRef,
    readonly notificationEvents: ERPNotificationEventsService,
    readonly signalR: ERPNotificationListenerService,
    readonly notificationUtility: ERPNotificationUtilityService
  ) {
    this._today = new Date();
    this._today.setHours(0, 0, 0, 0);

    this._yesterday = new Date();
    this._yesterday.setDate(this._yesterday.getDate() - 1);
    this._yesterday.setHours(0, 0, 0, 0);
  }

  createGroups(data: INotification[]) {
    this.notificationGroups = [];

    const todayNotifications = data.filter(i => i.date >= this._today);
    const yesterdayNotifications = data.filter(i => i.date >= this._yesterday && i.date < this._today);
    const olderNotifications = data.filter(i => i.date < this._yesterday);

    this.pushGroup(this._todayTitle, todayNotifications);
    this.pushGroup(this._yesterdayTitle, yesterdayNotifications);
    this.pushGroup(this._olderTitle, olderNotifications);
  }

  onClickDocumentId(element: INotification) {
    this.notificationUtility.openNotificationDocument(element);
  }

  switchReadState(element: INotification) {
    this.notificationUtility.setReadState$(element, !element.isRead).subscribe(n => {
      element.isRead = !element.isRead;
      this.changeDetector.detectChanges();
    });
  }

  private pushGroup(title: string, items: INotification[]) {
    if (items.length > 0) {
      this.notificationGroups.push({ title, items });
    }
  }
}
