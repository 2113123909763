import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { ERPSharedModule } from '../../../../../shared/src/lib';

import { ERPTextareaComponent } from './textarea.component';

@NgModule({
  declarations: [ERPTextareaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatInputModule,
    ERPSharedModule
  ],
  exports: [ERPTextareaComponent, MatFormFieldModule, ReactiveFormsModule]
})
export class ERPTextareaModule {}
