import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { DictionaryService } from '../services';

@Injectable({ providedIn: 'root' })
export class ERPRangesResolver {
  constructor(readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.ranges?.pipe(map(data => data.sort((a, b) => a.id - b.id)));
  }
}
