import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { ERPToasterService } from '@erp/components';
import {
  AutoCleanupFeature,
  BaseFormComponent,
  ERPDateUtil,
  ERPFormStateDispatcher,
  Features,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';
import { IBuckOn } from '@erp/tablet';

import { ERPProductionProcessingOrderService } from '../../../../../../../production/src/lib/modules/processing-order/services';

const MAX_LENGTH = 120;

@Component({
  selector: 'erp-tablet-buck-on-dialog',
  templateUrl: './buck-on-dialog.component.html',
  styleUrls: ['./buck-on-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPTabletBuckOnDialogComponent<T> extends BaseFormComponent<IBuckOn> implements OnInit {
  readonly destroyed$: Observable<unknown>;

  readonly form = new UntypedFormGroup({
    driftSnNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    driftOdNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    odMiscSnNumber: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    calibrationDueDate: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    driftVerifications: new UntypedFormArray([]),
    id: new UntypedFormControl(null),
    orderId: new UntypedFormControl(null),
    serviceId: new UntypedFormControl(null),
    userId: new UntypedFormControl(null),
    userName: new UntypedFormControl(null),
    rowVersion: new UntypedFormControl(null),
    startDate: new UntypedFormControl(null),
    isOpen: new UntypedFormControl(null)
  });

  constructor(
    readonly formState: ERPFormStateDispatcher,
    readonly dialogRef: MatDialogRef<ERPTabletBuckOnDialogComponent<IBuckOn>>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'create' | 'view' | 'edit'; buckOnData: IBuckOn },
    readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    readonly processingOrderService: ERPProductionProcessingOrderService,
    readonly toasterService: ERPToasterService
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.buckOnData) {
      if (this.data.buckOnData.driftVerifications?.length) {
        this.data.buckOnData.driftVerifications.forEach(i => {
          i.verificationTime =
            typeof i.verificationTime === 'object'
              ? ERPDateUtil.getTimeFromDate(i.verificationTime as Date)
              : i.verificationTime;
          this.data.buckOnData = {
            ...this.data.buckOnData,
            calibrationDueDate: this.adjustForTimezone(new Date(this.data.buckOnData.calibrationDueDate))
          };
          this.onAddMeasurement();
        });
      } else {
        this.onAddMeasurement();
      }
      this.form.patchValue(this.data.buckOnData);
    }
    if (this.data.mode === 'view') {
      this.form.disable();
    }
    if (this.data.mode === 'create') {
      this.setOrderId();
    }
  }

  private adjustForTimezone(date: Date): Date {
    // tslint:disable-next-line:no-magic-numbers
    const timeOffsetInMS = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);

    return date;
  }

  private setOrderId() {
    const url = this.router.url.split('/');
    const orderId = +url[url.findIndex(i => i === 'processing-order') + 1];
    const serviceId = +url[url.findIndex(i => i === 'service') + 1];

    this.form.patchValue({
      orderId,
      serviceId
    });
  }

  get isSaveDisabled(): boolean {
    return !this.form.valid || this.data.mode === 'view';
  }

  onSave() {
    if (this.data.mode === 'edit') {
      this.onUpdate();
    }
    if (this.data.mode === 'create') {
      this.onCreate();
    }
  }

  onCreate() {
    this.form.get('startDate')?.patchValue(new Date());

    const { valid, value } = this.form;

    if (!valid) {
      this.formState.onSubmit.notify();

      return;
    }

    this.handleTime(value);

    this.processingOrderService.createBuckOn(value).subscribe(
      res => {
        this.toasterService.success('success.created');
        this.dialogRef.close(res);
      },
      error => {
        const message = error.error?.error?.message || 'error.default';
        this.toasterService.error(message);
      }
    );
  }

  onUpdate() {
    this.form.get('startDate')?.patchValue(new Date());

    const { valid, value } = this.form;

    if (!valid) {
      this.formState.onSubmit.notify();

      return;
    }

    this.handleTime(value);

    this.processingOrderService.updateBuckOn(value).subscribe(
      res => {
        this.toasterService.success('success.updated');
        this.dialogRef.close(res);
      },
      error => {
        const message = error.error?.error?.message || 'error.default';
        this.toasterService.error(message);
      }
    );
  }

  get canAddMeasurement(): boolean {
    const maxLines = 3;

    return (this.form.get('driftVerifications') as UntypedFormArray)?.controls?.length < maxLines;
  }

  onAddMeasurement() {
    (this.form.get('driftVerifications') as UntypedFormArray).push(
      new UntypedFormGroup({
        edge1: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
        center: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
        edge2: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
        verificationTime: new UntypedFormControl(null, [Validators.required]),
        id: new UntypedFormControl(null),
        rowVersion: new UntypedFormControl(null),
        buckerDeviceParameterId: new UntypedFormControl(null, [])
      })
    );
    this.changeDetector.markForCheck();
  }

  onCancel() {
    this.dialogRef.close();
  }

  private handleTime(value: IBuckOn) {
    value.driftVerifications.forEach(line => {
      if (line?.verificationTime) {
        if (ERPDateUtil.isTimeValid(line.verificationTime as string)) {
          const [hours, minutes] = (line.verificationTime as string).split(':');
          const calibrationDueDate = this.form.get('calibrationDueDate')?.value;
          const date = new Date(calibrationDueDate);
          date.setUTCHours(+hours, +minutes);

          line.verificationTime = date;
        }
      }
    });
  }
}
