import { Injectable } from '@angular/core';

import { ICustomer, ICustomerResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ERPCustomerFactory {
  toRequest(customer: ICustomer): ICustomerResponse {
    const {
      id,
      general,
      rowVersion,
      attachments,
      customerNotes,
      billingAddress,
      physicalAddress,
      billingAddressId,
      physicalAddressId,
      billingAddressRowVersion,
      physicalAddressRowVersion
    } = customer;

    return {
      id,
      rowVersion,
      attachments,
      name: general.name,
      phone: general.phone,
      status: general.status,
      website: general.website,
      description: customerNotes,
      type: general.customerType,
      ownerUser: general.customerOwner,
      companyName: general.companyName,
      billingAddress: billingAddress
        ? {
            ...billingAddress,
            id: billingAddressId,
            rowVersion: billingAddressRowVersion
          }
        : null,
      physicalAddress: physicalAddress
        ? {
            ...physicalAddress,
            id: physicalAddressId,
            rowVersion: physicalAddressRowVersion
          }
        : null,
      statusDescription: general.statusDescription
    };
  }

  fromResponse(response: ICustomerResponse): ICustomer {
    const {
      id,
      name,
      type,
      phone,
      status,
      website,
      ownerUser,
      rowVersion,
      attachments,
      description,
      companyName,
      billingAddress,
      physicalAddress,
      statusDescription
    } = response;

    return {
      id,
      rowVersion,
      attachments,
      billingAddress,
      physicalAddress,
      general: {
        name,
        phone,
        status,
        website,
        companyName,
        statusDescription,
        customerType: type,
        customerOwner: ownerUser
      },
      customerNotes: description,
      billingAddressId: billingAddress?.id ?? null,
      physicalAddressId: physicalAddress?.id ?? null,
      billingAddressRowVersion: billingAddress?.rowVersion ?? null,
      physicalAddressRowVersion: physicalAddress?.rowVersion ?? null
    };
  }
}
