<form [formGroup]="form" (ngSubmit)="onApply()" autocomplete="off">
  <erp-label class="margin-bottom-2" i18n="@@common.table.filters.contains">
    Field contains:
  </erp-label>
  <erp-input formControlName="contains" [isFocused]="true"></erp-input>
  <div class="flex justify-content-flex-end">
    <erp-button color="primary" variant="link" type="reset" i18n="@@common.buttons.clear">
      Clear
    </erp-button>
    <erp-button medium color="accent" type="submit" class="margin-left-4" i18n="@@common.buttons.apply">
      Apply
    </erp-button>
  </div>
</form>
