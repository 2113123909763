<div class="filtered-by flex align-items-baseline" *ngIf="filters.length">
  <span class="filtered-by__label" i18n="@@common.table.filters.filtered-by">
    Filtered By:
  </span>
  <mat-chip-list>
    <mat-chip
      *ngFor="let filter of filters; let i = index; trackBy: trackByFn"
      color="primary"
      selected
      removable
      (removed)="onRemove(i)"
    >
      <span>
        {{ getDisplayName(filter) }}
      </span>
      <span class="flex justify-content-flex-end align-items-center" matChipRemove>
        &#10005;
      </span>
    </mat-chip>
    <erp-button
      color="primary"
      variant="link"
      *ngIf="filters.length > 1"
      (click)="onRemoveAll()"
      i18n="@@common.table.filters.clear-all"
    >
      Clear All
    </erp-button>
  </mat-chip-list>
</div>
