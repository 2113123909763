import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { ERPNotificationListenerService, ERPToasterOutletComponent, ERPToasterService } from '@erp/components';
import { PageTitleService } from '@erp/core';
import { AutoCleanupFeature, ERPSignalRConnectionService, Features, IDestroyable, changeSvgPaths } from '@erp/shared';

@Component({
  selector: 'erp-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPAppComponent implements OnInit, IDestroyable {
  readonly destroyed$: Observable<void>;
  @ViewChild(ERPToasterOutletComponent, {
    static: true
  })
  readonly toaster: ERPToasterOutletComponent;
  readonly tabletBodyClass = 'erp-app-tablet';

  @HostBinding('class.erp-tablet-module')
  isTabletModule = false;

  constructor(
    readonly toasterService: ERPToasterService,
    readonly titleService: PageTitleService,
    readonly router: Router,
    readonly zone: NgZone,
    readonly renderer: Renderer2,
    readonly signalRService: ERPSignalRConnectionService,
    readonly notificationListener: ERPNotificationListenerService,
    private authService: MsalService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    // tslint:disable-next-line: type-literal-delimiter
    (window as Window & typeof globalThis & { logout(): void }).logout = this.authService.logoutRedirect.bind(
      this.authService
    );
    // tslint:disable-next-line: type-literal-delimiter
    (window as Window & typeof globalThis & { test: Observable<object> }).test = this.http.get.bind(this.http)(
      'https://jsonplaceholder.typicode.com/todos/1'
    );

    this.toasterService.setDefaultContainer(this.toaster);
    this.signalRService.initConnection();
    this.notificationListener.initListener();

    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.isTabletModule = this.router.url.startsWith('/tablet');
        if (this.isTabletModule) {
          this.renderer.addClass(document.body, this.tabletBodyClass);
        } else {
          this.renderer.removeClass(document.body, this.tabletBodyClass);
        }

        this.zone.runOutsideAngular(changeSvgPaths);
      });
  }
}
