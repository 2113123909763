import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';

import { ERPConfirmComponent } from './components';

@NgModule({
  declarations: [ERPConfirmComponent],
  imports: [CommonModule, MatDialogModule, ERPButtonModule, ERPIconModule],
  exports: [ERPConfirmComponent]
})
export class ERPConfirmModule {}
