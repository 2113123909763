import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse, ICollectionResponseMetadata, IMetadataPaging } from '../abstracts';
import { IProductSearch } from '../interfaces';
import { ERP_DEFAULT_TABLE_PAGING } from '../providers';

@Injectable({
  providedIn: 'root'
})
export class ERPProductsSearchService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient, @Inject(ERP_DEFAULT_TABLE_PAGING) readonly paging: IMetadataPaging) {
    super('products');
  }

  getProducts(query: object) {
    return this.get<ICollectionResponse<IProductSearch>>(query, 'summary');
  }

  getProduct(id: string, materialId?: number) {
    return this.get<IProductSearch>({ materialId }, `summary/${id}?`);
  }

  getUpdatedProduct(query: object) {
    return this.get<IProductSearch>(query, `updated`);
  }

  getAutocomplete(match1: string, by: string) {
    const query: ICollectionResponseMetadata = {
      paging: this.paging,
      sorting: [],
      filtering: [{ by, op: 'contains', match1 }]
    };

    return this.get<ICollectionResponse<{ id: string; skuDetails: string }>>(
      { searchString: JSON.stringify(query) },
      'info'
    ).pipe(map(res => res.data));
  }
}
