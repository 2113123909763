import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import {
  ILoadingAuthAvailableMaterialRelease,
  ILoadingAuthAvailableSalesOrder,
  IMatchedCustomerWithAvailableDates
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPLoadingAuthSharedService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('LoadingAuth');
  }

  getCustomerWithAvailableDates(params: object) {
    return this.get<ICollectionResponse<IMatchedCustomerWithAvailableDates>>(params, 'availableDates');
  }

  getDestinations(params: object) {
    return this.get<ICollectionResponse<string[]>>(params, 'destinations');
  }

  getAvailableSalesOrderList(params: object): Observable<ICollectionResponse<ILoadingAuthAvailableSalesOrder>> {
    return this.get<ICollectionResponse<ILoadingAuthAvailableSalesOrder>>(params, `available/salesOrders`);
  }

  getAvailableMaterialReleaseList(
    params: object
  ): Observable<ICollectionResponse<ILoadingAuthAvailableMaterialRelease>> {
    return this.get<ICollectionResponse<ILoadingAuthAvailableMaterialRelease>>(params, `available/materialReleases`);
  }
}
