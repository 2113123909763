<erp-confirm
  i18n-header="@@common.dialogs.confirm-production-results.header"
  header="Confirm production results?"
  i18n-message="@@common.dialogs.confirm-production-results.message"
  message="Once confirmed, you can't edit this information."
  i18n-cancel="@@common.dialogs.confirm-production-results.buttons.cancel"
  cancel="Cancel"
  i18n-confirm="@@common.dialogs.confirm-production-results.buttons.confirm"
  confirm="Confirm"
  color="warn"
></erp-confirm>
