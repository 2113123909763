import { Injectable } from '@angular/core';

import { IManufacturer, IManufacturerResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPManufacturerFactory {
  toRequest(product: IManufacturer): IManufacturerResponse {
    return {
      id: product.id,
      address: product.address,
      status: product.status,
      designs: product.designs,
      materials: product.materials,
      author: product.author,
      description: product.description,
      ...product.general
    };
  }

  fromResponse(response: IManufacturerResponse): IManufacturer {
    return {
      id: response.id,
      address: response.address,
      status: response.status,
      designs: response.designs,
      materials: response.materials,
      author: response.author,
      description: response.description,
      general: {
        apiLicenseExpiryDate: response.apiLicenseExpiryDate,
        apiLicenseNumber: response.apiLicenseNumber,
        manufacturerId: response.manufacturerId,
        millName: response.millName
      }
    };
  }
}
