import { filter, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ERPSignalRConnectionService } from '@erp/shared';

@Injectable({
  providedIn: 'root'
})
export class ERPInventoryDetailsSignalRService {
  constructor(private signalRService: ERPSignalRConnectionService) {}

  correlationIds: string[] = [];

  readonly messages$ = this.signalRService.messages$.pipe(
    filter(message => this.correlationIds.includes(message.Metadata?.CorrelationId)),
    tap(message => (this.correlationIds = this.correlationIds.filter(item => item !== message.Metadata?.CorrelationId)))
  );

  setCorrelationId(id: string) {
    this.correlationIds.push(id);
  }
}
