import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { ERPSharedModule } from '@erp/shared';

import { ERPAutocompleteModule } from '../autocomplete';
import { ERPButtonModule } from '../button';
import { ERPConfirmModule } from '../confirm';
import { ERPDatePickerModule } from '../date-picker';
import { ERPHelpModule } from '../help/help.module';
import { ERPIconModule } from '../icon';
import { ERPLabelModule } from '../label';
import { ERPNotificationsModule } from '../notifications';
import { ERPRoutingSpinnerModule } from '../routing-spinner';

import { ERPNumberModule } from '../number';
import {
  ERPLogisticLoadingReportComponent,
  ERPOnHandReportComponent,
  ERPSidenavContainerComponent,
  ERPSidenavExpansionPanelComponent,
  ERPSidenavListComponent,
  ERPTabletSidenavComponent,
  ERPTabletSidenavListComponent
} from './components';
import { ERPSidenavFooterComponent } from './components/sidenav-footer';
import { ERPSidenavComponent } from './components/sidenav/sidenav.component';

@NgModule({
  declarations: [
    ERPSidenavComponent,
    ERPSidenavFooterComponent,
    ERPSidenavListComponent,
    ERPSidenavContainerComponent,
    ERPSidenavExpansionPanelComponent,
    ERPTabletSidenavComponent,
    ERPTabletSidenavListComponent,
    ERPLogisticLoadingReportComponent,
    ERPOnHandReportComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatExpansionModule,
    MatListModule,
    ERPIconModule,
    ERPButtonModule,
    ERPSharedModule,
    ERPConfirmModule,
    ERPRoutingSpinnerModule,
    ERPHelpModule,
    ERPLabelModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ERPAutocompleteModule,
    ERPLabelModule,
    ERPDatePickerModule,
    MatDialogModule,
    ERPNotificationsModule,
    ERPNumberModule
  ],
  exports: [
    ERPSidenavComponent,
    ERPSidenavFooterComponent,
    ERPSidenavListComponent,
    ERPSidenavContainerComponent,
    ERPSidenavExpansionPanelComponent,
    MatSidenavModule,
    MatListModule,
    ERPTabletSidenavComponent,
    ERPTabletSidenavListComponent
  ]
})
export class ERPSidenavModule {}
