import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { ERPSharedModule } from '@erp/shared';

import { ERPButtonModule } from '../../button';
import { ERPConfirmModule } from '../../confirm';
import { ERPIconModule } from '../../icon';
import { ERPLabelModule } from '../../label';
import { ERPSelectModule } from '../../select';

import { ERPConfirmCheckOutComponent, ERPTabletCheckInDialogComponent } from './components';

@NgModule({
  declarations: [ERPTabletCheckInDialogComponent, ERPConfirmCheckOutComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatDialogModule,
    ERPIconModule,
    ERPButtonModule,
    ERPLabelModule,
    ERPSelectModule,
    ERPConfirmModule,
    ERPSharedModule
  ],
  exports: [ERPTabletCheckInDialogComponent, ERPConfirmCheckOutComponent]
})
export class ERPTabletCheckInDialogModule {}
