<form [formGroup]="form">
  <div class="filter grid grid-gap-d">
    <div [class.col-8]="isTablet" [class.col-4]="!isTablet">
      <erp-autocomplete
        icon="action:search"
        placeholder="Search by Title or Document #"
        i18n-placeholder="@@notification.list.filter.search.placeholder"
        formControlName="searchValue"
      >
      </erp-autocomplete>
    </div>
    <div class="col-4 toggle-wrapper">
      <erp-slide-toggle color="lime" labelPosition="after" formControlName="isRead">
        <erp-label i18n="@@notification.list.filter.show-unread-toggle">
          Show Unread Only
        </erp-label>
      </erp-slide-toggle>
    </div>
  </div>
</form>
