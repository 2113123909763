import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[erpFocus]'
})
export class ERPFocusDirective implements AfterViewInit {
  @Input() erpFocus: boolean;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    if (this.erpFocus) {
      this.element.nativeElement.focus();
    }
  }
}
