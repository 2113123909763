import { Observable } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Dictionary } from '@erp/shared';

import { ERPLoadingService } from '../../services';

const DELAY_TIME = 1000;

@Injectable({ providedIn: 'root' })
export class LoadingInterceptor<T extends Dictionary> implements HttpInterceptor {
  constructor(readonly loadingService: ERPLoadingService) {}

  intercept(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (req.headers.get('skip-loader') === 'true') {
      return next.handle(req);
    }

    switch (req.method) {
      case 'GET':
        return this.handleGETRequest(req, next);
      default:
        return this.handleDefault(req, next);
    }
  }

  protected handleGETRequest(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    this.loadingService.addGETRequest();

    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.removeGETRequest();
      })
    );
  }

  protected handleDefault(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    this.loadingService.addRequest();

    return next.handle(req).pipe(finalize(() => setTimeout(() => this.loadingService.removeRequest(), DELAY_TIME)));
  }
}
