import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IPDPRoles } from '@erp/shared';

@Component({
  selector: 'erp-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPSidenavComponent implements OnInit {
  roles: IPDPRoles;
  constructor(readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.pipe<IPDPRoles>(map(({ roles }) => roles)).subscribe((roles: IPDPRoles) => {
      this.roles = roles;
    });
  }
}
