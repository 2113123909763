import { Injectable } from '@angular/core';

import { ISalesOrder, ISalesOrderInfo, ISalesOrderResponse } from '../interfaces/sales-order.interface';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedSalesOrderFactory {
  fromResponse(orderResponse: ISalesOrderResponse): ISalesOrder {
    const { id, address, rowVersion, lines, ...order } = orderResponse;

    return {
      id,
      rowVersion,
      salesOrderInfo: order as ISalesOrderInfo,
      shippingAddressInfo: address,
      lines
    };
  }
}
