import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ListRange } from '@angular/cdk/collections';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  forwardRef
} from '@angular/core';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';

import { FixedSizeTableVirtualScrollStrategy } from './fixed-size-table-virtual-scroll-strategy';
import { TableVirtualScrollDataSource } from './table-data-source';

export function tableVirtualScrollDirectiveStrategyFactory(tableDir: ERPTableItemSizeDirective) {
  return tableDir.scrollStrategy;
}

const defaults = {
  rowHeight: 32,
  headerHeight: 48,
  bufferMultiplier: 0.7
};

@Directive({
  selector: 'cdk-virtual-scroll-viewport[erpVirtualScrollTable]',
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: tableVirtualScrollDirectiveStrategyFactory,
      deps: [forwardRef(() => ERPTableItemSizeDirective)]
    }
  ]
})
export class ERPTableItemSizeDirective implements OnChanges, AfterContentInit, OnDestroy {
  @Input()
  readonly rowHeight = defaults.rowHeight;

  @Input()
  readonly headerHeight = defaults.headerHeight;

  @Input()
  readonly infiniteScroll: boolean;

  @Input()
  readonly bufferMultiplier = defaults.bufferMultiplier;

  @Output()
  readonly loadNextPage = new EventEmitter();

  @ContentChild(MatTable, { static: true })
  readonly table: MatTable<unknown>;

  readonly scrollStrategy = new FixedSizeTableVirtualScrollStrategy();

  readonly dataSourceChanges = new Subject<void>();

  constructor(private readonly zone: NgZone) {}

  ngOnDestroy() {
    this.dataSourceChanges.next();
    this.dataSourceChanges.complete();
  }

  ngAfterContentInit() {
    // @ts-ignore
    const switchDataSourceOrigin = this.table._switchDataSource;
    // @ts-ignore
    this.table._switchDataSource = (dataSource: unknown) => {
      switchDataSourceOrigin.call(this.table, dataSource);
      this.connectDataSource(dataSource);
    };

    this.connectDataSource(this.table.dataSource);
    if (this.infiniteScroll) {
      this.scrollStrategy.infiniteScroll = this.infiniteScroll;
      this.scrollStrategy.bottomScrollObservable.subscribe(() => this.loadNextPage.emit());
    }
  }

  connectDataSource(dataSource: unknown) {
    this.dataSourceChanges.next();
    if (dataSource instanceof TableVirtualScrollDataSource) {
      this.scrollStrategy.renderedRangeStream.pipe(takeUntil(this.dataSourceChanges)).subscribe((range: ListRange) => {
        this.zone.run(() => {
          dataSource.renderedRangeStream.next(range);
        });
      });

      // @ts-ignore
      dataSource._data
        .pipe(
          map((data: unknown[]) => data.length),
          takeUntil(this.dataSourceChanges)
        )
        .subscribe((length: number) => {
          this.scrollStrategy.dataLength = length || 0;
        });
      dataSource.renderedRangeStream.next({ start: 0, end: 0 });
    } else {
      throw new Error(
        '[erpVirtualScrollTable] requires TableVirtualScrollDataSource be set as [dataSource] of [mat-table]'
      );
    }
  }

  ngOnChanges() {
    const config = {
      rowHeight: +this.rowHeight || defaults.rowHeight,
      headerHeight: +this.headerHeight || defaults.headerHeight,
      bufferMultiplier: +this.bufferMultiplier || defaults.bufferMultiplier
    };
    this.scrollStrategy.setConfig(config);
  }
}
