import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'erp-no-access',
  templateUrl: './no-access-page.component.html',
  styleUrls: ['./no-access-page.component.scss']
})
export class ERPNoAccessPageComponent implements OnInit {
  route = '/';

  constructor(readonly router: Router) {}

  ngOnInit() {
    if (this.router.url.indexOf('/tablet/') > -1) {
      this.route = `/tablet`;
    }
  }
}
