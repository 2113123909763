<ng-content select="erp-fixed-toolbar-action"></ng-content>
<erp-actions-select
  *ngIf="!isSmallScreen && moreActionList.length"
  class="toolbar-action"
  [title]="title"
  [actionList]="moreActionList"
></erp-actions-select>
<erp-actions-select
  *ngIf="isSmallScreen && actionList.length"
  [title]="title"
  [actionList]="actionList"
></erp-actions-select>
<ng-content *ngIf="!isSmallScreen"></ng-content>

<ng-template>
  <ng-container i18n="@@common.buttons.actions">
    Actions
  </ng-container>
  <ng-container i18n="@@common.buttons.more-actions">
    More Actions
  </ng-container>
</ng-template>
