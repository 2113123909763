import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPConfirmModule } from '../../confirm';

import { ERPDiscardCreationDialogComponent } from './components';

@NgModule({
  declarations: [ERPDiscardCreationDialogComponent],
  imports: [CommonModule, ERPConfirmModule],
  exports: [ERPDiscardCreationDialogComponent]
})
export class ERPDiscardCreationDialogModule {}
