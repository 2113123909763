import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { EmitEvent } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ERPEmitService {
  private events = new Subject<EmitEvent>();

  events$ = this.events.asObservable();

  emit(event: EmitEvent): void {
    this.events.next(event);
  }
}
