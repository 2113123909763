<form [formGroup]="form" class="padding-top-3">
  <div>
    <erp-button dense type="reset" variant="link" color="primary">
      Clear All Filters
    </erp-button>
  </div>
  <div class="grid grid-column-gap-d filter-grid">
    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.material-type">Material Type:</ng-container>
      </erp-label>
      <erp-select
        formControlName="materialTypeId"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="materialTypes$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.wall">Wall:</ng-container>
      </erp-label>
      <erp-select
        formControlName="wall"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="walls$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.connection">Connection:</ng-container>
      </erp-label>
      <erp-select
        formControlName="connection"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="connections$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.weight">Weight per Foot:</ng-container>
      </erp-label>
      <erp-number type="decimal" formControlName="weight"></erp-number>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.protectorType">Protector Type:</ng-container>
      </erp-label>
      <erp-select
        formControlName="protectorType"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="protectorTypes$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <!--  Label "Size" changed to "OD" per customer request -->
        <ng-container i18n="@@inventory.sku-smart-search.filtering.size">OD:</ng-container>
      </erp-label>
      <erp-select
        formControlName="size"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="sizes$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.design">Design:</ng-container>
      </erp-label>
      <erp-select
        formControlName="design"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="designs$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <!--  Label "Thread" changed to "End type" per customer request -->
        <ng-container i18n="@@inventory.sku-smart-search.filtering.end-type">End type:</ng-container>
      </erp-label>
      <erp-select
        formControlName="thread"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="threads$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.specialty">Specialty:</ng-container>
      </erp-label>
      <erp-select
        formControlName="specialty"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="specialties$ | async"
      ></erp-select>
    </div>
    <!--Important block of code. Do not remove it-->
    <div></div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.grade">Grade:</ng-container>
      </erp-label>
      <erp-select
        formControlName="grade"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="grades$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.range">Range:</ng-container>
      </erp-label>
      <erp-select
        formControlName="range"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="ranges$ | async"
      ></erp-select>
    </div>

    <div>
      <erp-label>
        <ng-container i18n="@@inventory.sku-smart-search.filtering.condition">Condition:</ng-container>
      </erp-label>
      <erp-select
        formControlName="condition"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="conditions$ | async"
      ></erp-select>
    </div>
  </div>
</form>
