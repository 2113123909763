import { AbstractControl, ValidatorFn } from '@angular/forms';

export function LessThanDynamicValidator(comparator: string, comparable: string): ValidatorFn {
  return (control: AbstractControl) => {
    const comparatorValue = control.get(comparator)?.value ?? 0;
    const comparableValue = control.get(comparable)?.value;

    const valid = !comparableValue || comparableValue <= comparatorValue;

    if (!valid) {
      control.get(comparable)?.setErrors({ ...control.get(comparable)?.errors, notValid: true });
    } else {
      removeFormControlError(control.get(comparable) as AbstractControl, 'notValid');
    }

    return valid ? null : { notValid: true };
  };
}

function removeFormControlError(control: AbstractControl, errorName: string) {
  if (control?.errors && control?.errors[errorName]) {
    delete control.errors[errorName];
    if (Object.keys(control.errors).length === 0) {
      control.setErrors(null);
    }
  }
}
