import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPButtonModule } from '../../button';

import { ERPNavigationFailedPageComponent } from './components';
import { ERPNavigationFailedPageRoutingModule } from './navigation-failed-page-module.routing';

@NgModule({
  declarations: [ERPNavigationFailedPageComponent],
  exports: [ERPNavigationFailedPageComponent],
  imports: [CommonModule, ERPButtonModule, ERPNavigationFailedPageRoutingModule]
})
export class ERPNavigationFailedPageModule {}
