import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { TooltipDirective } from './directive';

@NgModule({
  declarations: [TooltipDirective],
  imports: [CommonModule, MatTooltipModule],
  exports: [TooltipDirective]
})
export class ERPTooltipModule {}
