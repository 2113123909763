import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';

import { BaseHttpService } from '../abstracts';

@Injectable({
  providedIn: 'root'
})
export class InventoryStatusService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('inventoryStatuses');
  }

  get inventoryStatuses() {
    return this.get<ISelectOption[]>();
  }
}
