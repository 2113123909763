import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';
import { ISession } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPSessionService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('userSessions');
  }

  getSession(userId: string, params = {}): Observable<ISession> {
    return this.get<ISession>(params, `${encodeURI(userId)}`);
  }

  deleteSession(userId: string, params = {}): Observable<ISession> {
    return this.delete<ISession>(params, `${encodeURI(userId)}`);
  }

  createSession(body: ISession, params = {}): Observable<ISession> {
    return this.post<ISession>(body, params, `${encodeURI(body.userId)}`);
  }
}
