<div class="flex flex-column align-items-center padding-top-8">
  <h3 i18n="@@navigation-failed.title">
    Something's wrong
  </h3>
  <div class="margin-bottom-5" i18n="@@navigation-failed.description">
    We were unable to connect to the service.
  </div>
  <div class="margin-bottom-3">
    <erp-button wide (click)="reload()">
      <ng-container i18n="@@navigation-failed.buttons.retry">
        Retry
      </ng-container>
    </erp-button>
  </div>
  <erp-button routerLink="/" color="primary" variant="link">
    <ng-container i18n="@@navigation-failed.buttons.go-to-homepage">
      Go to the Home Page
    </ng-container>
  </erp-button>
</div>
