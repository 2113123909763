import { Inject, Injectable, Type } from '@angular/core';

import { ERP_TABLE_FILTER_TYPE } from '../providers';
import { TableFilterType } from '../types';

type InjectionType = { component: Type<unknown>; data: object };

@Injectable({ providedIn: 'any' })
export class ERPTableFilterRegistry {
  private readonly _registry = new Map<TableFilterType, InjectionType>();

  constructor(@Inject(ERP_TABLE_FILTER_TYPE) registries: [TableFilterType, InjectionType][]) {
    for (const [type, injection] of registries) {
      this._registry.set(type, injection);
    }
  }

  get(type: TableFilterType) {
    if (this._registry.has(type)) {
      return this._registry.get(type) as InjectionType;
    }

    throw new Error(`Cannot find filter for ${type}`);
  }
}
