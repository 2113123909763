import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';

import { ERPToasterComponent, ERPToasterOutletComponent } from './components';

@NgModule({
  declarations: [ERPToasterComponent, ERPToasterOutletComponent],
  imports: [CommonModule, ERPIconModule, ERPButtonModule, RouterModule],
  exports: [ERPToasterComponent, ERPToasterOutletComponent]
})
export class ERPToasterModule {}
