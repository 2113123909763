import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

import { ERPToasterService } from '@erp/components';
import {
  AutoCleanupFeature,
  BaseFormComponent,
  ERPFormStateDispatcher,
  Features,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';
import { IVolumetricGrowth } from '@erp/tablet';

import { ERPProductionProcessingOrderService } from '../../../../../../../production/src/lib/modules/processing-order/services';

const MAX_LENGTH = 120;
const MAX_LENGTH_STENCIL = 250;

@Component({
  selector: 'erp-tablet-check-in-dialog',
  templateUrl: './volumetric-growth-dialog.component.html',
  styleUrls: ['./volumetric-growth-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPFormStateDispatcher]
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPTabletVolumetricGrowthDialogComponent<T> extends BaseFormComponent<IVolumetricGrowth>
  implements OnInit {
  readonly destroyed$: Observable<unknown>;

  readonly form = new UntypedFormGroup({
    piTapeSn: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    ovalityDialSn: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    micrometerSn: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    straightedgeSn: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    entryStencil: new UntypedFormControl(null, [Validators.required, Validators.maxLength(MAX_LENGTH_STENCIL)]),
    id: new UntypedFormControl(null),
    orderId: new UntypedFormControl(null),
    serviceId: new UntypedFormControl(null),
    measurementTypeId: new UntypedFormControl(1), // default option
    userId: new UntypedFormControl(null),
    userName: new UntypedFormControl(null),
    rowVersion: new UntypedFormControl(null),
    startDate: new UntypedFormControl(null),
    isOpen: new UntypedFormControl(null)
  });

  constructor(
    readonly formState: ERPFormStateDispatcher,
    readonly dialogRef: MatDialogRef<ERPTabletVolumetricGrowthDialogComponent<IVolumetricGrowth>>,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'create' | 'view' | 'edit'; volumetricData: IVolumetricGrowth },
    readonly changeDetector: ChangeDetectorRef,
    readonly router: Router,
    readonly processingOrderService: ERPProductionProcessingOrderService,
    readonly toasterService: ERPToasterService
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.volumetricData) {
      this.form.patchValue(this.data.volumetricData);
    }
    if (this.data.mode === 'view') {
      this.form.disable();
    }
    if (this.data.mode === 'create') {
      this.setOrderId();
    }
  }

  private setOrderId() {
    const url = this.router.url.split('/');
    const orderId = +url[url.findIndex(i => i === 'processing-order') + 1];
    const serviceId = +url[url.findIndex(i => i === 'service') + 1];

    this.form.patchValue({
      orderId,
      serviceId
    });
  }

  onSave() {
    if (this.data.mode === 'edit') {
      this.onUpdate();
    }
    if (this.data.mode === 'create') {
      this.onCreate();
    }
  }

  onCreate() {
    this.form.get('startDate')?.patchValue(new Date());

    const { valid, value } = this.form;

    if (!valid) {
      this.formState.onSubmit.notify();

      return;
    }

    this.processingOrderService.createDevice(value).subscribe(
      res => {
        this.toasterService.success('success.created');
        this.dialogRef.close(res);
      },
      error => {
        const message = error.error?.error?.message || 'error.default';
        this.toasterService.error(message);
      }
    );
  }

  onUpdate() {
    this.form.get('startDate')?.patchValue(new Date());

    const { valid, value } = this.form;

    if (!valid) {
      this.formState.onSubmit.notify();

      return;
    }

    this.processingOrderService.updateDevice(value).subscribe(
      res => {
        this.toasterService.success('success.updated');
        this.dialogRef.close(res);
      },
      error => {
        const message = error.error?.error?.message || 'error.default';
        this.toasterService.error(message);
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }
}
