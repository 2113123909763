import { PDPDesktopSideNavModule } from '../enums/pdp.enum';
import { IPDPDesktopModules } from '../interfaces';

// Have to match routing structure
export const pdpTabletModules: { [key: string]: string[] } = {
  'logistic-and-warehousing': [
    'driver-sign-in',
    'unloading-authorizations',
    'loading-authorization',
    'movements',
    'segregation'
  ],
  production: ['processing-order'],
  'quality-control': ['yard-ncp']
};

// TODO: simplify for desktop. Make similar to tablet. Refactor routing structure

export const pdpDesktopModules: IPDPDesktopModules = {
  logisticAndWarehousing: [
    { resourceName: 'unloading-authorizations', navigateTo: '/logistic-and-warehousing/unloading-authorizations' },
    { resourceName: 'loading-authorizations', navigateTo: '/logistic-and-warehousing/loading-authorizations' },
    { resourceName: 'movements', navigateTo: '/inventory/setup/movements' },
    { resourceName: 'transfer-orders', navigateTo: '/logistic-and-warehousing/transfer-orders' },
    { resourceName: 'material-id-history', navigateTo: '/inventory/inquiries/material-id-history' },
    { resourceName: 'inventory-adjustment', navigateTo: '/inventory/setup/inventory-adjustment' },
    { resourceName: 'transportation-companies', navigateTo: '/logistic-and-warehousing/transportation-companies' }
  ],
  sales: [
    { resourceName: 'sales-quotes', navigateTo: '/sales/sales-quotes' },
    { resourceName: 'sales-orders', navigateTo: '/sales/sales-orders' },
    { resourceName: 'sales-goods-issue', navigateTo: '/sales/goods-issues' },
    { resourceName: 'sales-customers', navigateTo: '/sales/customers' },
    { resourceName: 'sales-contacts', navigateTo: '/sales/contacts' },
    { resourceName: 'sales-material-releases', navigateTo: '/sales/material-release' }
  ],
  procurement: [
    {
      resourceName: 'procurement-purchase-requisitions',
      navigateTo: '/procurement/inventory-transactions/purchase-requisitions'
    },
    { resourceName: 'procurement-purchase-orders', navigateTo: '/procurement/inventory-transactions/purchase-orders' },
    { resourceName: 'procurement-receipts', navigateTo: '/procurement/inventory-transactions/receipts' },
    {
      resourceName: 'procurement-inbound-deliveries',
      navigateTo: '/procurement/inventory-transactions/inbound-deliveries'
    },
    { resourceName: 'procurement-vendors', navigateTo: '/procurement/inventory-transactions/vendors' }
  ],
  production: [
    { resourceName: 'production-process-orders', navigateTo: '/production/processing-order' },
    { resourceName: 'production-services', navigateTo: '/production/services' },
    { resourceName: 'production-work-centers', navigateTo: '/production/work-centers' }
  ],
  planning: [
    { resourceName: 'planning-board', navigateTo: '/planning/planning' },
    { resourceName: 'planning-schedule-per-operation', navigateTo: '/planning/production-scheduling' }
  ],
  inventoryOnHand: [{ resourceName: 'inventory-on-hand', navigateTo: '/inventory/setup/on-hand' }],
  qualityControl: [{ resourceName: 'quality-yarn-ncp', navigateTo: '/quality-control/yard-ncp' }],
  administration: [
    { resourceName: 'administration-inventory-maintenance', navigateTo: '/inventory/product-types/casing' },
    { resourceName: 'administration-security', navigateTo: '/administration/user-roles' }
  ]
};

// non navigatable things that require permissions (e.g. popups, buttons, etc.)
export const pdpDesktopActions: string[] = ['shipping-report', 'receiving-report', 'on-hand-report'];

export function getDesktopPDPResources(): string[] {
  const moduleResources: string[] = [];

  for (const key of Object.keys(pdpDesktopModules)) {
    pdpDesktopModules[key as PDPDesktopSideNavModule].forEach(resource => moduleResources.push(resource.resourceName));
  }

  return [...moduleResources, ...pdpDesktopActions];
}

export function getTabletPDPResources(): string[] {
  return [
    ...pdpTabletModules['logistic-and-warehousing'],
    ...pdpTabletModules.production,
    ...pdpTabletModules['quality-control']
  ];
}
