import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPConfirmModule } from '../../confirm';

import { ERPCConfirmQuantityCorrectDialogComponent, ERPConfirmProductionResultsDialogComponent } from './components';

@NgModule({
  declarations: [ERPConfirmProductionResultsDialogComponent, ERPCConfirmQuantityCorrectDialogComponent],
  imports: [CommonModule, ERPConfirmModule],
  exports: [ERPConfirmProductionResultsDialogComponent, ERPCConfirmQuantityCorrectDialogComponent]
})
export class ERPConfirmProductionResultDialogModule {}
